import React, { FC } from "react";
import { useFocused, useSelected } from "slate-react";
import { BlockProps } from "../block/Block";
import "./image.scss";

const Images: FC<BlockProps> = ({ attributes, children, element }) => {
  const selected = useSelected();
  const focused = useFocused();
  return (
    <div {...attributes}>
      <div contentEditable={false}    >
        <img src={element.url} className="rich-text-image" style={{boxShadow: `${selected && focused ? '0 0 0 3px #B4D5FF' : 'none'}`}}  />
      </div>
      {children}
    </div>
  );
};

export default Images;

