import React, { FC, ReactElement, useEffect } from "react";
import { useAppDispatch } from "../../store/hooks";
import HomeLayout from "../../components/layout/HomeLayout";
import {
  announcementsState,
  getAnnouncementsAsync,
} from "../../features/announcements/announcementsSlice";
import { store } from "../../store/store";
import "./home.scss";

const Home: FC = (): ReactElement => {
  const storeState = store.getState();
  const announcements = storeState.announcements as announcementsState;
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getAnnouncementsAsync());
  }, []);
  return (
    <div className={"home"}>
      <HomeLayout isLoading={announcements.status !== "idle"} />
    </div>
  );
};

export default Home;
