import { Editor, Element as SlateElement, Path, Transforms } from "slate";
import { CustomEditor, ImageElement } from "../custom-types";

export const isMarkActive = (editor: CustomEditor, format: string): boolean => {
  const marks = Editor.marks(editor);
  const formatted = marks as unknown as Record<string, boolean>;
  return marks ? formatted[format] === true : false;
};
export const toggleMark = (editor: CustomEditor, format: string): void => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const LIST_TYPES = ["numbered-list", "bulleted-list"];

export const toggleBlock = (editor: CustomEditor, format: any): void => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  // if the selected block is a list of child elements get all child elements
  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type),
    split: true,
  });

  // if the current format isActive set the blocks children to each  be a paragraph
  // else if the current format isNotactive and the format is a list type,
  // then set all blocks child elements to be list items
  // else set the format of each of the blocks children to the format prop.
  const newProperties: Partial<SlateElement> = {
    type: isActive ? "paragraph" : isList ? "list-item" : format,
  };
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};
export const isBlockActive = (
  editor: CustomEditor,
  format: string
): boolean => {
  const [match] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
  });
  return !!match;
};

export const indent = (editor: CustomEditor, format: any): void => {
  const block = { type: format, children: [] };
  Transforms.wrapNodes(editor, block);
};

export const outdent = (editor: CustomEditor): void => {
  Transforms.liftNodes(editor, {
    match: (n, path) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      ["list-item", "bulleted-list"].includes(n.type) &&
      path.length > 2,
  });

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      ["indented"].includes(n.type),
    split: true,
  });
};

export const updateFontSize = (editor: CustomEditor, update: string): void => {
  const marks = Editor.marks(editor);
  if (!!marks) {
    const formatted = marks as unknown as Record<string, any>;
    const currentSize: number = !!formatted["fontSize"]
      ? formatted["fontSize"]
      : 1;
    const updatedSize =
      update === "increase" ? currentSize + 0.05 : currentSize - 0.05;
    Editor.addMark(editor, "fontSize", updatedSize);
  }
};

export const updateFontColour = (
  editor: CustomEditor,
  colour: string
): void => {
  const marks = Editor.marks(editor);
  if (!!marks) {
    Editor.addMark(editor, "fontColour", colour);
  }
};
export const updateFontFamily = (editor: CustomEditor, font: any): void => {
  Editor.addMark(editor, "fontFamily", font);
};
