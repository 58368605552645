import { Button } from "@mui/material";
import React, { FC } from "react";
import { Node } from "react-flow-renderer";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { addWorkflowNode } from "../../../features/workflow/workflowSlice";
// import { nodeWidth } from "../../../models/workflow/constants/Node.constant";
import { WorkflowNodeConfiguration } from "../../../../models/workflow/WorkflowNode.model";
import colors from "../../../theme/colors.module.scss";
import getNewNode from "../utils/getNewNode";
import { getId, getNodeIndex } from "../workflowzone/WorkFlowZone";
import "./workflowoption.scss";
export interface WorkflowOptionProps {
  option: WorkflowNodeConfiguration;
  reactFlowWrapper: any;
  reactFlowInstance: any;
}
const WorkflowOption: FC<WorkflowOptionProps> = ({
  option,
  reactFlowInstance,
  reactFlowWrapper,
}) => {
  const { t } = useTranslation(["admin"]);
  const dispatch = useDispatch();
  const onClick = () => {
    if (reactFlowWrapper && reactFlowWrapper.current && reactFlowInstance) {
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();

      const position = reactFlowInstance.project({
        x: reactFlowBounds.left,
        y: reactFlowBounds.top,
      });
      const newNode = getNewNode(option, position);
      dispatch(addWorkflowNode(newNode));
    }
  };
  const onDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    nodeType: string
  ) => {
    event.dataTransfer.setData("application/reactflow", JSON.stringify(option));
    event.dataTransfer.effectAllowed = "move";
  };
  return (
    <div
      className={`workflow-option-container `}
      onDragStart={(event) => {
        onDragStart(event, "default");
      }}
      onClick={() => onClick()}
      draggable>
      <Button
        variant="outlined"
        draggable={true}
        size="large"
        style={{
          borderColor: option.color,
          color: option.color,
          backgroundColor: colors.white,
          transform: "translate3d(0, 0, 0)",
        }}>
        {t("add")} {t(option.type)}
      </Button>
    </div>
  );
};

export default WorkflowOption;
