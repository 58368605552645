import { MenuItem, Select, Stack } from "@mui/material";
import React, { FC, useEffect } from "react";
import { Node } from "react-flow-renderer";
import { useTranslation } from "react-i18next";
import { routes } from "../../../../config/routes";
import {
  getAllForms,
  getFormsAsync,
  getFormsStatus,
} from "../../../../features/forms/formsSlice";
import {
  selectCurrentElement,
  updateElement,
} from "../../../../features/workflow/workflowSlice";
import RouteItem from "../../../../../models/RouteItem.model";
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import { AdminButton } from "../../../adminbutton/AdminButton";
import ExpandableMenu from "../../../expandableMenu/ExpandableMenu";
import updateNodeCustomData from "../../utils/updateNodeCustomData";
import "./../../../../App.scss";
import "./nodeform.scss";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";

const NodeForm: FC = () => {
  const { t } = useTranslation(["admin"]);
  const dispatch = useAppDispatch();
  const route = routes.find(
    (routeToFind: RouteItem) => routeToFind.key == "formDesigner"
  );
  const forms = useAppSelector(getAllForms);
  const currentWorkflowNode = useAppSelector(selectCurrentElement);
  const formsStatus = useAppSelector(getFormsStatus);
  useEffect(() => {
    dispatch(getFormsAsync());
  }, []);
  useEffect(() => {
    if (
      formsStatus === "idle" &&
      forms.length > 0 &&
      currentWorkflowNode &&
      !currentWorkflowNode.data.formId
    ) {
      //TODO: update condition when editing

      dispatch(
        updateElement(
          updateNodeCustomData(
            "formId",
            forms[0].id,
            currentWorkflowNode as Node
          )
        )
      );
    }
  }, [formsStatus, currentWorkflowNode]);
  return (
    <ExpandableMenu title={t("form")}>
      <Stack direction="row" spacing={1} className="node-form-root">
        <Select
          size="small"
          variant="outlined"
          color="primary"
          className="node-form-select no-label-input"
          value={
            currentWorkflowNode?.data.formId
              ? currentWorkflowNode.data.formId
              : forms.length > 0
              ? forms[0].id
              : ""
          }
          onChange={(e) =>
            dispatch(
              updateElement(
                updateNodeCustomData(
                  "formId",
                  e.target.value,
                  currentWorkflowNode as Node
                )
              )
            )
          }>
          {forms.map((record, index) => (
            <MenuItem key={index} value={record.id}>
              {record.displayName}
            </MenuItem>
          ))}
        </Select>
        <div className="nav-button-container">
          <AdminButton
            pathName={!!route ? route.path : `/formDesginer`}
            title={t("editForm")}
            buttonText={t("editForm")}
            startIcon={<ModeEditOutlineOutlinedIcon />}
            configuration={{ isEdit: false }}
          />
        </div>
      </Stack>
    </ExpandableMenu>
  );
};

export default NodeForm;
