import React from "react";
import EdisonTypography from "../../app-shared/edison/typography/EdisonTypography";
import "./businesscase.scss";

const BusinessCase = () => (
  <div className="businesscase-root">
    <div className="body">
      <EdisonTypography
        variant="t1"
        title={"Business Case"}
        disableUppercase={true}
      />
      <EdisonTypography
        variant="t8"
        title={"Coming Soon"}
        disableUppercase={true}
      />
    </div>
  </div>
);

export default BusinessCase;
