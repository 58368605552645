import { User } from "@microsoft/microsoft-graph-types";

import React, { FC } from "react";
import UserPeoplePicker from "../../../app-shared/user/peoplepicker/UserPeoplePicker";
import { selectGroupMembers, selectUserPhotos } from "../../../features/msGraph/msGraphSlice";
import UserPicture from "../../models/common/UserPicture.model";
import { useAppSelector } from "../../redux/store/hooks";

import { mapPeople } from "../../utils/userMapper";

export interface WrappedPeoplePickerProps{
        readOnly: boolean;
        component: any;
        useInternalState: boolean;
        value?: any;
        setValue?: any;
}
const WrappedPeoplePicker: FC<WrappedPeoplePickerProps> = ({readOnly, component, useInternalState, value}) => {
    const groupMembers: User[] = useAppSelector(selectGroupMembers);
    const userPictures: UserPicture[] = useAppSelector(selectUserPhotos);
  return (
    <UserPeoplePicker
      multiple={true}
      label={component.displayName}
      placeholder={component.displayName}
      noOptionsText={"a"}
      maxAvatars={4}
      maxDropdownCount={10}
      peopleList={mapPeople(userPictures, groupMembers)}
      useInternalState={useInternalState}
      isEditing={!readOnly}
      value={value ? value : mapPeople(userPictures, groupMembers).slice(0, 4)}
    />
  );
};

export default WrappedPeoplePicker;
