import { loginRequest } from "../config/authConfig";

export function execute(
  instance: any,
  httpRequest: any,
  dataType: string,
  data?: any,
  id?: number,
  scopes?: any
): Promise<unknown> {
  const activeAccount = instance.getActiveAccount(); // TODO: Add logic that calls the setActiveAccount API, otherwise this will always be null
  const accounts = instance.getAllAccounts();
  const currentAccount = activeAccount || accounts[0];

  const requestedScope = !!scopes ? scopes : loginRequest;  
  const request = 
     {
        ...requestedScope,
        account: currentAccount,
      };

  return new Promise((resolve) => {
    instance
      .acquireTokenSilent(request)
      .then((tokenResponse: string) =>
        resolve(call(httpRequest, tokenResponse, dataType, data, id))
      )
      .catch((error: string) => {
        console.error("ENADA ERROR: ", error);
        instance.aquireTokenPopup(request).then((tokenResponse: string) => {
          resolve(call(httpRequest, tokenResponse, dataType, data, id));
        });
      });
  });
}

function call(
  httpRequest: any,
  tokenResponse: any,
  dataType: string,
  data?: any,
  id?: any
) {
  if (data && id) {
    return httpRequest(tokenResponse.accessToken, dataType, data, id).then(
      (response: unknown) => response
    );
  }
  if (data) {
    return httpRequest(tokenResponse.accessToken, dataType, data).then(
      (response: unknown) => response
    );
  }
  if (id) {
    return httpRequest(tokenResponse.accessToken, dataType, id).then(
      (response: unknown) => response
    );
  }
  return httpRequest(tokenResponse.accessToken, dataType).then(
    (response: unknown) => response
  );
}
