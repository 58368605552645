import React from "react";
import { NodeBaseProps } from "../nodeProps.model";
export interface LeafProps extends NodeBaseProps {
  leaf: any;
}
const Leaf = ({ attributes, children, leaf }: LeafProps): JSX.Element => {
  if (leaf.bold) {
    children = (
      <strong
        style={{
          fontSize: `${leaf.fontSize}em`,
          color: leaf.fontColour,
          fontFamily: leaf.fontFamily,
        }}>
        {children}
      </strong>
    );
  }

  if (leaf.code) {
    children = (
      <code
        style={{
          fontSize: `${leaf.fontSize}em`,
          color: leaf.fontColour,
          fontFamily: leaf.fontFamily,
        }}>
        {children}
      </code>
    );
  }

  if (leaf.italic) {
    children = (
      <em
        style={{
          fontSize: `${leaf.fontSize}em`,
          color: leaf.fontColour,
          fontFamily: leaf.fontFamily,
        }}>
        {children}
      </em>
    );
  }

  if (leaf.underline) {
    children = (
      <u
        style={{
          fontSize: `${leaf.fontSize}em`,
          color: leaf.fontColour,
          fontFamily: leaf.fontFamily,
        }}>
        {children}
      </u>
    );
  }

  return (
    <span
      {...attributes}
      style={{
        fontSize: `${leaf.fontSize}em`,
        color: leaf.fontColour,
        fontFamily: leaf.fontFamily,
      }}>
      {children}
    </span>
  );
};
export default Leaf;
