import {
  Divider,
  MenuItem,
  Paper,
  Select,
  styled, useTheme
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CodeIcon from "@mui/icons-material/Code";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import FormatIndentDecreaseIcon from "@mui/icons-material/FormatIndentDecrease";
import FormatIndentIncreaseIcon from "@mui/icons-material/FormatIndentIncrease";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import FormatUnderlined from "@mui/icons-material/FormatUnderlined";
import ImageIcon from "@mui/icons-material/Image";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import { ToggleButton, ToggleButtonGroup } from "@mui/lab";
import { mdiFormatFontSizeDecrease, mdiFormatFontSizeIncrease } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useEffect, useState } from "react";
import { Editor } from "slate";
import { useSlate } from "slate-react";
import { CustomEditor } from "./custom-types";
import { fontFamilies } from "./utils/fontFamilies";
import {
  indent,
  outdent,
  toggleBlock,
  toggleMark,
  updateFontColour,
  updateFontFamily,
  updateFontSize
} from "./utils/richTextUtils";
import { insertImage } from "./utils/withImages";
import { insertLink, isLinkActive, unwrapLink } from "./utils/withLink";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.5),
    border: 0,
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-of-type": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));
const RichTextFieldToolbar = () => {
  const editor = useSlate();
  const [color, setColor] = useState("#000000");
  const [fontFamily, setFontFamily] = useState("Arial");
  useEffect(() => {
    const marks = Editor.marks(editor);
    if (!!marks) {
      const formatted = (marks as unknown) as Record<string, any>;

      !!formatted["fontColour"]
        ? setColor(formatted["fontColour"])
        : setColor("#000000");
      !!formatted["fontFamily"]
        ? setFontFamily(formatted["fontFamily"])
        : setFontFamily(fontFamilies[0]);
    }
  }, [Editor.marks(editor)]);

  const checkSelected = (value: string): boolean => {
    const marks = Editor.marks(editor);
    if (!!marks) {
      const formatted = (marks as unknown) as Record<string, any>;
      return Object.keys(formatted).includes(value);
    }
    return false;
  };
  const theme = useTheme()

  return (
    <Paper
      elevation={1}
      style={{
        display: "flex",
        border: `1px solid ${theme.palette.divider}`,
        flexWrap: "wrap",
        width: "fit-content"
      }}>
      <StyledToggleButtonGroup size="small" value="left">
        <ToggleButton
          onClick={() => toggleMark(editor, "bold")}
          value="bold"
          selected={checkSelected("bold")}
          color="primary">
          <FormatBoldIcon />
        </ToggleButton>
        <ToggleButton
          onClick={() => toggleMark(editor, "italic")}
          value="italic"
          selected={checkSelected("italic")}
          color="primary">
          <FormatItalicIcon />
        </ToggleButton>
        <ToggleButton
          onClick={() => toggleMark(editor, "underline")}
          value="underline"
          selected={checkSelected("underline")}
          color="primary">
          <FormatUnderlined />
        </ToggleButton>
        <ToggleButton
          onClick={() => toggleMark(editor, "code")}
          value="code"
          selected={checkSelected("code")}
          color="primary">
          <CodeIcon />
        </ToggleButton>
      </StyledToggleButtonGroup>
      <Divider flexItem orientation="vertical" />
      <StyledToggleButtonGroup size="small" value="left">
        <ToggleButton
          onClick={() => indent(editor, "indented")}
          value="indented"
          color="primary">
          <FormatIndentIncreaseIcon />
        </ToggleButton>
        <ToggleButton
          onClick={() => outdent(editor)}
          value="decrease-indent"
          color="primary">
          <FormatIndentDecreaseIcon />
        </ToggleButton>
        <ToggleButton
          onClick={() => toggleBlock(editor, "block-quote")}
          value="block-quote">
          <FormatQuoteIcon />
        </ToggleButton>
        <ToggleButton
          onClick={() => toggleBlock(editor, "bulleted-list")}
          value="bulleted-list">
          <FormatListBulletedIcon />
        </ToggleButton>
        <ToggleButton
          onClick={() => toggleBlock(editor, "numbered-list")}
          value="numbered-list">
          <FormatListNumberedIcon />
        </ToggleButton>
      </StyledToggleButtonGroup>
      <Divider flexItem orientation="vertical" />
      <StyledToggleButtonGroup size="small" value="left">
        <ToggleButton
          onClick={() => updateFontSize(editor, "increase")}
          value="increase-font-size">
            <Icon path={mdiFormatFontSizeIncrease}   size={1}/>
        </ToggleButton>
        <ToggleButton
          onClick={() => updateFontSize(editor, "decrease")}
          value="decrease-font-size">
           <Icon path={mdiFormatFontSizeDecrease}   size={1}/>
        </ToggleButton>

        <ToggleButton value="color-picker">
          <FormatColorFillIcon></FormatColorFillIcon>
          <ArrowDropDownIcon />
          <input
            type="color"
            value={color}
            onChange={(e) => updateFontColour(editor, e.target.value)}
          />
        </ToggleButton>
        <ToggleButton  value= "font-pickerÓ">
          <Select
            color="primary"
            value={fontFamily}
            variant="standard"
            onChange={(e) => updateFontFamily(editor, e.target.value)}>
            {fontFamilies.map((font, index) => (
              <MenuItem key={index} value={font}>
                {font}
              </MenuItem>
            ))}
          </Select>
        </ToggleButton>
      </StyledToggleButtonGroup>
      <Divider flexItem orientation="vertical" />
      <StyledToggleButtonGroup size="small" value="left">
        <ToggleButton onClick={() => uploadImage(editor)} value="insert-image">
          <ImageIcon />
        </ToggleButton>
        <ToggleButton
          onClick={(e) => {
            e.preventDefault();
            const url = window.prompt("Enter the URL of the link:");
            if (!url) return;
            insertLink(editor, url);
          }}
          value="insert-link"
          selected={isLinkActive(editor)}>
          <InsertLinkIcon />
        </ToggleButton>
        <ToggleButton
          onClick={() => {
            if (isLinkActive(editor)) {
              unwrapLink(editor);
            }
          }}
          value="remove-link"
          selected={false}>
          <LinkOffIcon />
        </ToggleButton>
      </StyledToggleButtonGroup>
    </Paper>
  );
};
const uploadImage = (editor: CustomEditor) => {
  const url = window.prompt("Enter the URL of the image:");
  if (!url) return false;
  insertImage(editor, url);
};

export default RichTextFieldToolbar;
