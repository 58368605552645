import SearchIcon from "@mui/icons-material/Search";
import { Grid, IconButton, TextField } from "@mui/material";
import clsx from "clsx";
import React, { FC, useState } from "react";
import "./searchbar.scss";
import "./../../../App.scss";

export interface EdisonSearchBarProps {
  searchFilter: string;
  setSearchFilter: any;
  animated?: boolean;
  itemStyleOverride?: React.CSSProperties;
  onSearchPerformed?: any;
  width:string;
}
const EdisonSearchBar: FC<EdisonSearchBarProps> = ({
  searchFilter,
  setSearchFilter,
  animated,
  itemStyleOverride,
  onSearchPerformed,
  width
}) => {
  const [searchClicked, setSearchClicked] = useState<boolean>(false);
  const onSearchClicked = () => {
    setSearchClicked((prev) => !prev);
  };
  return (
    <Grid
      item
      className={clsx("search-bar", "no-label-input")}
      width={width}
      style={itemStyleOverride}>
      <TextField
        onKeyPress={(ev) => {
          if (ev.key === "Enter") {
            onSearchPerformed();
            ev.preventDefault();
          }
        }}
        variant="outlined"
        className={
          animated
            ? clsx("animated-search-input", {
                "transparent-box": !searchClicked,
              })
            : ""
        }
        style={(!!searchClicked || !animated)?{width: width}: {}}
        value={searchFilter}
        size="small"
        onChange={(e) => setSearchFilter(e.target.value)}
        InputProps={{
          endAdornment: (
            <IconButton
              aria-label="expand row"
              size="medium"
              disabled={!animated}
              onClick={() => onSearchClicked()}>
              <SearchIcon />
            </IconButton>
          ),
        }}
      />
    </Grid>
  );
};
export default EdisonSearchBar;
