import { Grid } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import AdminFormInput from "../../components/AdminFormInput";
import CalculatedFieldInput from "../../components/calculatedfield/CalculatedFieldInput";
import {
  transformConditionToBackend,
  transformExpressionToBackend,
} from "../../components/calculatedfield/utils/transform";
import { ChoiceInputs } from "../../components/ChoiceInputs";
import Layout from "../../components/layout/Layout";
import Loading from "../../components/loading/Loading";
import MultiChoice from "../../components/multichoice/MultiChoice";
import { NumberInputs } from "../../components/NumberInputs";
import { PeoplePickerInputs } from "../../components/PeoplePickerInputs";
import { SwitchInputs } from "../../components/SwitchInputs";
import { TextboxInputs } from "../../components/TextboxInputs";
import {
  resetSlice,
  selectConditionList,
  selectDependencyIds,
  selectExpressionList,
  selectIsCondition,
  setIsEdit,
} from "../../features/fields/calculatedFieldSlice";
import {
  clearIndividualField,
  createFieldAsync,
  getIndividualFieldAsync,
  updateFieldAsync,
} from "../../features/fields/fieldsSlice";
import { selectChoiceList } from "../../features/fields/multiChoiceSlice";
import FieldItem from "../../../models/FieldTypes/FieldItem.model";
import fieldTypes, {
  fieldTypeDisplayNameFromId,
  fieldTypeIdFromDisplayName,
} from "../../../models/FieldTypes/FieldTypes.enum";
import { getFieldTypes } from "../../utils/dataTypes";
import ILocation from "../../../models/ILocation.model";
import { Choice } from "../../../models/multiChoiceField.model";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import EdisonButton from "../../../app-shared/edison/button/EdisonButton";
import EdisonFormInput from "../../../app-shared/edison/forminput/EdisonFormInput";
import EdisonFormSelect from "../../../app-shared/edison/formselect/EdisonFormSelect";
import defaultItem from "../../utils/defaultItem";
import removeWhitespaces from "../../utils/removeWhitespaces";
import "./newfield.scss";

const defaultButton: FieldItem = defaultItem(getFieldTypes().textBox, {});

const NewField: FC = () => {
  // HOOKS
  const history = useHistory();
  const { t } = useTranslation(["admin"]);
  const methods = useForm();
  const dispatch = useAppDispatch();
  const { handleSubmit, watch } = methods;
  const location: ILocation = useLocation();
  //STATE
  const [isLoading, setLoading] = useState(false);

  const isEdit = !!location.state && !!location.state.item;

  const field: FieldItem = !!isEdit
    ? (location.state.item as FieldItem)
    : defaultButton;

  const watchSelect = watch(
    "fieldType",
    fieldTypeIdFromDisplayName(field.dataType)
  );
  const watchFormatList = watch("format");
  /// Used  to generate the  configuration for the calculated fields
  const dependencyIds = useAppSelector(selectDependencyIds);
  const multiLevelChoiceList = useAppSelector(selectChoiceList);
  const expressionList = useAppSelector(selectExpressionList);
  const conditionList = useAppSelector(selectConditionList);
  const isCalculatedFieldCondition = useAppSelector(selectIsCondition);

  const cancelOperation = () => {
    history.push({
      pathname: "/admin/fields",
      state: { title: t("fields") },
    });
  };

  useEffect(() => {
    dispatch(resetSlice());
    dispatch(setIsEdit(isEdit));
    if (isEdit) {
      dispatch(clearIndividualField());
      dispatch(getIndividualFieldAsync(field.id));
    }
  }, []);
  //fallback in case someone tries to come directly here
  if (!isEdit && location.pathname === "/admin/fields/editfield")
    cancelOperation();

  const onSubmit = async (data: any) => {
    let configuration = {};
    const fieldType = fieldTypeDisplayNameFromId(data.fieldType);
    switch (fieldType) {
      case getFieldTypes().number:
        configuration = {
          decimalPlaces: data.decimalPlaces,
          format: data.format,
          maximum: data.maximum,
          minimum: data.minimum,
        };
        if (data.format === "currency") {
          configuration = { ...configuration, currency: data.currency };
        }
        break;
      case getFieldTypes().switch:
        configuration = {
          onLabel: data.onLabel,
          offLabel: data.offLabel,
          hasDefaultValue: data.hasDefaultValue,
        };
        break;
      case getFieldTypes().choice:
        configuration = {
          choice: data.choice,
          isMultiChoice: data.isMultiChoice,
        };
        break;
      case getFieldTypes().textBox:
        configuration = {
          maxLength: data.maxLength,
          isMultiLine: data.isMultiLine,
        };
        break;
      case getFieldTypes().people:
        configuration = {
          isMultiValue: data.isMultiValue,
        };
        break;
      case getFieldTypes().calculated:
        // let validationErrors: string[] = [];
        // isCalculatedFieldCondition
        //   ? conditionList.forEach((block) => {
        //       validationErrors = validationErrors.concat(
        //         validateExpression(block.conditionBlock, true)
        //       );
        //       validationErrors = validationErrors.concat(
        //         validateExpression(block.resultBlock, false)
        //       );
        //     })
        //   : (validationErrors = validateExpression(expressionList, false));

        // if (validationErrors.length > 0) {
        //   dispatch(setValidationMessages(validationErrors));
        //   console.log("validation result", validationErrors);
        //   return;
        // }

        configuration = {
          calculatedExpression: isCalculatedFieldCondition
            ? transformConditionToBackend(conditionList)
            : transformExpressionToBackend(expressionList),
          format: watchFormatList,
        };
        break;
      case getFieldTypes().multiChoice:
        configuration = {
          choiceList: multiLevelChoiceList,
        };
        break;
      default: {
      }
    }
    const result = {
      displayName: data.displayName,
      dataType: fieldType,
      type: fieldType,
      isDeleted: false,
      name: removeWhitespaces(data.displayName),
      description: data.description,
      configuration: configuration,
      // at the moment sending only fields dependencies from calculated field
      fieldDependencies: dependencyIds,
    };
    !!isEdit
      ? dispatch(updateFieldAsync({ ...result, id: field.id }))
      : dispatch(createFieldAsync(result));
    history.push({
      pathname: "/admin/fields",
      state: { title: t("fields") },
    });
  };

  const a = fieldTypeIdFromDisplayName(field.dataType);
  return (
    <Layout>
      <Grid className="new-field-page-root" container spacing={3}>
        <Grid item xs={12}>
          {!isLoading ? (
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="field-metadata">
                  <AdminFormInput
                    name="displayName"
                    label={t("label")}
                    internalName={isEdit ? field.name : undefined}
                    defaultValue={field.displayName}
                    hasIDField={true}
                    requiredMessage={t("labelRequiredMessage")}
                  />
                  <EdisonFormInput
                    disabled={false}
                    name="description"
                    label={t("description")}
                    requiredMessage={t("descriptionRequiredMessage")}
                    defaultValue={field.description}
                  />
                  <EdisonFormSelect
                    name="fieldType"
                    label={t("fieldTypes")}
                    isDisabled={!!isEdit}
                    data={fieldTypes.map((fieldType) => ({
                      id: fieldType.id,
                      displayName: t(fieldType.displayName),
                    }))}
                    requiredMessage={t("typeRequiredMessage")}
                    defaultValue={
                      fieldTypeIdFromDisplayName(field.dataType)
                      // fieldTypes.find(
                      //   (fieldToFind) =>
                      //     field.dataType === fieldToFind.displayName
                      // )?.id
                    }
                  />
                </div>
                {watchSelect === 2 && (
                  <NumberInputs
                    isEdit={!!isEdit}
                    defaultConfiguration={!isEdit ? "" : field.configuration}
                    horizontal={true}
                  />
                )}

                {watchSelect === 8 && (
                  <SwitchInputs
                    isEdit={!!isEdit}
                    defaultConfiguration={!isEdit ? "" : field.configuration}
                  />
                )}
                {watchSelect === 4 && (
                  <ChoiceInputs
                    defaultConfiguration={!isEdit ? "" : field.configuration}
                  />
                )}
                {watchSelect === 0 && (
                  <TextboxInputs
                    isEdit={!!isEdit}
                    defaultConfiguration={!isEdit ? "" : field.configuration}
                  />
                )}
                {watchSelect === 9 && (
                  <PeoplePickerInputs
                    isEdit={!!isEdit}
                    defaultConfiguration={!isEdit ? "" : field.configuration}
                  />
                )}
                {watchSelect === 11 && (
                  <CalculatedFieldInput name="calculation" isEdit={!!isEdit} />
                )}
                {watchSelect === 5 && (
                  <MultiChoice
                    isEdit={isEdit}
                    list={field.configuration?.choiceList as Choice[]}
                  />
                )}
                <Grid container className="button-container" spacing={1}>
                  <Grid item>
                    <EdisonButton
                      onClick={cancelOperation}
                      name={t("cancel")}
                    />
                  </Grid>
                  <Grid item>
                    <EdisonButton type="submit" name={t("save")} />
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          ) : (
            <Loading />
          )}
        </Grid>
      </Grid>
    </Layout>
  );
};

export default NewField;
