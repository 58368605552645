import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  Rating,
  Stack,
  Switch,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import ProjectsTabs from "../../components/projectstabs/ProjectsTabs";
import { selectCurrentForm } from "../../redux/reducers/formsSlice";
import { useAppSelector } from "../../redux/store/hooks";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import "./viewproject.scss";
import WrappedPeoplePicker from "../../components/wrappedpeoplepicker/WrappedPeoplePicker";
import colors from "../../theme/colors.module.scss";
import EdisonTypography from "../../../app-shared/edison/typography/EdisonTypography";
import EdisonCard from "../../../app-shared/edison/card/EdisonCard";
import EdisonExpandableMenu from "../../../app-shared/edison/expandablemenu/EdisonExpandableMenu";
const defaultImgSrc =
  "https://s3-alpha-sig.figma.com/img/6a09/49ad/c51074839596ef8d90d0f1d7818f810f?Expires=1649030400&Signature=FMZrrb229knUYMBkaUeSPPlWE7jENimiDQwN6g4cCLvdI3nQ4T7DB-dbnlry056~1DrhsxkKH~ZeElMEqZQ2TtGCvbIC-2gLFUH2QGN-xztdyllF4mV5UQdBbelWhfKBmxYHW8~WEfKKbjbAlPHewiZ3sQU6-V~LjwhAgw17PLf-8TR04yhHG~L0uoFlldYm~NxSUHAwkM-~AyDTzqNEfqBoN4Y7fjgSRSg9JyKDmMUCoQVIVCx5JkrbK7b379gtxT2JIueCq0erunuEPYkaViAoUUXWCo6Tnd326n2LNuH1w8Vp5oYIZeJ1vyu8UzBC8nJeQ3xMstJW1JqtrJ69hg__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA";

const ViewProject = () => {
  const location = useLocation();
  const history = useHistory();
  const currentForm = useAppSelector(selectCurrentForm);
  const { t } = useTranslation(["projects"]);

  const [flyoutOpen, setFlyoutOpen] = useState(false);
  useEffect(() => {
    console.log("params1", location.search);
    console.log("params2", location.key);
  }, []);

  return (
    <Stack direction="row" className="view-project-root">
      <div className={`projects-content ${flyoutOpen && "flyout-open"}`}>
        <EdisonCard padding="1em 0 1em 0">
          <Stack spacing={2}>
            <Stack direction="row" className="top-bar">
              <Stack direction="row" spacing={6}>
                <Button
                  startIcon={<ArrowBackIcon />}
                  onClick={() => history.goBack()}>
                  {t("back")}
                </Button>
                <div className="title-container">
                  <EdisonTypography title={currentForm?.name?? ""} variant="t2" />
                </div>
              </Stack>
              <Stack direction="row" spacing={1}>
                <IconButton onClick={() => console.log("message clicked")}>
                  <ForumOutlinedIcon />
                </IconButton>
                <Button startIcon={<PreviewOutlinedIcon />}>
                  {t("liveVersion")}
                </Button>
              </Stack>
            </Stack>

            <ProjectsTabs />
          </Stack>
        </EdisonCard>
      </div>
      <Paper className={`flyout ${flyoutOpen && "flyout-open"}`}>
        {flyoutOpen ? (
          <Stack>
            <Stack direction="row" className={"flyout-top-toolbar"}>
              <Stack direction="row">
                <ChevronRightIcon
                  fontSize="medium"
                  className="chevron"
                  onClick={() => setFlyoutOpen((prev) => !prev)}
                />
                <Divider orientation="vertical" flexItem />
                <Rating />
              </Stack>
              <Stack direction="row" spacing={2}>
                <Stack direction="row">
                  <VisibilityOutlinedIcon /> 129
                </Stack>
                <FavoriteBorderOutlinedIcon /> <ShareOutlinedIcon />
              </Stack>
            </Stack>
            <Divider flexItem />
            <Stack className="flyout-content" spacing={1}>
              <img src={defaultImgSrc} className="flyout-image" />
              <Stack spacing={1}>
                <EdisonTypography title="Stage" variant="t7" />
                <Stack direction={"row"} spacing={2}>
                  <EdisonTypography title="Previous" variant="t7" />
                  <EdisonTypography title="Draft" variant="t7" />
                </Stack>
                <Stack
                  direction="row"
                  style={{
                    backgroundColor: colors.grey50,
                    padding: "0.3em",
                    display: "flex",
                    justifyContent: "space-between",
                  }}>
                  <Stack direction="row" spacing={1}>
                    <Box
                      component="span"
                      style={{
                        width: 20,
                        height: 20,
                        borderRadius: "50%",
                        backgroundColor: "green",
                      }}
                    />
                    <EdisonTypography title="Published" variant="t4" />
                  </Stack>
                  <EdisonTypography title="3 days Remaining" variant="t10" />
                </Stack>
              </Stack>
              <Stack>
                <EdisonTypography title="Owner" variant="t5" />
                <WrappedPeoplePicker
                  readOnly={false}
                  component={{ displayName: "Owner" }}
                  useInternalState={true}
                  value={[]}
                />
              </Stack>
              <EdisonExpandableMenu title="Gamification" variant="t5">
                <Stack>
                  <div> Enabled</div>
                  <Switch/>
                </Stack>
              </EdisonExpandableMenu>

              <EdisonExpandableMenu title="Integrations" variant="t5">

              </EdisonExpandableMenu>

              <EdisonExpandableMenu title="Triage" variant="t5">
              </EdisonExpandableMenu>
              <EdisonExpandableMenu title="Notifications" variant="t5">
              </EdisonExpandableMenu>
              <EdisonExpandableMenu title="Details" variant="t5">
              </EdisonExpandableMenu>
              <EdisonExpandableMenu title="Versioning" variant="t5">
              </EdisonExpandableMenu>
            </Stack>
          </Stack>
        ) : (
          <div>
            <ChevronLeftIcon
              fontSize="medium"
              className="chevron"
              onClick={() => setFlyoutOpen((prev) => !prev)}
            />
          </div>
        )}
      </Paper>
    </Stack>
  );
};

export default ViewProject;
