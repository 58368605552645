import fieldTypes from "../../models/FieldTypes/FieldTypes.enum";
import tableTypes from "../../models/TableTypes/TableTypes.enum";

export const getTableDataTypes: any = () => ({
  standard: tableTypes.find((types) => types.id === 0)?.displayName,
  periodic: tableTypes.find((types) => types.id === 1)?.displayName,
  task: tableTypes.find((types) => types.id === 2)?.displayName,
});

export const getFieldTypes: any = () => ({
  textBox: fieldTypes.find((types) => types.id === 0)?.displayName,
  richText: fieldTypes.find((types) => types.id === 1)?.displayName,
  number: fieldTypes.find((types) => types.id === 2)?.displayName,
  image: fieldTypes.find((types) => types.id === 3)?.displayName,
  choice: fieldTypes.find((types) => types.id === 4)?.displayName,
  multiChoice: fieldTypes.find((types) => types.id === 5)?.displayName,
  rag: fieldTypes.find((types) => types.id === 6)?.displayName,
  date: fieldTypes.find((types) => types.id === 7)?.displayName,
  switch: fieldTypes.find((types) => types.id === 8)?.displayName,
  people: fieldTypes.find((types) => types.id === 9)?.displayName,
  url: fieldTypes.find((types) => types.id === 10)?.displayName,
  calculated: fieldTypes.find((types) => types.id === 11)?.displayName,
});
