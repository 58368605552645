import AddIcon from "@mui/icons-material/Add";
import React, { FC } from "react";
import "./tabcontainer.scss";
import Tab from "./Tab";
import { useAppDispatch } from "../../store/hooks";
import { addTab } from "../../features/livepreview/livepreviewSlice";

export interface TabContainerProps {
  data: any;
}

const TabContainer: FC<TabContainerProps> = ({ data }) => {
  const dispatch = useAppDispatch();

  const renderTab = (tab: any, index: number) => {
    return <Tab key={tab.id} data={tab} index={index} id={index.toString()} />;
  };

  return (
    <div className={`tab-container draggable`}>
      <div className="tabs">
        {data.map((tab: any, index: number) => {
          const currentPath = `${index}`;
          return (
            <React.Fragment key={tab.id}>
              {renderTab(tab, index)}
            </React.Fragment>
          );
        })}

        <AddIcon
          style={{ cursor: "pointer", margin: "auto", marginRight: "10px" }}
          onClick={
            () => dispatch(addTab())
            // handleNewColumn(`${path}-${data.children.length}`, data)
          }
        />
      </div>
    </div>
  );
};
export default TabContainer;
