import { postTables, putTables } from "../../../services/APIService";
import React, { FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import AdminFormInput from "../../components/AdminFormInput";
import Layout from "../../components/layout/Layout";
import LinkedTables from "../../components/linkedtables/LinkedTables";
import Loading from "../../components/loading/Loading";
import PeriodicTableInputs from "../../components/PeriodicTableInputs";
import {
  getFieldsAsync,
  selectAllFields,
} from "../../features/fields/fieldsSlice";
import {
  clearIndividualTable,
  getIndividualTableAsync,
  selectIndividualTable,
} from "../../features/tables/tablesSlice";
import ILocation from "../../../models/ILocation.model";
import Item from "../../../models/Item.model";
import PeriodicTableItem from "../../../models/TableTypes/PeriodicTableItem.model";
import TableField from "../../../models/TableTypes/TableField.model";
import TableItem from "../../../models/TableTypes/TableItem.model";
import tableTypes from "../../../models/TableTypes/TableTypes.enum";
import { getTableDataTypes } from "../../utils/dataTypes";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { RootState } from "../../store/store";
import EdisonButton from "../../../app-shared/edison/button/EdisonButton";
import EdisonCard from "../../../app-shared/edison/card/EdisonCard";
import EdisonFormInput from "../../../app-shared/edison/forminput/EdisonFormInput";
import EdisonFormSelect from "../../../app-shared/edison/formselect/EdisonFormSelect";

import removeWhitespaces from "../../utils/removeWhitespaces";
import "./newtable.scss";
import DefaultViews from "../../../models/TableTypes/PeriodicTableFields/DefaultViews.enum";
import DataTypes from "../../../models/TableTypes/PeriodicTableFields/DataTypes.enum";
import PeriodUnits from "../../../models/TableTypes/PeriodicTableFields/PeriodUnits.enum";

const standardDataType = getTableDataTypes().standard;
const periodicDataType = getTableDataTypes().periodic;

const defaultTable: TableItem = {
  name: "",
  displayName: "",
  id: 0,
  description: "",
  configuration: {},
  modified: new Date(),
  isDeleted: false,
  isSystem: false,
  fields: [],
  dataType: standardDataType,
};
const NewTable: FC = () => {
  const history = useHistory();
  const location: ILocation = useLocation();
  const { t } = useTranslation(["admin"]);
  const dispatch = useAppDispatch();
  const methods = useForm();

  const { handleSubmit, watch, setValue } = methods;
  const watchTableType = watch("tableType", 0);
  const watchTableToCopyIndex = watch("tableToCopy");
  const isEdit = !!location.state && !!location.state.item;

  const table: TableItem = !!isEdit
    ? (location.state.item as TableItem)
    : defaultTable;
  const [currentTableTypeString, setCurrentTableTypeString] =
    useState(standardDataType);
  const [tableConfiguration, setTableConfiguration] = useState<Item[]>([]);
  const [tables] = useState<TableItem[]>(
    location.state.configuration as TableItem[]
  );
  const [fieldIds, setFieldIds] = useState<number[]>([]);

  const individualTable = useAppSelector(selectIndividualTable);
  const fields = useAppSelector(selectAllFields);
  const fieldsStatus = useAppSelector(
    (state: RootState) => state.fields.status
  );
  useEffect(() => {
    dispatch(clearIndividualTable());
    if (isEdit) {
      dispatch(getIndividualTableAsync(table?.id));
    }
    if (fieldsStatus === "idle") {
      dispatch(getFieldsAsync());
    }
  }, []);

  useEffect(() => {
    if (!isEdit) {
      setFieldIds([]);
      setValue("periodicUnits", "weeks");
      setValue("defaultView", "periodic");
      setValue("dataTypeUnit", "cost");
      setValue("tableToCopy", 0);
    }
    const currentTableType = tableTypes.find(
      (tableType: any) => tableType.id === (watchTableType as number)
    );
    setCurrentTableTypeString(
      !!currentTableType ? currentTableType.displayName : standardDataType
    );
  }, [watchTableType]);

  useEffect(() => {
    if (!!individualTable && isEdit) {
      propagateIndividualTableConfig();
    }
  }, [isEdit, individualTable]);

  useEffect(() => {
    if (!isEdit) {
      const validIndex = !!watchTableToCopyIndex ? watchTableToCopyIndex : 0;
      const selectedTable = tables.filter(
        (tableToFilter) => tableToFilter.dataType === currentTableTypeString
      )[validIndex];
      dispatch(getIndividualTableAsync(selectedTable?.id));
    }
  }, [watchTableToCopyIndex, currentTableTypeString]);

  const propagateIndividualTableConfig = () => {
    if (!individualTable) return;
    setFieldIds(individualTable?.fields?.map((field: any) => field.fieldId));
    if (individualTable.dataType === periodicDataType) {
      const periodTable = individualTable as PeriodicTableItem;
      setValue(
        "periodicUnits",
        PeriodUnits[periodTable.configuration.periodicUnits]
      );
      setValue(
        "defaultView",
        DefaultViews[periodTable.configuration.defaultView]
      );
      setValue(
        "dataTypeUnit",
        DataTypes[periodTable.configuration.dataTypeUnit]
      );
    }
  };
  const getSelectedFields = (): TableField[] => {
    return tableConfiguration.map((field) => ({
      fieldId: field.id,
      personal: false,
      minWidth: "",
      order: "",
      configuration: {},
    }));
  };

  const goBack = () => {
    history.push({
      pathname: "/admin/tables",
      state: { title: t("tables") },
    });
  };

  const onSubmit = async (data: any) => {
    const tempRequest = {
      type: currentTableTypeString,
      dataType: currentTableTypeString,
      isDeleted: false,
      name: removeWhitespaces(data.displayName),
      periodUnits: PeriodUnits[data.periodicUnits],
      defaultView: DefaultViews[data.defaultView],
      configuration:
        data.tableType === 1
          ? {
              periodicUnits: PeriodUnits[data.periodicUnits],
              defaultView: DefaultViews[data.defaultView],
              dataTypeUnit: DataTypes[data.dataTypeUnit],
            }
          : {},
      fields: getSelectedFields(),
    };
    const {
      dataTypeUnit,
      periodicUnits,
      defaultView,
      tableType,
      tableToCopy,
      ...baseData
    } = data;

    const result = { ...baseData, ...tempRequest };
    !!isEdit
      ? await putTables({ ...result, id: table.id })
      : await postTables(result);
    goBack();
  };
  return (
    <Layout>
      <div className="new-table-page-root">
        {fieldsStatus !== "loading" ? (
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="new-table-text-inputs">
                <AdminFormInput
                  name="displayName"
                  label={t("label")}
                  internalName={isEdit ? table.name : undefined}
                  hasIDField={true}
                  requiredMessage={t("labelRequiredMessage")}
                  defaultValue={table.displayName}
                />
                <EdisonFormInput
                  disabled={false}
                  name="description"
                  label={t("description")}
                  requiredMessage={t("descriptionRequiredMessage")}
                  defaultValue={table.description}
                />
              </div>

              <div className="new-table-option-inputs">
                <EdisonFormSelect
                  name="tableType"
                  defaultValue={
                    tableTypes.find(
                      (filterTable: any) =>
                        table.dataType === filterTable.displayName
                    )?.id
                  }
                  label={t("tableType")}
                  fullWidth={true}
                  isDisabled={isEdit}
                  requiredMessage=""
                  data={tableTypes.map((tableType: any) => ({
                    id: tableType.id,
                    displayName: t(tableType.displayName),
                  }))}
                />

                {!isEdit && (
                  <div className="copy-from-table-container">
                    <EdisonFormSelect
                      name="tableToCopy"
                      defaultValue={isEdit ? "Disabled" : 0}
                      label={t("copyFromTable")}
                      fullWidth={true}
                      isDisabled={isEdit}
                      requiredMessage=""
                      data={
                        isEdit
                          ? []
                          : tables.filter(
                              (tableToFilter) =>
                                currentTableTypeString ===
                                tableToFilter.dataType
                            )
                      }
                    />
                    <div className="copy-button">
                      <EdisonButton
                        name={t("copy")}
                        disabled={isEdit}
                        onClick={() => propagateIndividualTableConfig()}
                      />
                    </div>
                  </div>
                )}
              </div>

              {((isEdit && table.dataType === periodicDataType) ||
                currentTableTypeString === periodicDataType) && (
                <EdisonCard>
                  <PeriodicTableInputs
                    defaultConfiguration={table.configuration}
                  />
                </EdisonCard>
              )}
              <LinkedTables
                data={fields}
                firstTableTitle={t("fieldsAvailableToAdd")}
                secondTableTitle={t("tableConfiguration")}
                ids={fieldIds}
                setParentData={setTableConfiguration}
              />
              <div className="button-container">
                <EdisonButton fill={true} type="submit" name={t("save")} />
                <EdisonButton onClick={goBack} name={t("cancel")} />
              </div>
            </form>
          </FormProvider>
        ) : (
          <Loading />
        )}
      </div>
    </Layout>
  );
};
export default NewTable;
