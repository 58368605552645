import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Avatar,
  AvatarGroup,
  Chip,
  FilterOptionsState,
  Grid,
  ListItem,
  ListSubheader,
  Popper,
  TextField,
} from "@mui/material";
import React, { FC, useState } from "react";
import BaseUserFieldProps from "../../models/BaseUserFieldProps.model";
import { User } from "./../../models/peoplepicker/peoplePicker.model";
import UserPeoplePickerProfile from "./profile/UserPeoplePickerProfile";
import "./userpeoplepicker.scss";

export interface UserPeoplePickerProps extends BaseUserFieldProps {
  isEditing: boolean;
  peopleList: any;
  placeholder: string;
  multiple: boolean;
  maxAvatars: number;
  noOptionsText: string;
  maxDropdownCount: number;
}
const CustomPopper = (props: any) => (
  <Popper
    {...props}
    className="user-people-picker-popper-root"
    placement="bottom"
  />
);

const UserPeoplePicker: FC<UserPeoplePickerProps> = ({
  isEditing,
  peopleList,
  label,
  multiple,
  maxAvatars,
  noOptionsText,
  value,
  maxDropdownCount,
  onChange,
  useInternalState,
  disabled,
}) => {
  const [inputValue, setInputValue] = React.useState("");
  const [internalState, setInternalState] = useState(value);
  const updateValue = (newValue: any) => {
    useInternalState ? setInternalState(newValue) : onChange(newValue);
  };
  const getCurrentValue = () => (useInternalState ? internalState : value);
  return (
    <>
      {isEditing ? (
        <Autocomplete
          PopperComponent={CustomPopper}
          multiple
          options={peopleList}
          groupBy={(option) => option.type}
          inputValue={inputValue}
          disabled={disabled}
          value={getCurrentValue()}
          onChange={(event: any, newValue: any) => {
            if (multiple || peopleList.length === 0) {
              updateValue(newValue);
            } else {
              const updated = newValue.pop();
              updated ? updateValue([updated]) : updateValue([]);
            }
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          filterOptions={(options: User[], state: FilterOptionsState<User>) => {
            if (state.inputValue === "") {
              return options.slice(0, maxDropdownCount);
            }
            return options
              .filter((user: User) =>
                user.userName
                  .toLowerCase()
                  .includes(state.inputValue.toLowerCase())
              )
              .slice(0, maxDropdownCount);
          }}
          isOptionEqualToValue={(option, value) =>
            option.userName === value.userName
          }
          renderGroup={(params) => (
            <div key={params.key}>
              <ListSubheader inset={true} style={{ zIndex: 3 }}>
                {params.group}
              </ListSubheader>
              {params.children}
            </div>
          )}
          renderTags={(options) => (
            <Grid direction="row" container spacing={1}>
              {options.map((option, index: number) => (
                <Grid key={index} item>
                  <Chip
                    label={option.userName}
                    avatar={
                      <Avatar alt={option.userName} src={option.imageUrl}>
                        {getInitials(option.userName)}
                      </Avatar>
                    }
                    onDelete={() => {
                      updateValue(
                        getCurrentValue().filter(
                          (user: User) => user.userName !== option.userName
                        )
                      );
                    }}
                    deleteIcon={<CloseIcon sx={{ width: 24, height: 24 }} />}
                  />
                </Grid>
              ))}
            </Grid>
          )}
          getOptionLabel={(option) => option.userName}
          noOptionsText={noOptionsText}
          renderInput={(params) => <TextField {...params} label={label} />}
          renderOption={(props, option) => (
            <ListItem {...props}>
              <UserPeoplePickerProfile user={option} />
            </ListItem>
          )}
        />
      ) : (
        <>
          {multiple ? (
            <AvatarGroup max={maxAvatars} className="avatar-group-root">
              {getCurrentValue().map((user: User, index: number) => (
                <Avatar
                  key={index}
                  alt={user.userName}
                  src={user.imageUrl}></Avatar>
              ))}
            </AvatarGroup>
          ) : (
            peopleList.length === 1 && (
              <UserPeoplePickerProfile user={peopleList[0]} />
            )
          )}
        </>
      )}
    </>
  );
};
export default UserPeoplePicker;

export const getInitials = (name: string): string => {
  const split = name.split(" ");
  let initials = "";
  for (let i = 0; i < split.length; i++) {
    const chunk = split[i];

    initials += chunk[0];
  }
  return initials;
};
