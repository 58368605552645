import { ThemeProvider } from "@mui/material/styles";
import React, { FC, ReactNode, useEffect, useState } from "react";
import { getThemeByName } from "./appTheme";
import colorPallete from "./colorPallete.json";

export const ThemeContext = React.createContext((themeName: string): void => {
  // create context
});

const setPropertyColor = (property: string, color: string) => {
  document.documentElement.style.setProperty(property, color);
};

interface Props {
  children: ReactNode;
}

const ThemeContainer: FC<Props> = ({ children }) => {
  // State to hold the selected theme name (will come from api?)
  const [themeName, _setThemeName] = useState("adminTheme");

  // Retrieve the theme object by theme name

  const theme = getThemeByName(themeName);

  useEffect(() => {
    setPropertyColor("--primary-color", theme.palette.primary.main);
    setPropertyColor("--secondary-color", theme.palette.secondary.main);
    setPropertyColor("--primary-text-color", theme.palette.text.primary);
    setPropertyColor("--secondary-text-color", theme.palette.text.secondary);
    setPropertyColor("--ideas-color", colorPallete.ideasColor);
    setPropertyColor("--projects-color", colorPallete.projectsColor);
    setPropertyColor("--admin-color", colorPallete.adminColor);
    setPropertyColor("--admin-color-30", colorPallete.adminColor30);
    setPropertyColor("--cases-color", colorPallete.casesColor);
    setPropertyColor("--light-grey-color", colorPallete.lightGrey);
    setPropertyColor("--grey-100-color", colorPallete.grey100);
    setPropertyColor("--grey-50-color", colorPallete.grey50);
    setPropertyColor("--warning-color", colorPallete.warning);
    setPropertyColor("--white-color", colorPallete.white);
    setPropertyColor("--black-color", colorPallete.black);
    setPropertyColor("--drop-color", colorPallete.dropColor);
    setPropertyColor("--dark-primary-color", colorPallete.darkPrimaryColor);
    setPropertyColor("--card-outline", colorPallete.cardOutline);
    setPropertyColor("--toolbar-color", colorPallete.toolbarColor);
  }, []);

  return (
    <ThemeContext.Provider value={_setThemeName}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
      {/* <ThemeRoot theme={theme} /> */}
    </ThemeContext.Provider>
  );
};

export default ThemeContainer;
