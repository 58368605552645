export type ExpressionUnitType = "operator" | "operand"| "number"| "text" ;

export type Operator = "+" | "-" | "*" | "/" | "(" | ")" | "<" | ">" | "=" | "!=";

export interface Operand {
  id: number;
  name: string;
  displayName: string;
}

export interface ExpressionUnit {
  type: ExpressionUnitType;
  value: Operand | Operator| number | string ;
}

export interface ConditionBlock {
  conditionType: ConditionOperator;
  conditionBlock: ExpressionUnit[];
  resultBlock: ExpressionUnit[];
}
export type ConditionOperator = "if" | "else" | "elseIf";


export const ComparatorList: ExpressionUnit[] = [
  {
    type: "operator",
    value: "<",
  },
  {
    type: "operator",
    value: ">",
  },
  {
    type: "operator",
    value: "=",
  },
  {
    type: "operator",
    value: "!=",
  },
];
export const OperatorList: ExpressionUnit[] = [
  {
    type: "operator",
    value: "+",
  },
  {
    type: "operator",
    value: "-",
  },
  {
    type: "operator",
    value: "*",
  },
  {
    type: "operator",
    value: "/",
  },
  {
    type: "operator",
    value: "(",
  },
  {
    type: "operator",
    value: ")",
  },
];
export type RowType = "condition" | "then" | "else";
export interface CurrentFocusedRow {
  rowIndex: number;
  rowType: RowType;
}

export interface MoveExpressionAction {
  dragIndex: number;
  hoverIndex: number;
}

export interface BackendCalculatedFieldModel {
  expressionName: string;
  expressionType: BackendExpressionType;
  expression: string;
  expressionSuccess?: string;
  expressionFail?: string;
}
export interface TransformedBackendExpression {
  expression: ConditionBlock[] | ExpressionUnit[];
  isCondition: boolean;
}

export type BackendExpressionType = "calc" | "if";

export interface DependencyId{
  id: number,
  fieldId: number,
  parentFieldId: number
}

export const fieldFormatList:string[]=["text", "currency", "percentage"]