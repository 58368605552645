import { Stack, Switch } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import EdisonTabs from "../../../app-shared/edison/tabs/EdisonTabs";
import {
  selectCurrentForm,
  selectFormReadOnly,
  setReadOnly,
} from "../../redux/reducers/formsSlice";
import { useAppSelector } from "../../redux/store/hooks";
import colors from "../../theme/colors.module.scss";

import SingleTab from "./SingleTab";
const ProjectsTabs = () => {
  const dispatch = useDispatch();
  const currentForm = useAppSelector(selectCurrentForm);
  const readOnly = useAppSelector(selectFormReadOnly);
  // Bad Performance in tab probably linked to this:=> https://github.com/mui/material-ui/issues/23710
  return (
    <EdisonTabs
      tabs={
        currentForm
          ? currentForm.containers.map((container) => ({
              label: container.displayName,
              children: (
                <Stack>
                  <Stack>
                    <div> ReadOnly </div>
                    <Switch
                      checked={readOnly}
                      onChange={(e) => dispatch(setReadOnly(e.target.checked))}
                    />
                  </Stack>
                  <SingleTab container={container} />
                </Stack>
              ),
            }))
          : []
      }
      height="100%"
      indicatorColour={colors.primaryColor}
      scrollable={true}
      tabTitleBoxStyling={{
        border: "1px solid",
        borderRadius: "16px",
        borderColor: "divider",
      }}
    />
  );
};

export default ProjectsTabs;
