import { CssBaseline } from "@mui/material";
import clsx from "clsx";
import React, { FC, ReactNode, useReducer, useState } from "react";
import CreateDrawer from "../CreateDrawer/CreateDrawer";
import Header from "../Header/Header";
import Navigation from "../Navigation/Navigation";
import "./layout.scss";

interface Props {
  children: ReactNode;
}

const Layout: FC<Props> = ({ children }) => {
  // const classes = useStyles();
  const [open, toggle] = useReducer((isOpen) => !isOpen, true);
  const [rightPanelOpen, setRightPanelOpen] = useState(false);
  return (
    <div className={"layout"}>
      <CssBaseline />
      <Header open={open} handleMenuOpen={toggle} />
      <Navigation
        open={open}
        handleMenuClose={toggle}
        toggleRightPanel={setRightPanelOpen}
      />
      <main
        className={clsx("content", {
          ["content-shift"]: open,
        })}>
        {children}
      </main>
      <CreateDrawer open={rightPanelOpen} toggleOpen={setRightPanelOpen} />
    </div>
  );
};

export default Layout;
