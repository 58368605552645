import { Box } from "@mui/material";

import React, { FC, ReactNode } from "react";
import EdisonTypography from "../../app-shared/edison/typography/EdisonTypography";
import EdisonCard from "../../app-shared/edison/card/EdisonCard";
import "./homecard.scss";

interface Props {
  children: ReactNode;
  title: string;
}

const HomeCard: FC<Props> = ({ children, title }) => {
  return (
    <div className="home-card-root">
      <EdisonCard>
        <Box>
          <EdisonTypography
            variant="t3"
            title={title}
            disableUppercase={true}
          />
        </Box>
        <div>{children}</div>
      </EdisonCard>
    </div>
  );
};

export default HomeCard;
