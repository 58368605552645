import { Box, Grid, Icon, Paper, Typography } from "@mui/material";
import DefaultIcon from "@mui/icons-material/FileCopy";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import { routes } from "../../config/routes";
import RouteItem from "../../../models/RouteItem.model";
import EdisonTypography from "../../../app-shared/edison/typography/EdisonTypography";
import "./administration.scss";

const Administration: FC = () => {
  const { t } = useTranslation(["admin"]);

  return (
    // <Provider store={store}>
    <Layout>
      <div className="administration-container">
        <Grid container spacing={3}>
          {routes
            .filter((route: RouteItem) => !!route.isAdminOption)
            .map((route: RouteItem) => (
              <Grid item xs={6} sm={3} key={`${route.key}`}>
                <Paper className="paper">
                  <NavLink
                    to={{
                      pathname: `${route.path}`,
                      state: {
                        title: t(route.key),
                      },
                    }}
                    className="admin-navlink">
                    <div>
                      {route.icon}

                      <EdisonTypography title={t(route.key)} variant="t2" />
                      <Typography
                        className="desc"
                        variant="body2"
                        display="block">
                        <EdisonTypography
                          title={t(route.description || "home")}
                          variant="t8"
                          disableUppercase
                        />
                        {/* <Box letterSpacing={1}>{t(route.description)}</Box> */}
                      </Typography>
                    </div>
                  </NavLink>
                </Paper>
              </Grid>
            ))}
        </Grid>
      </div>
    </Layout>
    // </Provider>
  );
};

export default Administration;
