import { Box, Grid, Typography } from "@mui/material";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { section } from "../../config/constants";
import {
  selectAllButtons,
  selectAllNonDeletedFields,
} from "../../features/fields/fieldsSlice";
import { selectAllTables } from "../../features/tables/tablesSlice";
import { useAppSelector } from "../../store/hooks";
import FeatureList from "../featurelist/FeatureList";
import SearchableTabs from "../tabs/SearchableTabs";
import "./features.scss";
export interface FeaturesProps {
  tabs: any[];
}

const Features: FC<FeaturesProps> = ({ tabs }) => {
  const { t } = useTranslation(["admin"]);

  const fieldsSelector = useAppSelector(selectAllNonDeletedFields);
  const tablesSelector = useAppSelector(selectAllTables);
  const buttonsSelector = useAppSelector(selectAllButtons);
  const tabsContent = [
    {
      label: "fields",
      children: (
        <FeatureList featureSections={section[0]} data={fieldsSelector} />
      ),
    },
    {
      label: "tables",
      children: (
        <FeatureList featureSections={section[1]} data={tablesSelector} />
      ),
    },
    {
      label: "buttons",
      children: (
        <FeatureList featureSections={section[2]} data={buttonsSelector} />
      ),
    },
  ];

  return (
    <Grid container className="features-container">
      <Grid item alignContent="center" className="drag-drop-container">
        <Typography variant="caption" display="block">
          <Box letterSpacing={3} className="drag-drop-heading">
            ({t("dragAndDrop")})
          </Box>
        </Typography>
      </Grid>
      <div className="tab-container">
        <SearchableTabs
          tabs={tabsContent}
          tabsToSearch={["buttons", "tables", "fields"]}
        />
      </div>
    </Grid>
  );
};

export default Features;
