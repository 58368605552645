import { FormLabel, Grid } from "@mui/material";
import React, { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  selectCurrentFocusedRow,
  selectIsCondition,
} from "../../../features/fields/calculatedFieldSlice";
import { getFieldsAsync } from "../../../features/fields/fieldsSlice";
import {
  ExpressionUnit,
  Operand,
} from "../../../../models/calculatedfield/calculatedfield.model";
import FieldItem from "../../../../models/FieldTypes/FieldItem.model";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import "./fieldstab.scss";
import FieldsTabRow from "./FieldsTabRow";

export interface FieldsTabProps {
  addExpressionUnit: (unitToAdd: ExpressionUnit) => void;
  data: FieldItem[];
}
const FieldsTab: FC<FieldsTabProps> = ({ addExpressionUnit, data }) => {
  const { t } = useTranslation(["admin"]);
  const { watch } = useFormContext();
  const watchFormatList = watch("format");

  const dispatch = useAppDispatch();
  const fieldsStatus = useAppSelector(
    (state: RootState) => state.fields.status
  );
  const currentFocusedRow = useAppSelector(selectCurrentFocusedRow);
  const isCondition = useAppSelector(selectIsCondition);
  useEffect(() => {
    if (fieldsStatus === "idle") {
      dispatch(getFieldsAsync());
    }
  }, []);
  return (
    <Grid container direction="column" className="fields-column">
      <FormLabel component="legend">{t("userValue")}</FormLabel>
      <FieldsTabRow
        isDisabled={
          isCondition &&
          watchFormatList === "text" &&
          ["then", "else"].includes(currentFocusedRow.rowType)
        }
        fieldName={t("addCustomNumber")}
        fieldType={t("number")}
        onClick={() => addExpressionUnit({ type: "number", value: 0 })}
      />
      <FieldsTabRow
        isDisabled={
          isCondition &&
          ["currency", "percentage"].includes(watchFormatList) &&
          ["then", "else"].includes(currentFocusedRow.rowType)
        }
        fieldName={t("addCustomText")}
        fieldType={t("text")}
        onClick={() =>
          addExpressionUnit({ type: "text", value: t("enterText") as string })
        }
      />
      <FormLabel component="legend">{t("fieldsLower")}</FormLabel>
      {data.map((field, index) => (
        <FieldsTabRow
          key={index}
          fieldName={field.name}
          fieldType={field.dataType}
          isDisabled={
            isCondition &&
            watchFormatList === "text" &&
            ["then", "else"].includes(currentFocusedRow.rowType)
          }
          onClick={() =>
            addExpressionUnit({
              type: "operand",
              value: field as unknown as Operand,
            })
          }
        />
      ))}
    </Grid>
  );
};

export default FieldsTab;
