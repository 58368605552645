import { Stack } from "@mui/material";
import React, { useEffect } from "react";
import PersonalProfile from "../../components/PersonalProfile/PersonalProfile";
import { getMicrosoftGraphUserAsync } from "../../features/user/userSlice";
import { useAppDispatch } from "../../store/hooks";
import EdisonTypography from "../../app-shared/edison/typography/EdisonTypography";
import EdisonCard from "../../app-shared/edison/card/EdisonCard";

const Personal = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getMicrosoftGraphUserAsync());
  }, []);
  return (
    <Stack direction="column">
      <Stack flexGrow={2}>
        <EdisonCard>
          <PersonalProfile />
        </EdisonCard>
      </Stack>
      <Stack flexGrow={3}>
        <EdisonCard>
          <EdisonTypography
            variant="t9"
            title={"world"}
            disableUppercase={true}
          />
        </EdisonCard>
      </Stack>
      <Stack flexGrow={5}>
        <EdisonCard>
          <EdisonTypography
            variant="t9"
            title={"check"}
            disableUppercase={true}
          />
        </EdisonCard>
      </Stack>
    </Stack>
  );
};

export default Personal;
