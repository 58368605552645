import AltRouteIcon from "@mui/icons-material/AltRoute";
import CloseIcon from "@mui/icons-material/Close";
import GradingIcon from "@mui/icons-material/Grading";
import MicNoneOutlinedIcon from "@mui/icons-material/MicNoneOutlined";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import { IconButton, Stack } from "@mui/material";
import React, { FC } from "react";
import { useDispatch } from "react-redux";
import { deleteNode } from "../../../../features/workflow/workflowSlice";
import "./nodetitle.scss";

export interface NodeTitleProps {
  data: any;
}
const NodeTitle: FC<NodeTitleProps> = ({ data }) => {
  const dispatch = useDispatch();

  return (
    <div
      className="node-title-root"
      style={{
        backgroundColor: `${hexToRgb(
          (data.configuration.color as string).toLowerCase(),
          0.25
        )}`,
        borderBottom: `1px solid ${hexToRgb(
          (data.configuration.color as string).toLowerCase(),
          1
        )}`,
      }}>
      <Stack direction="row">
        {getNodeIcon(data.configuration.type)}
        <div className="node-title-container">
          <div className="node-title"> {data.displayName}</div>
          <IconButton onClick={() => dispatch(deleteNode(data.id))}>
            <CloseIcon className="icon-button" />
          </IconButton>
        </div>
      </Stack>
    </div>
  );
};

const hexToRgb = (hex: string, opacity: number): string => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  const rgbValues = result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
  return `rgba(${rgbValues?.r},${rgbValues?.g},${rgbValues?.b},${opacity} )`;
};

const getNodeIcon = (nodeType: string) => {
  switch (nodeType) {
    case "Stage":
      return <GradingIcon />;
    case "StageReview":
      return <PlaylistAddCheckIcon />;
    case "Condition":
      return <AltRouteIcon />;
    default:
      return <MicNoneOutlinedIcon />;
  }
};
export default NodeTitle;
