import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import { Button, Divider, Drawer, Grid, Stack, TextField } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import EdisonSearchBar from "../../app-shared/edison/searchbar/EdisonSearchBar";
import {
  createProjectAsync,
  selectCurrentProject,
  setCurrentProject,
} from "../../features/projects/containerProjectSlice";
import {
  getProjectsTypesAsync,
  selectProjectTypes,
} from "../../features/projectTypes/projectTypesSlice";
import { useAppSelector } from "../../store/hooks";
import ProjectTypeCard from "./ProjectTypeCard";
import "./createdrawer.scss";
export interface CreateDrawerProps {
  open: boolean;
  toggleOpen: any;
}
const CreateDrawer: FC<CreateDrawerProps> = ({ open, toggleOpen }) => {
  const [currentProjectType, setCurrentProjectType] = useState(null);
  const dispatch = useDispatch();
  const projectTypes = useAppSelector(selectProjectTypes);
  const currentProject = useAppSelector(selectCurrentProject);
  const [searchFilter, setSearchFilter] = useState("");
  useEffect(() => {
    dispatch(getProjectsTypesAsync());
  }, []);

  return (
    <Drawer open={open} anchor="right" hideBackdrop={true}>
      <Stack className="create-drawer-root">
        <Stack direction="row" spacing={1}>
          <ChevronLeftOutlinedIcon
            onClick={() => {
              toggleOpen(false);
              setCurrentProjectType(null);
            }}
            className="chevron-pointer"
          />
          <Divider flexItem orientation="vertical" />
          <div> Create Project</div>
        </Stack>
        <Divider flexItem />
        {currentProjectType ? (
          <Stack className="create-project-container" spacing={2}>
            <Stack
              style={{
                padding: "1em",
              }}
              spacing={2}>
              <div> ProjectType</div>
              <Stack direction="row" className="project-card-container">
                <ProjectTypeCard projectType={currentProjectType} />
                <div className="change-button-container">
                  <Button onClick={() => setCurrentProjectType(null)}>
                    Change
                  </Button>
                </div>
              </Stack>
              <Stack spacing={1}>
                <Stack>
                  <div> Whats your project</div>

                  <TextField
                    value={currentProject.displayName ?? ""}
                    size="small"
                    className="no-label-input"
                    onChange={(e) =>
                      dispatch(
                        setCurrentProject({
                          ...currentProject,
                          displayName: e.target.value,
                        })
                      )
                    }
                  />
                </Stack>
              </Stack>
            </Stack>
            <Stack>
              <Divider />
              <Stack direction="row" className="buttons-container" spacing={1}>
                <Button
                  onClick={() => {
                    dispatch(createProjectAsync(currentProject));
                    dispatch(setCurrentProject({}));
                    setCurrentProjectType(null);
                  }}>
                  Create
                </Button>
                <Button
                  onClick={() => {
                    dispatch(setCurrentProject({}));
                    setCurrentProjectType(null);
                  }}>
                  Cancel
                </Button>
              </Stack>
            </Stack>
          </Stack>
        ) : (
          <Stack className="project-type-list-container" spacing={2}>
            <div> Project Type</div>
            <EdisonSearchBar
              width="100%"
              animated={false}
              searchFilter={searchFilter}
              setSearchFilter={setSearchFilter}
            />
            <Grid container spacing={2}>
              {projectTypes
                .filter((projectType) =>
                  (projectType.displayName as string).includes(searchFilter)
                )
                .map((projectType, index) => (
                  <Grid
                    item
                    key={index}
                    className="grid-item"
                    onClick={() => {
                      setCurrentProjectType(projectType);
                      dispatch(
                        setCurrentProject({
                          projectTypeId: projectType.id,
                          projectPermissions: [],
                        })
                      );
                    }}>
                    <ProjectTypeCard projectType={projectType} />
                  </Grid>
                ))}
            </Grid>
          </Stack>
        )}
      </Stack>
    </Drawer>
  );
};

export default CreateDrawer;
