import {
  Switch,
  FormControl,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import React, { FC } from "react";

import { Controller, useFormContext } from "react-hook-form";


export interface EdisonSwitchProps {
  name: string;
  isDisabled?: boolean;
  label: string;
  defaultValue: boolean;
}

const EdisonFormSwitch: FC<EdisonSwitchProps> = ({
  name,
  isDisabled,
  label,
  defaultValue,
}) => {
  const { control } = useFormContext();

  return (
    <div>
      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            value="top"
            control={
              <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                render={({ field: { onChange, value } }) => (
                  <Switch
                    id={name}
                    checked={value}
                    onChange={onChange}
                    disabled={isDisabled}
                    color="primary"
                  />
                )}
              />
            }
            label={label}
            labelPlacement="top"
          />
        </FormGroup>
      </FormControl>
    </div>
  );
};
export default EdisonFormSwitch;
