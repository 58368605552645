import React, { FC, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { currencies } from "../config/currencies";
import EdisonFormInput from "../../app-shared/edison/forminput/EdisonFormInput";
import EdisonFormSelect from "../../app-shared/edison/formselect/EdisonFormSelect";
import EdisonRadioGroupInput from "../../app-shared/edison/radiogroupinput/EdisonRadioGroupInput";

interface Props {
  defaultConfiguration?: any;
  isEdit?: boolean;
  horizontal?: boolean;
}

const formatList = ["number", "percentage", "currency"];
const decimalOption = ["0", "1", "2", "3", "4", "5"];

export const NumberInputs: FC<Props> = ({
  defaultConfiguration,
  isEdit,
  horizontal,
}) => {
  const { t } = useTranslation(["admin"]);
  const { watch } = useFormContext();
  const watchFormatList = watch("format");
  const [currentFormat, setCurrentFormat] = useState("number");

  useEffect(() => {
    if (isEdit) {
      setCurrentFormat((defaultConfiguration.format as string).toLowerCase());
    }
  }, []);

  useEffect(() => {
    if (!!watchFormatList) {
      setCurrentFormat((watchFormatList as string).toLowerCase());
    }
  }, [watchFormatList]);
  return (
    <div>
      <EdisonRadioGroupInput
        title={t("format")}
        type="format"
        options={formatList}
        defaultValue={
          !!defaultConfiguration ? defaultConfiguration.format : formatList[0]
        }
        textData={{
          percentage: t("percentage"),
          number: t("number"),
          currency: t("currency"),
        }}
        horizontal={horizontal || true}
      />
      {currentFormat === "currency" && (
        <EdisonFormSelect
          name="currency"
          isDisabled={false}
          defaultValue={isEdit ? defaultConfiguration.currency : 0}
          label={t("currency")}
          requiredMessage=""
          data={Object.keys(currencies).map((currency: string) => ({
            id: currency,
            displayName: currency,
          }))}
        />
      )}
      <EdisonRadioGroupInput
        title={t("decimalPlaces")}
        type="decimalPlaces"
        options={decimalOption}
        defaultValue={
          !!defaultConfiguration
            ? defaultConfiguration.decimalPlaces
            : decimalOption[0]
        }
        textData={{}}
        horizontal={horizontal || true}
      />
      <EdisonFormInput
        disabled={false}
        name="minimum"
        label={t("minimumValue")}
        // item={defaultConfiguration}
        defaultValue={
          !!defaultConfiguration ? defaultConfiguration.minimum : ""
        }
        requiredMessage={t("minimumValueRequiredMessage")}
      />
      <EdisonFormInput
        disabled={false}
        name="maximum"
        label={t("maximumValue")}
        // item={defaultConfiguration}
        defaultValue={
          !!defaultConfiguration ? defaultConfiguration.maximum : ""
        }
        requiredMessage={t("maximumValueRequiredMessage")}
      />
    </div>
  );
};
