import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import React, { FC, ReactNode } from "react";
import { loginRequest } from "../config/authConfig";
import UnauthenticatedView from "../pages/UnauthenticatedView/UnauthenticatedView";

async function handleLogin(instance: any) {
  await instance.loginPopup(loginRequest).catch((e: any) => {
    console.error(e);
  });
}

function ErrorComponent() {
  const { instance } = useMsal();

  return <UnauthenticatedView handleLogin={handleLogin} instance={instance} />;
}

function LoadingComponent() {
  const { instance } = useMsal();
  return <UnauthenticatedView isSigningIn instance={instance} />;
}

interface Props {
  children: ReactNode;
}

const AuthenticationService: FC<Props> = ({ children }) => {
  return (
    <>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Silent}
        authenticationRequest={loginRequest}
        errorComponent={ErrorComponent}
        loadingComponent={LoadingComponent}>
        {children}
      </MsalAuthenticationTemplate>
    </>
  );
};

export default AuthenticationService;
