import { Box, Stack, Typography } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import AdminFormInput from "../../components/AdminFormInput";
import CardLivePreview from "../../components/carddesigner/livepreview/CardLivePreview";
import CardFeatures from "../../components/cardfeatures/CardFeatures";
import Layout from "../../components/layout/Layout";
import Loading from "../../components/loading/Loading";
import { getFieldsAsync } from "../../features/fields/fieldsSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { RootState } from "../../store/store";
import EdisonButton from "../../../app-shared/edison/button/EdisonButton";
import EdisonCard from "../../../app-shared/edison/card/EdisonCard";
import EdisonFormInput from "../../../app-shared/edison/forminput/EdisonFormInput";

import "./carddesigner.scss";
import colors from "./../../theme/colors.module.scss";

const CardDesigner: FC = () => {
  const history = useHistory();
  const methods = useForm();
  const { handleSubmit } = methods;
  const { t } = useTranslation(["admin"]);
  const dispatch = useAppDispatch();
  const [name, setName] = useState("");

  const fieldsStatus = useAppSelector(
    (state: RootState) => state.fields.status
  );

  useEffect(() => {
    if (fieldsStatus === "idle") {
      dispatch(getFieldsAsync());
    }
  }, []);

  const cancelOperation = () => {
    history.push({
      pathname: "/admin",
      state: { title: t("admin") },
    });
  };

  const onSubmit = async (data: any) => {
    console.log("On Submit:", data);
  };

  return (
    <Layout>
      <DndProvider backend={HTML5Backend}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack className="card-desginer-root">
              <Stack direction="row" spacing={2}>
                <Stack>
                  <Typography variant="subtitle1" display="block">
                    <Box fontWeight="700" letterSpacing={5}>
                      {t("properties").toUpperCase()}
                    </Box>
                  </Typography>
                  <AdminFormInput
                    name="displayName"
                    label={t("label")}
                    internalName={undefined}
                    hasIDField={true}
                    requiredMessage={t("labelRequiredMessage")}
                    defaultValue={""}
                  />
                  <EdisonFormInput
                    disabled={false}
                    name="description"
                    label={t("description")}
                    requiredMessage={t("descriptionRequiredMessage")}
                    defaultValue={""}
                  />
                  {fieldsStatus !== "loading" ? <CardFeatures /> : <Loading />}
                </Stack>
                <div style={{ flexGrow: 1 }}>
                  <Typography variant="subtitle1" display="block">
                    <Box fontWeight="700" letterSpacing={5}>
                      {t("livePreview").toUpperCase()}
                    </Box>
                  </Typography>
                  <div className="card-designer-preview-container">
                    <EdisonCard backgroundColor={colors.grey50}>
                      <CardLivePreview />
                    </EdisonCard>
                  </div>
                </div>
              </Stack>
              <Stack
                direction="row"
                spacing={1}
                className="card-designer-button-container">
                <EdisonButton onClick={cancelOperation} name={t("cancel")} />
                <EdisonButton type="submit" name={t("save")} />
              </Stack>
            </Stack>
          </form>
        </FormProvider>
      </DndProvider>
    </Layout>
  );
};
export default CardDesigner;
