import EditIcon from "@mui/icons-material/Edit";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { FormHelperText, FormLabel, IconButton } from "@mui/material";
import React, { FC, MouseEvent, useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useFormContext } from "react-hook-form";
import { BaseFieldProps } from "../../models/BaseFieldProps";
import BaseUserFieldProps from "../../models/BaseUserFieldProps.model";
import "./image.scss";
import ImageCropper from "./ImageCropper";

export interface UserImageProps extends BaseUserFieldProps {
  invalidMediaTypeMessage: string;
  accept: string;
}

const UserImage: FC<UserImageProps> = ({
  label,
  disabled,
  invalidMediaTypeMessage,
  readOnly,
  accept,
  value,
  onChange,
  useInternalState,
}) => {
  const [internalState, setInternalState] = useState(value);
  const [invalidMediaType, setInvalidMediaType] = useState(false);
  const [open, setOpen] = useState(false);
  const getCurrentValue = () => {
    return useInternalState ? internalState : value;
  };

  const setFile = (image: any) => {
    if (image && image.preview) {
      URL.revokeObjectURL(image.preview);
    }
    if (image) {
      const newState = Object.assign(image, {
        preview: URL.createObjectURL(image),
      });
      useInternalState ? setInternalState(newState) : onChange(newState);
    } else {
      useInternalState ? setInternalState(null) : onChange(null);
    }
  };

  function setUploadError() {
    setInvalidMediaType(true);
    setFile(null);
  }

  const onDrop = useCallback(
    (droppedFiles: any) => {
      if (droppedFiles && droppedFiles.length > 0) {
        setInvalidMediaType(false);
        const img = droppedFiles[0];
        setFile(img);
        setOpen(true);
      }
    },
    [label]
  );

  const onSave = (file: File) => {
    setFile(file);
    setOpen(false);
  };

  const editImage = (e: MouseEvent) => {
    e.preventDefault();
    setOpen(true);
  };

  const deleteImage = (e: MouseEvent) => {
    e.preventDefault();
    setFile(undefined);
  };

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop,
    disabled: disabled || readOnly,
    accept,
    onDropRejected: () => setUploadError(),
  });

  const thumb = (imageUrl: string) => (
    <div className="thumb" key={getCurrentValue.name}>
      <div className="thumbInner">
        <img src={imageUrl} className="img" />
      </div>
      <div className="thumbAction">
        <IconButton
          className="deleteButton"
          aria-label="Edit"
          onClick={deleteImage}>
          <HighlightOffIcon fontSize="inherit" />
        </IconButton>
        <IconButton
          className="editButton"
          aria-label="Edit"
          onClick={editImage}>
          <EditIcon fontSize="inherit" />
        </IconButton>
      </div>
    </div>
  );

  return (
    <div className="imageField">
      {readOnly ? (
        <img
          src={getCurrentValue()?.preview}
          alt={label}
          className={disabled ? "imgDisabled" : ""}
        />
      ) : (
        <>
          <FormLabel component="legend">
            {label}
            {/* {!value && !!required && <span> *</span>} */}
          </FormLabel>
          <div className="root">
            {getCurrentValue() && getCurrentValue().preview && (
              <aside className="thumbContainer">
                {thumb(getCurrentValue().preview)}
              </aside>
            )}
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <div className="fileInputContainer">
                {!value && (
                  <UploadFileIcon style={{ height: "50px", width: "50px" }} />
                )}
                {!getCurrentValue() ? (
                  <div>Drag and drop or browse to choose a file</div>
                ) : (
                  <div>
                    Drag and drop or browse to choose an alternative file
                  </div>
                )}
              </div>
            </div>
            <div className="form-helper-styling" hidden={!invalidMediaType}>
              <FormHelperText error={invalidMediaType}>
                {invalidMediaType && invalidMediaTypeMessage}
              </FormHelperText>
            </div>
          </div>
          {!readOnly &&
            !disabled &&
            getCurrentValue() &&
            getCurrentValue().preview && (
              <ImageCropper
                image={getCurrentValue()}
                isOpen={open}
                title="Edit image"
                onClose={() => setOpen(false)}
                onSave={onSave}
              />
            )}
        </>
      )}
    </div>
  );
};

export default UserImage;
