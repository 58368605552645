import { Grid } from "@mui/material";
import React, { FC, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import AdminFormInput from "../../components/AdminFormInput";
import Layout from "../../components/layout/Layout";
import Loading from "../../components/loading/Loading";
import {
  createFieldAsync,
  updateFieldAsync,
} from "../../features/fields/fieldsSlice";
import FieldButton from "../../../models/FieldTypes/FieldButton.model";
import ILocation from "../../../models/ILocation.model";
import { useAppDispatch } from "../../store/hooks";
import EdisonButton from "../../../app-shared/edison/button/EdisonButton";
import EdisonFormInput from "../../../app-shared/edison/forminput/EdisonFormInput";
import defaultItem from "../../utils/defaultItem";
import removeWhitespaces from "../../utils/removeWhitespaces";
import "./newbutton.scss";

const defaultButton: FieldButton = defaultItem("button", { linkUrl: "" });

const NewButton: FC = () => {
  const history = useHistory();
  const location: ILocation = useLocation();
  const dispatch = useAppDispatch();
  const methods = useForm();
  const { handleSubmit } = methods;
  const { t } = useTranslation(["admin"]);
  const [isLoading, setLoading] = useState(false);

  const isEdit = !!location.state && !!location.state.item;

  const button: FieldButton = !!isEdit
    ? (location.state.item as FieldButton)
    : defaultButton;

  const cancelOperation = () => {
    history.push({
      pathname: "/admin/buttons",
      state: { title: t("buttons") },
    });
  };

  //fallback in case someone tries to come directly here
  if (!isEdit && location.pathname === "/admin/buttons/editbutton")
    cancelOperation();

  const onSubmit = async (data: any) => {
    setLoading(true);
    const tempRequest = {
      type: "button",
      dataType: "Button",
      isDeleted: false,
      name: removeWhitespaces(data.displayName),
      configuration: {
        linkUrl: data.linkUrl,
      },
    };
    const result = { ...data, ...tempRequest };
    !!isEdit
      ? dispatch(updateFieldAsync({ ...result, id: button.id }))
      : dispatch(createFieldAsync(result));
    setLoading(false);
    history.push({
      pathname: "/admin/buttons",
      state: { title: t("buttons") },
    });
  };

  return (
    <Layout>
      <Grid className="new-button-page-root" container spacing={3}>
        <Grid item xs={12}>
          {!isLoading ? (
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <AdminFormInput
                  name="displayName"
                  label={t("label")}
                  internalName={isEdit ? button.name : undefined}
                  hasIDField={true}
                  defaultValue={button.displayName}
                  requiredMessage={t("labelRequiredMessage")}
                />
                <EdisonFormInput
                  disabled={false}
                  name="description"
                  label={t("description")}
                  defaultValue={button.description}
                  requiredMessage={t("descriptionRequiredMessage")}
                />
                <EdisonFormInput
                  disabled={false}
                  name="linkUrl"
                  label={t("linkUrl")}
                  defaultValue={button.configuration?.linkUrl || ""}
                  requiredMessage={t("linkUrlRequiredMessage")}
                />
                <div className="button-container">
                  <EdisonButton onClick={cancelOperation} name={t("cancel")} />
                  <EdisonButton type="submit" name={t("save")} />
                </div>
              </form>
            </FormProvider>
          ) : (
            <Loading />
          )}
        </Grid>
      </Grid>
    </Layout>
  );
};
export default NewButton;
