import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { CardContent, Grid, Typography } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import { AdminButton } from "../../components/adminbutton/AdminButton";
import AdminTable from "../../components/admintable/AdminTable";
import Layout from "../../components/layout/Layout";
import Loading from "../../components/loading/Loading";
import { routes } from "../../config/routes";
import {
  getFieldsAsync,
  recycleFieldAsync,
  selectAllButtons,
} from "../../features/fields/fieldsSlice";
import ColumnModel from "../../../models/Column.model";
import Item from "../../../models/Item.model";
import RouteItem from "../../../models/RouteItem.model";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { RootState } from "../../store/store";
import EdisonCard from "../../../app-shared/edison/card/EdisonCard";
import "./buttons.scss";

const mockedTokens = [
  "ProjectOnlineProjectId",
  "ProjectOnlineProjectSiteUrl",
  "ProjectOnlineProjectSiteUrl",
];

const Buttons: FC = () => {
  const { t } = useTranslation(["admin"]);

  const columns: ColumnModel[] = [
    {
      id: 1,
      name: t("label"),
      reference: "displayName",
    },
    {
      id: 2,
      name: t("internalName"),
      reference: "name",
    },
    {
      id: 3,
      name: t("linkUrl"),
      reference: "linkUrl" as keyof Item,
    },
    {
      id: 4,
      name: t("icon"),
      reference: "icon " as keyof Item,
    },
  ];

  const { url } = useRouteMatch();
  const [tokens] = useState(mockedTokens);
  const history = useHistory();

  const dispatch = useAppDispatch();

  const buttons = useAppSelector(selectAllButtons);
  const fieldsStatus = useAppSelector(
    (state: RootState) => state.fields.status
  );

  useEffect(() => {
    if (fieldsStatus === "idle") {
      dispatch(getFieldsAsync());
    }
  }, []);

  const deleteRow = async (ids: number[]) => {
    ids.forEach((id: number) => dispatch(recycleFieldAsync(id)));
  };

  const editRow = async (ids: number[]) => {
    history.push({
      pathname: `/admin/buttons/editbutton`,
      state: {
        title: t("editButton"),
        item: buttons.find((item: Item) => item.id === ids[0]),
      },
    });
  };

  const route = routes.find(
    (routeToFind: RouteItem) => routeToFind.key == "newbutton"
  );
  return (
    <Layout>
      <div className="buttons-page-container">
        <EdisonCard>
          <Grid container spacing={8} className="meta-data-card">
            <Grid item className="description-container">
              <Grid item className="description-card-text">
                <Typography variant="body1">
                  {t("buttonsPageDescriptionP1")}
                </Typography>
                <Typography variant="body1">
                  {t("buttonsPageDescriptionP2")}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle2">{t("Tokens")}</Typography>
                <ul className="tokens-list">
                  {tokens.map((token: string, index: number) => (
                    <li key={index}>
                      <Typography variant="body1">{token}</Typography>
                    </li>
                  ))}
                </ul>
              </Grid>
            </Grid>
            <Grid item className="create-button">
              <AdminButton
                pathName={!!route ? route.path : `${url}/newbutton`}
                title={t("newButton")}
                buttonText={t("createButton")}
              />
            </Grid>
          </Grid>
        </EdisonCard>
        {fieldsStatus !== "loading" ? (
          <Grid item xs={12}>
            <EdisonCard>
              <CardContent>
                {buttons.length > 0 ? (
                  <AdminTable
                    data={buttons}
                    columns={columns}
                    editAction={editRow}
                    deleteAction={deleteRow}
                  />
                ) : (
                  <Typography variant="body1">
                    {t("loadingErrorMessage")}
                  </Typography>
                )}
              </CardContent>
            </EdisonCard>
          </Grid>
        ) : (
          <Loading />
        )}
      </div>
    </Layout>
  );
};
export default Buttons;
