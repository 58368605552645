import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteFields,
  getIndividualTable,
  getTables,
  postTables,
  putTables,
} from "../../../services/APIService";
import Item from "../../../models/Item.model";
import reducerStatus from "../../../models/reducerStatus";
import TableField from "../../../models/TableTypes/TableField.model";
import TableItem from "../../../models/TableTypes/TableItem.model";
import { RootState } from "../../store/store";
import formatModifiedDate from "../../utils/formatModifiedDate";

export interface tablesState {
  value: number;
  individualTable: TableItem | null;
  tables: Array<Item>;
  status: reducerStatus;
}

const initialState: tablesState = {
  value: 0,
  individualTable: null,
  tables: [],
  status: "idle",
};
export const getTablesAsync = createAsyncThunk(
  "tables/getTables",
  async (_, { rejectWithValue }) => {
    const response: any = await getTables();
    if (!(response.status as number).toString().startsWith("2"))
      return rejectWithValue(response.data);
    return response.data;
  }
);

export const getIndividualTableAsync = createAsyncThunk(
  "tables/getIndividualTable",
  async (id: number, { rejectWithValue }) => {
    const response: any = await getIndividualTable(id);
    if (!(response.status as number).toString().startsWith("2"))
      return rejectWithValue(response.data);
    return response.data;
  }
);
export const recycleTableAsync = createAsyncThunk(
  "tables/recycleTable",
  async (id: number, { rejectWithValue }) => {
    const response: any = await deleteFields(id);
    if (!(response.status as number).toString().startsWith("2"))
      return rejectWithValue(response.data);
    return id;
  }
);

export const createTableAsync = createAsyncThunk(
  "tables/createTable",
  async (data: any, { rejectWithValue }) => {
    const response: any = await postTables(data);
    if (!(response.status as number).toString().startsWith("2"))
      return rejectWithValue(response.data);
    return response.data;
  }
);

export const updateTableAsync = createAsyncThunk(
  "tables/updateTable",
  async (data: any, { rejectWithValue }) => {
    const response: any = await putTables(data);
    if (!(response.status as number).toString().startsWith("2"))
      return rejectWithValue(response.data);
    return response.data;
  }
);

export const tablesSlice = createSlice({
  name: "tables",
  initialState,
  reducers: {
    clearIndividualTable: (state) => {
      state.individualTable = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTablesAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getTablesAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTablesAsync.fulfilled, (state, action) => {
        state.status = "idle";
        if (!action.payload) return;
        state.tables = action.payload;
      })
      .addCase(recycleTableAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(recycleTableAsync.fulfilled, (state, action) => {
        state.status = "idle";
        if (!action.payload) return;
        state.tables = state.tables.filter(
          (table: Item) => table.id !== action.payload
        );
      })
      .addCase(getIndividualTableAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getIndividualTableAsync.fulfilled, (state, action) => {
        state.status = "idle";
        if (!action.payload) return;
        state.individualTable = action.payload;
      })
      .addCase(createTableAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createTableAsync.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(updateTableAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateTableAsync.fulfilled, (state) => {
        state.status = "idle";
      });
  },
});

export const selectAllTables = (state: RootState): Array<Item> =>
  state.tables.tables
    .map((dataToMap: Item) => {
      return {
        ...dataToMap,
        modified: formatModifiedDate(dataToMap.modified) as unknown as Date,
      };
    })
    .filter((res: Item) => !res.isDeleted);

export const selectIndividualTable = (state: RootState): TableItem | null =>
  state.tables.individualTable;

export const selectTableStatus = (state: RootState): reducerStatus =>
  state.tables.status;
export const { clearIndividualTable } = tablesSlice.actions;
export default tablesSlice.reducer;
