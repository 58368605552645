import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Form from "../../models/backend/form/Form.model";
import { RootState } from "../store/store";

export interface formsState {
  currentForm: Form | null;
  readOnly: boolean;
}
const mockedForm: Form = {
  modified: new Date().toDateString(),
  modifiedBy: "Test User",
  id: 1,
  name: "MockedForm",
  displayName: "Mocked Form",
  dataType: 1,
  containers: [
    {
      id: 2,
      formId: 1,
      displayName: "First Tab",
      type: "tab",
      zones: [
        {
          id: 3,
          columns: [
            {
              id: 4,
              zoneId: 3,
              fields: [
                {
                  id: 5,
                  columnId: 4,
                  fieldId: 6,
                  field: {
                    dataType: "Number",
                    name: "Number",
                    displayName: "Number Field",
                    configuration: {
                      format: "currency",
                      currency: 80,
                    },
                  },
                },
                {
                  id: 7,
                  columnId: 4,
                  fieldId: 8,
                  field: {
                    dataType: "Date",
                    name: "Date",
                    displayName: "Date Field",
                  },
                },
              ],
              tables: [],
            },

            {
              id: 9,
              zoneId: 3,
              fields: [
                {
                  id: 10,
                  columnId: 9,
                  fieldId: 11,
                  field: {
                    dataType: "Number",
                    name: "Number2",
                    displayName: "Number Field 2",
                    configuration: {
                      format: "percentage",
                    },
                  },
                },
                {
                  id: 12,
                  columnId: 9,
                  fieldId: 13,
                  field: {
                    dataType: "Date",
                    name: "Date2",
                    displayName: "Date Field 2",
                  },
                },
              ],
              tables: [],
            },
          ],
        },
        {
          id: 14,
          columns: [
            {
              id: 15,
              zoneId: 14,
              fields: [
                {
                  id: 16,
                  columnId: 15,
                  fieldId: 17,
                  field: {
                    dataType: "People",
                    displayName: "People Picker",
                    name: "People Picker",
                  },
                },
                {
                  id: 16,
                  columnId: 15,
                  fieldId: 17,
                  field: {
                    dataType: "Switch",
                    displayName: "Switch Field",
                    name: "SwitchField",
                  },
                },
              ],
              tables: [],
            },
            {
              id: 1,
              zoneId: 14,
              fields: [
                {
                  field: {
                    name: "UrlField",
                    displayName: "Url Field",
                    dataType: "Url",
                  },
                },
              ],
              tables: [],
            },
          ],
        },
        {
          id: 14,
          columns: [
            {
              id: 15,
              zoneId: 14,
              fields: [
                {
                  id: 16,
                  columnId: 15,
                  fieldId: 17,
                  field: {
                    dataType: "RichText",
                    displayName: "Rich Text Field",
                    name: "Rich Text Field",
                  },
                },
              ],
              tables: [],
            },
          ],
        },
      ],
    },
    {
      id: 90,
      displayName: "Table tab",
      type: "tab",
      zones: [
        {
          id: 56,
          columns: [
            {
              id: 18,
              zoneId: 14,
              fields: [],
              tables: [
                {
                  id: 19,
                  table: {
                    dataType: "Task",
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

const initialState: formsState = {
  currentForm: mockedForm,
  readOnly: false,
};

export const formsSlice = createSlice({
  name: "forms",
  initialState,
  reducers: {
    setReadOnly: (state, action: PayloadAction<boolean>) => {
      state.readOnly = action.payload;
    },
  },
});
export const { setReadOnly } = formsSlice.actions;

export const selectCurrentForm = (state: RootState): Form | null =>
  (state.projectForms as formsState).currentForm;

export const selectFormReadOnly = (state: RootState): boolean =>
  (state.projectForms as formsState).readOnly;

export default formsSlice.reducer;
