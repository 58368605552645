import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UpdateUserOperandPayload } from "../../components/calculatedfield/units/UserOperand";
import getDependenciesFromList from "../../components/calculatedfield/utils/getDependenciesFromList";
import {
  ConditionBlock,
  ConditionOperator,
  CurrentFocusedRow,
  DependencyId,
  ExpressionUnit,
  MoveExpressionAction,
} from "../../../models/calculatedfield/calculatedfield.model";
import FieldItem from "../../../models/FieldTypes/FieldItem.model";
import { RootState } from "../../store/store";
import { moveDNDObjectInArray } from "../../utils/dndHelpers";
import { getIndividualFieldAsync } from "./fieldsSlice";

export interface calculatedFieldState {
  conditionList: ConditionBlock[];
  currentFocusedRow: CurrentFocusedRow;
  expressionList: ExpressionUnit[];
  isCondition: boolean;
  fieldToEdit: FieldItem | null;
  isAwaitingFieldToEdit: boolean;
  editExpressionSet: boolean;
  calculatedFields: FieldItem[];
  isRetrieving: boolean;
  isEdit: boolean;
  expressionBoxClicked: boolean;
  validationMessages: string[];
}

const initialState: calculatedFieldState = {
  conditionList: [
    {
      conditionType: "if",
      conditionBlock: [],
      resultBlock: [],
    },
    {
      conditionType: "else",
      conditionBlock: [],
      resultBlock: [],
    },
  ],
  currentFocusedRow: { rowIndex: 0, rowType: "condition" },
  expressionList: [],
  isCondition: false,
  fieldToEdit: null,
  isAwaitingFieldToEdit: true,
  calculatedFields: [],
  isRetrieving: false,
  editExpressionSet: false,
  isEdit: false,
  expressionBoxClicked: false,
  validationMessages: [],
};

const DefaultConditionRow: ConditionBlock = {
  conditionType: "else",
  conditionBlock: [],
  resultBlock: [],
};

export const calculatedFieldSlice = createSlice({
  name: "calculatedField",
  initialState,
  reducers: {
    /// Condition Reducers
    addBlockToConditionList: (state) => {
      state.conditionList.push(DefaultConditionRow);
    },
    addExpressionUnitToConditionBlock: (
      state,
      action: PayloadAction<ExpressionUnit>
    ) => {
      state.currentFocusedRow.rowType === "condition"
        ? state.conditionList[
            state.currentFocusedRow.rowIndex
          ].conditionBlock.push(action.payload)
        : state.conditionList[
            state.currentFocusedRow.rowIndex
          ].resultBlock.push(action.payload);
    },
    removeExpressionUnitFromBlock: (state, action: PayloadAction<number>) => {
      const focused = state.currentFocusedRow;
      focused.rowType === "condition"
        ? (state.conditionList[focused.rowIndex].conditionBlock =
            state.conditionList[focused.rowIndex].conditionBlock.filter(
              (_, index: number) => index !== action.payload
            ))
        : (state.conditionList[focused.rowIndex].resultBlock =
            state.conditionList[focused.rowIndex].resultBlock.filter(
              (_, index: number) => index !== action.payload
            ));
    },
    updateBlockConditionType: (
      state,
      action: PayloadAction<ConditionOperator>
    ) => {
      const focused = state.currentFocusedRow;
      state.conditionList[focused.rowIndex].conditionType = action.payload;
      if (action.payload === "else") {
        state.conditionList = state.conditionList.slice(
          0,
          focused.rowIndex + 1
        );
      }
      if (action.payload === "elseIf") {
        state.conditionList.push(DefaultConditionRow);
      }
    },
    updateFocusedRow: (state, action: PayloadAction<CurrentFocusedRow>) => {
      state.currentFocusedRow = action.payload;
    },

    moveExpressionUnitInBlock: (
      state,
      action: PayloadAction<MoveExpressionAction>
    ) => {
      const focused = state.currentFocusedRow;
      focused.rowType === "condition"
        ? (state.conditionList[focused.rowIndex].conditionBlock =
            moveDNDObjectInArray(
              state.conditionList[focused.rowIndex].conditionBlock,
              action.payload.dragIndex,
              action.payload.hoverIndex
            ))
        : (state.conditionList[focused.rowIndex].resultBlock =
            moveDNDObjectInArray(
              state.conditionList[focused.rowIndex].resultBlock,
              action.payload.dragIndex,
              action.payload.hoverIndex
            ));
    },

    removeConditionBlock: (state, action: PayloadAction<number>) => {
      state.conditionList = state.conditionList.filter(
        (_, index: number) => index !== action.payload
      );
    },
    updateUserOperandInList: (
      state,
      action: PayloadAction<UpdateUserOperandPayload>
    ) => {
      const focused = state.currentFocusedRow;
      state.isCondition
        ? focused.rowType === "condition"
          ? (state.conditionList[focused.rowIndex].conditionBlock[
              action.payload.index
            ].value = action.payload.newValue)
          : (state.conditionList[focused.rowIndex].resultBlock[
              action.payload.index
            ].value = action.payload.newValue)
        : (state.expressionList[action.payload.index].value =
            action.payload.newValue);
    },

    setConditionList: (state, action: PayloadAction<ConditionBlock[]>) => {
      state.conditionList = action.payload;
    },
    setIsCondition: (state, action: PayloadAction<boolean>) => {
      state.conditionList = initialState.conditionList;
      state.expressionList = [];
      state.currentFocusedRow = initialState.currentFocusedRow;
      state.isCondition = action.payload;
    },

    /// ExpressionList Reducers
    addUnitToExpressionList: (state, action: PayloadAction<ExpressionUnit>) => {
      state.expressionList.push(action.payload);
    },
    removeUnitFromExpressionList: (state, action: PayloadAction<number>) => {
      state.expressionList = state.expressionList.filter(
        (_, index: number) => index !== action.payload
      );
    },
    moveUnitInExpressionList: (
      state,
      action: PayloadAction<MoveExpressionAction>
    ) => {
      state.expressionList = moveDNDObjectInArray(
        state.expressionList,
        action.payload.dragIndex,
        action.payload.hoverIndex
      );
    },
    setExpressionList: (state, action: PayloadAction<ExpressionUnit[]>) => {
      state.expressionList = action.payload;
    },
    resetSlice: (state) => initialState,

    setFieldToEdit: (state, action: PayloadAction<FieldItem>) => {
      state.fieldToEdit = action.payload;
    },
    setIsAwaitingFieldToEdit: (state, action: PayloadAction<boolean>) => {
      state.isAwaitingFieldToEdit = action.payload;
    },
    setIsRetrieving: (state, action: PayloadAction<boolean>) => {
      state.isRetrieving = action.payload;
    },
    setEditExpressionSet: (state, action: PayloadAction<boolean>) => {
      state.editExpressionSet = action.payload;
    },
    setIsEdit: (state, action: PayloadAction<boolean>) => {
      state.isEdit = action.payload;
    },
    setExpressionBoxClicked: (state, action: PayloadAction<boolean>) => {
      state.expressionBoxClicked = action.payload;
    },
    setValidationMessages: (state, action: PayloadAction<string[]>) => {
      state.validationMessages = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getIndividualFieldAsync.fulfilled, (state, action) => {
      if (
        state.isAwaitingFieldToEdit &&
        state.isEdit &&
        action.payload.type === "calculated"
      ) {
        if (
          action.payload.configuration.calculatedExpression[0]
            .expressionType === "calc"
        ) {
          state.isCondition = false;
          state.fieldToEdit = action.payload;
        } else {
          state.isCondition = true;
          state.fieldToEdit = action.payload;
        }

        state.isAwaitingFieldToEdit = false;
      } else {
        const found = state.calculatedFields.find(
          (field) => field.id === action.payload.id
        );
        if (!found) {
          state.calculatedFields.push(action.payload);
        }
      }
    });
  },
});

const moveUnitInExpressionArray = (
  array: any[],
  dragIndex: number,
  hoverIndex: number
): any[] => {
  const dragExpression = array[dragIndex];
  const expressionCopy = array.slice();
  expressionCopy.splice(dragIndex, 1);
  expressionCopy.splice(hoverIndex, 0, dragExpression);
  return expressionCopy;
};

export const {
  addBlockToConditionList,
  addExpressionUnitToConditionBlock,
  removeExpressionUnitFromBlock,
  updateBlockConditionType,
  updateFocusedRow,
  moveExpressionUnitInBlock,
  addUnitToExpressionList,
  removeUnitFromExpressionList,
  moveUnitInExpressionList,
  removeConditionBlock,
  setIsCondition,
  setExpressionList,
  setConditionList,
  resetSlice,
  setFieldToEdit,
  setIsAwaitingFieldToEdit,
  setIsRetrieving,
  setEditExpressionSet,
  setIsEdit,
  updateUserOperandInList,
  setExpressionBoxClicked,
  setValidationMessages,
} = calculatedFieldSlice.actions;

export const selectConditionList = (state: RootState): ConditionBlock[] =>
  state.calculatedField.conditionList;

export const selectExpressionList = (state: RootState): ExpressionUnit[] =>
  state.calculatedField.expressionList;

export const selectCurrentFocusedRow = (state: RootState): CurrentFocusedRow =>
  state.calculatedField.currentFocusedRow;

export const selectIsCondition = (state: RootState): boolean =>
  state.calculatedField.isCondition;

export const selectFieldToEdit = (state: RootState): any =>
  state.calculatedField.fieldToEdit;

export const selectIsRetrieving = (state: RootState): any =>
  state.calculatedField.isRetrieving;

export const selectEditExpressionSet = (state: RootState): any =>
  state.calculatedField.editExpressionSet;

export const selectExpressionBoxClicked = (state: RootState): any =>
  state.calculatedField.expressionBoxClicked;

export const selectDependencyIds = (state: RootState): DependencyId[] => {
  const typedState = state.calculatedField as calculatedFieldState;
  const calculatedFieldIds = typedState.calculatedFields.map(
    (field) => field.id
  );
  if (!typedState.isCondition) {
    return getDependenciesFromList(
      typedState.expressionList,
      typedState.fieldToEdit,
      calculatedFieldIds
    );
  } else {
    let flattenedConditionList: ExpressionUnit[] = [];
    typedState.conditionList.forEach((block) => {
      flattenedConditionList = flattenedConditionList
        .concat(block.conditionBlock)
        .concat(block.resultBlock);
    });
    return getDependenciesFromList(
      flattenedConditionList,
      typedState.fieldToEdit,
      calculatedFieldIds
    );
  }
};
export const selectCalculatedFieldsList = (state: RootState): FieldItem[] =>
  state.calculatedField.calculatedFields;

export const selectValidationMessages = (state: RootState): string[] =>
  state.calculatedField.validationMessages;

export default calculatedFieldSlice.reducer;
