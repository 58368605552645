import { Avatar, Badge, Stack, Typography } from "@mui/material";
import React, { FC } from "react";
import {
  User,
  UserStatus,
} from "../../../models/peoplepicker/peoplePicker.model";
import { getInitials } from "../UserPeoplePicker";
import "./peoplepickerprofile.scss";

export interface PeoplePickerProfileProps {
  user: User;
}
const UserPeoplePickerProfile: FC<PeoplePickerProfileProps> = ({ user }) => {
  return (
    <Stack direction="row" spacing={2}>
      <Badge
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        variant="dot"
        color={mapStatusToColor(user.status)}
        overlap="circular">
        <Avatar alt={user.userName} src={user.imageUrl}>
          {getInitials(user.userName)}
        </Avatar>
      </Badge>
      <div className="profile-name">
        <Typography>{user.userName}</Typography>
      </div>
    </Stack>
  );
};

const mapStatusToColor = (status: UserStatus) => {
  switch (status) {
    case "online":
      return "success";
    case "away":
      return "warning";
    case "busy":
      return "error";
    case "offline":
      return "secondary";
    case "presenting":
      return "error";
  }
};
export default UserPeoplePickerProfile;
