import React, { FC } from "react";
import EdisonTypography from "../typography/EdisonTypography";
import "./edisonparenttabs.scss";

export interface EdisonParentTabProps {
  data: EdisonParentTab[];
}

interface EdisonParentTab {
  id: number;
  tabTitle: string;
  tabContent: React.ReactNode;
}

const EdisonParentTabs: FC<EdisonParentTabProps> = ({ data }) => {
  const [visibleTab, setVisibleTab] = React.useState(data[0].id);

  const listTitles = data.map((item) => (
    <li
      key={item.id}
      onClick={() => setVisibleTab(item.id)}
      className={visibleTab === item.id ? "tab-title active" : "tab-title"}>
      <EdisonTypography variant="t4a" title={item.tabTitle} />
    </li>
  ));

  const listContent = data.map((item) => (
    <p key={item.id} style={visibleTab === item.id ? {} : { display: "none" }}>
      {item.tabContent}
    </p>
  ));

  return (
    <div className="tabs">
      <ul className="tabs-titles">{listTitles}</ul>
      <div className="tab-content">{listContent}</div>
    </div>
  );
};

export default EdisonParentTabs;
