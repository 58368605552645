import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getRequestMsGraph } from "../../services/APIService";
import { RootState } from "../../store/store";

export interface userState {
  tenant: string;
  instance: string;
  status: "idle" | "loading" | "failed";
  user: any;
  photo: any;
}

const initialState: userState = {
  tenant: "development",
  instance: "frontend",
  status: "idle",
  user: {},
  photo: "",
};

export const getMicrosoftGraphUserAsync = createAsyncThunk(
  "user/getUser",
  async () => {
    const response = await getRequestMsGraph("me");
    return response.json();
  }
);

export const getUserPhotoAsync = createAsyncThunk(
  "user/getUserPhoto",
  async (id?: string) => {
    const idDefined =
      id === null || id === undefined || id === "me" ? false : true;
    const query = idDefined ? `users/${id}/photo/$value` : `me/photo/$value`;
    const response = await getRequestMsGraph(query);
    const win = window.URL || window.webkitURL;
    return win.createObjectURL(await response.blob());
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setCurrentInstance: (state, action: PayloadAction<string>) => {
      state.instance = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(getMicrosoftGraphUserAsync.pending, (state: any) => {
        console.log("get user pending");
        state.status = "loading";
      })
      .addCase(getUserPhotoAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        getMicrosoftGraphUserAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.status = "idle";
          state.user = action.payload;
        }
      )
      .addCase(
        getUserPhotoAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.photo = action.payload;
          state.status = "idle";
        }
      )
      .addCase(
        getMicrosoftGraphUserAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          console.log("get user failed", action.payload);
          state.status = "failed";
        }
      )
      .addCase(
        getUserPhotoAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          console.log("get user photo failed", action.payload);
          state.status = "failed";
        }
      );
  },
});

export const { setCurrentInstance } = userSlice.actions;

export const getUserSettings = (state: RootState): string =>
  `/${state.user.tenant}/${state.user.instance}`;

export const selectUser = (state: RootState): any => {
  return (state.user as userState).user;
};

export const selectUserPhoto = (state: RootState): any => {
  return (state.user as userState).photo;
};

export default userSlice.reducer;
