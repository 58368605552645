
import { History } from 'history';

export default function navigate(pathName: string, history:History,  state?: unknown,) {

  history.push({
    pathname: pathName,
    state: state,
  });
}
