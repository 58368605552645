import { FormLabel, Stack } from "@mui/material";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  ExpressionUnit,
  Operand,
} from "../../../../../models/calculatedfield/calculatedfield.model";
import FieldItem from "../../../../../models/FieldTypes/FieldItem.model";
import FieldsTabRow from "../../../calculatedfield/tabs/FieldsTabRow";

export interface ConditionListFieldsTabProps {
  addExpressionUnit: (unitToAdd: ExpressionUnit) => void;
  data: FieldItem[];
}
const ConditionListFieldsTab: FC<ConditionListFieldsTabProps> = ({
  data,
  addExpressionUnit,
}) => {
  const { t } = useTranslation(["admin"]);
  return (
    <Stack>
      <FormLabel component="legend">{t("userValue")}</FormLabel>
      <FieldsTabRow
        isDisabled={false}
        fieldName={t("addCustomNumber")}
        fieldType={t("number")}
        onClick={() => addExpressionUnit({ type: "number", value: 0 })}
      />
      <FieldsTabRow
        isDisabled={false}
        fieldName={t("addCustomText")}
        fieldType={t("text")}
        onClick={() =>
          addExpressionUnit({
            type: "text",
            value: t("enterText") as string,
          })
        }
      />
      <FormLabel component="legend">{t("fieldsLower")}</FormLabel>
      {data.map((field, index) => (
        <FieldsTabRow
          key={index}
          fieldName={field.name}
          fieldType={field.dataType}
          isDisabled={false}
          onClick={() =>
            addExpressionUnit({
              type: "operand",
              value: field as unknown as Operand,
            })
          }
        />
      ))}
    </Stack>
  );
};

export default ConditionListFieldsTab;
