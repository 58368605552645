import { nanoid } from "nanoid";
import {
  BackendCalculatedFieldModel,
  ComparatorList,
  ConditionBlock,
  ExpressionUnit,
  Operand,
  Operator,
  OperatorList,
} from "../../../../models/calculatedfield/calculatedfield.model";
import Item from "../../../../models/Item.model";

export const transformExpressionToBackend = (
  expressionList: ExpressionUnit[]
): BackendCalculatedFieldModel[] => {
  return [
    {
      expressionName: "hello",
      expressionType: "calc",
      expression: parseExpressionUnitList(expressionList),
    },
  ];
};

export const transformConditionToBackend = (
  conditionList: ConditionBlock[]
): BackendCalculatedFieldModel[] => {
  let newExpressionName = nanoid();
  return conditionList.map((conditionBlock: ConditionBlock, index: number) => {
    const expression: BackendCalculatedFieldModel =
      conditionBlock.conditionType === "else"
        ? {
            expressionName: newExpressionName,
            expressionType: "calc",
            expression: parseExpressionUnitList(conditionBlock.resultBlock),
          }
        : {
            expressionName: newExpressionName,
            expressionType: "if",
            expression: parseExpressionUnitList(conditionBlock.conditionBlock),
            expressionSuccess: parseExpressionUnitList(
              conditionBlock.resultBlock
            ),
          };
    newExpressionName = nanoid();
    if (index !== conditionList.length - 1) {
      expression.expressionFail = "_Expression:[" + newExpressionName + "]_";
    }

    return expression;
  });
};

export const transformExpressionToFrontend = (
  rawExpression: BackendCalculatedFieldModel,
  fields: Item[]
): ExpressionUnit[] => {
  return parseExpressionUnitListString(rawExpression.expression, fields);
};
export const transformConditionToFrontend = (
  rawExpression: BackendCalculatedFieldModel[],
  fields: Item[]
): ConditionBlock[] => {
  const conditionList: ConditionBlock[] = rawExpression.map(
    (block: BackendCalculatedFieldModel, index: number) => {
      if (index === 0) {
        return {
          conditionType: "if",
          conditionBlock: parseExpressionUnitListString(
            block.expression,
            fields
          ),
          resultBlock: parseExpressionUnitListString(
            block.expressionSuccess as string,
            fields
          ),
        };
      }

      return block.expressionType === "if"
        ? {
            conditionType: "elseIf",
            conditionBlock: parseExpressionUnitListString(
              block.expression,
              fields
            ),
            resultBlock: parseExpressionUnitListString(
              block.expressionSuccess as string,
              fields
            ),
          }
        : {
            conditionType: "else",
            conditionBlock: [],
            resultBlock: parseExpressionUnitListString(
              block.expression,
              fields
            ),
          };
    }
  );

  return conditionList;
};

export const parseExpressionUnitListString = (
  expressionString: string,
  fields: Item[]
): ExpressionUnit[] => {
  if (expressionString.length === 0) {
    return [];
  }
  const split = expressionString.split(" ");
  return split.map((unitString) => {
    if (unitString.startsWith("_Field:")) {
      const id = unitString.substring(7, unitString.length - 1);
      const field = fields.find((field) => field.id.toString() === id);
      if (field) {
        return {
          type: "operand",
          value: {
            id: field.id,
            name: field.name,
            displayName: field.displayName,
          },
        };
      }
    }
    const parsed = parseInt(unitString);

    const operatorComparatorList: string[] = ComparatorList.map(
      (comparator) => comparator.value as string
    ).concat(OperatorList.map((operator) => operator.value as string));

    return isNaN(parsed)
      ? operatorComparatorList.includes(unitString)
        ? { type: "operator", value: unitString as Operator }
        : { type: "text", value: getDecodedSubstring(unitString) }
      : { type: "number", value: parsed };
  });
};
const getDecodedSubstring = (text: string) => {
  const result = text.substring(1, text.length - 1);
  return decodeURIComponent(result);
};

export const parseExpressionUnitList = (
  expressionList: ExpressionUnit[]
): string => {
  return expressionList
    .map((unit) => {
      if (["operator", "comparator", "number"].includes(unit.type)) {
        return unit.value as string;
      }
      if (unit.type === "text") {
        return `\"${encodeURIComponent(unit.value as string)}\"`;
      }
      return "_Field:" + (unit.value as Operand).id.toString() + "_";
    })
    .join(" ");
};
