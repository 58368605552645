import { Grid } from '@mui/material';
import classNames from 'classnames';
import React, { FC, useState } from 'react'
import "./expandablemenu.scss"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import EdisonTypography from '../typography/EdisonTypography';
export interface EdisonExpandableMenuProps{
    title: string;
    children: any;
    sticky?: boolean;
    variant?:string;
}
const EdisonExpandableMenu: FC<EdisonExpandableMenuProps> = ({title, children, sticky, variant}) => {
    const [isExpanded, setIsExpanded] = useState(true);
    return (
      <Grid
        container
        direction="column"
        className={classNames("expandable-menu", { sticky: sticky })}>
        <div className="expandable-menu_header">
          <EdisonTypography title={title} variant={variant? variant: "t2"} />
          {isExpanded ? (
            <ArrowDropUpIcon onClick={() => setIsExpanded(false)} />
          ) : (
            <ArrowDropDownIcon onClick={() => setIsExpanded(true)} />
          )}
        </div>
        {isExpanded && children}
      </Grid>
    )
}

export default EdisonExpandableMenu