import {
  Drawer,
  IconButton,
  ListItem,
  ListItemIcon,
  Stack,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import clsx from "clsx";
import React, { FC, ReactElement } from "react";
import NavigationMenu from "./NavigationMenu";
import "./navigation.scss";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import EdisonTypography from "../../app-shared/edison/typography/EdisonTypography";
import edison365Logo from "../../assets/edison365_logo.png";

// define interface to represent component props
interface Props {
  open: boolean;
  handleMenuClose?: () => void;
  toggleRightPanel: any;
}

const Navigation: FC<Props> = ({
  open,
  handleMenuClose,
  toggleRightPanel,
}): ReactElement => {
  return (
    <>
      <Drawer
        variant="permanent"
        className={clsx("navigation-drawer", {
          ["drawer-open"]: open,
          ["drawer-close"]: !open,
        })}
        classes={{
          paper: clsx({
            ["drawer-open"]: open,
            ["drawer-close"]: !open,
          }),
        }}>
        <Stack spacing={15}>
          <Stack>
            <ListItem className="create-logo">
              <ListItemIcon>
                <IconButton
                  onClick={() => toggleRightPanel(true)}
                  style={{ width: "fit-content" }}>
                  <AddCircleOutlineOutlinedIcon />
                </IconButton>
              </ListItemIcon>
              <EdisonTypography
                title="Create"
                disableUppercase={true}
                variant="t9"
              />
            </ListItem>
            <NavigationMenu open={open} />
          </Stack>
          <Stack spacing={1} className="logo-container">
            <IconButton onClick={handleMenuClose}>
              {!!open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
            {/* <img src={CDNValues.edisonLogo} className={"logo"} /> */}
            <img src={edison365Logo} className={"logo"} />
          </Stack>
        </Stack>
      </Drawer>
    </>
  );
};

export default Navigation;
