import React, { FC } from "react";

export interface ContainerIconProps {
  imgUrl: string;
}
const ContainerIcon: FC<ContainerIconProps> = ({ imgUrl }) => {
  return <img src={imgUrl}></img>;
};

export default ContainerIcon;
