import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import {
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
} from "@mui/material";
import React from "react";
import { Node } from "react-flow-renderer";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  selectCurrentElement,
  updateElement,
} from "../../../../features/workflow/workflowSlice";
import WorkflowReviewStage from "../../../../../models/workflow/WorkflowReviewStage.model";
import { useAppSelector } from "../../../../store/hooks";
import ExpandableMenu from "../../../expandableMenu/ExpandableMenu";
import updateNodeCustomData from "../../utils/updateNodeCustomData";
import "./../../../../App.scss";
import "./nodetasks.scss";

const NodeTasks = () => {
  const { t } = useTranslation(["admin"]);
  const currentNode = useAppSelector(selectCurrentElement);
  const dispatch = useDispatch();
  return (
    <ExpandableMenu title={t("tasks")}>
      <Stack spacing={2} className="node-tasks-root">
        <Stack direction="column" spacing={2}>
          {(currentNode?.data as WorkflowReviewStage).tasks?.map(
            (task, index) => (
              <OutlinedInput
                size="small"
                className="no-label-input"
                type="text"
                key={index}
                value={task.description}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        const newList = (
                          currentNode?.data as WorkflowReviewStage
                        ).tasks?.filter(
                          (task, filterIndex: number) => filterIndex !== index
                        );
                        dispatch(
                          updateElement(
                            updateNodeCustomData(
                              "tasks",
                              newList,
                              currentNode as Node
                            )
                          )
                        );
                      }}>
                      <CancelOutlinedIcon />
                    </IconButton>
                  </InputAdornment>
                }
                onChange={(e) => {
                  const current = (currentNode?.data as WorkflowReviewStage)
                    .tasks;

                  if (current && currentNode) {
                    const updated = current.map((task, mapIndex) => {
                      if (mapIndex === index) {
                        return { description: e.target.value };
                      }
                      return task;
                    });
                    dispatch(
                      updateElement(
                        updateNodeCustomData(
                          "tasks",
                          updated,
                          currentNode as Node
                        )
                      )
                    );
                  }
                }}
              />
            )
          )}
        </Stack>
        <Button
          onClick={() => {
            const current = (currentNode?.data as WorkflowReviewStage).tasks;
            if (current) {
              dispatch(
                updateElement(
                  updateNodeCustomData(
                    "tasks",
                    [...current, { description: "" }],
                    currentNode as Node
                  )
                )
              );
            } else {
              dispatch(
                updateElement(
                  updateNodeCustomData(
                    "tasks",
                    [{ description: "" }],
                    currentNode as Node
                  )
                )
              );
            }
          }}
          className="add-task-button">
          <AddCircleOutlineIcon /> {t("addTask")}
        </Button>
      </Stack>
    </ExpandableMenu>
  );
};

export default NodeTasks;
