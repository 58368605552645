import AbcIcon from "@mui/icons-material/Abc";
import DateRangeIcon from "@mui/icons-material/DateRange";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import ImageIcon from "@mui/icons-material/Image";
import LinkIcon from "@mui/icons-material/Link";
import NumbersIcon from "@mui/icons-material/Numbers";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import SmartButtonIcon from "@mui/icons-material/SmartButton";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { nanoid } from "nanoid";
import React from "react";
import { ItemDNDType } from "../../models/Livepreview/ItemDNDType.model";
export const SIDEBAR_ITEM = "sidebarItem";
export const TAB = "tab";
export const ROW = "row";
export const COLUMN = "column";
export const COMPONENT = "component";

export const DEFAULTHEADER = "Header";

export const baseComponents = {};

export const baseLayout: Array<ItemDNDType> = [
  {
    type: ROW,
    id: "row0",
    title: DEFAULTHEADER,
    children: [
      {
        type: COLUMN,
        title: DEFAULTHEADER,
        id: "column0",
        children: [],
      },
    ],
  },
];

export const SIDEBAR_ITEMS = [
  {
    id: nanoid(),
    type: SIDEBAR_ITEM,
    children: "<div>ola</div>",
    component: {
      type: "input",
      content: "Some input",
    },
  },
  {
    id: nanoid(),
    type: SIDEBAR_ITEM,
    component: {
      type: "name",
      content: (
        <div>
          <b>UUIU</b>
        </div>
      ),
    },
  },
  {
    id: nanoid(),
    type: SIDEBAR_ITEM,
    component: {
      type: "email",
      content: "Some email",
    },
  },
  {
    id: nanoid(),
    type: SIDEBAR_ITEM,
    component: {
      type: "phone",
      content: "Some phone",
    },
  },
  {
    id: nanoid(),
    type: SIDEBAR_ITEM,
    component: {
      type: "image",
      content: "Some image",
    },
  },
];

// TODO: find a way to move this and link it up to the model
export const section = [
  {
    id: "fields",
    name: "Fields",
    sections: [
      {
        id: "textFields",
        name: "TEXT FIELDS",
        icon: AbcIcon,
      },
      {
        id: "numberFields",
        name: "NUMBER FIELDS",
        icon: NumbersIcon,
      },
      {
        id: "peoplePicker",
        name: "PEOPLE PICKER FIELDS",
        icon: GroupAddIcon,
      },
      {
        id: "multiChoice",
        name: "MULTI CHOICE FIELDS",
        icon: QuestionMarkIcon,
      },
      { id: "switchFields", name: "SWITCH FIELDS", icon: QuestionMarkIcon },
      { id: "URLFields", name: "URL FIELDS", icon: LinkIcon },
      { id: "dateFields", name: "DATE FIELDS", icon: DateRangeIcon },
      { id: "richTextFields", name: "RICH TEXT FIELDS", icon: TextSnippetIcon },
      { id: "imageFields", name: "IMAGE FIELDS", icon: ImageIcon },
    ],
  },
  {
    id: "tables",
    name: "Tables",
    sections: [
      {
        id: "standardTables",
        name: "STANDARD",
        icon: QuestionMarkIcon,
      },
      {
        id: "periodicTables",
        name: "PERIODIC",
        icon: QuestionMarkIcon,
      },
      {
        id: "tasksTables",
        name: "TASKS",
        icon: QuestionMarkIcon,
      },
    ],
  },
  {
    id: "buttons",
    name: "Buttons",
    sections: [
      {
        id: "buttons",
        name: "BUTTONS",
        icon: SmartButtonIcon,
      },
    ],
  },
];
