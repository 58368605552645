import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import DeleteIcon from "@mui/icons-material/Delete";
import { TreeItem, TreeView } from "@mui/lab";
import { Grid } from "@mui/material";
import React, { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  deletePath,
  selectChoiceList,
  setChoiceList,
} from "../../features/fields/multiChoiceSlice";
import { Choice } from "../../../models/multiChoiceField.model";
import { useAppSelector } from "../../store/hooks";
import "./multichoice.scss";
import MultiChoicePopover from "./MultiChoicePopover";

export interface multiChoiceProps {
  isEdit: boolean;
  list: Choice[];
}
const MultiChoice: FC<multiChoiceProps> = ({ isEdit, list }) => {
  const choiceList = useAppSelector(selectChoiceList);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEdit) {
      dispatch(setChoiceList(list));
    }
  }, [isEdit]);

  const renderTreeItemLabel = (choice: Choice, childrenLength: number) => (
    <Grid container direction="row">
      <Grid item className="label">
        {" "}
        {choice.label}
      </Grid>
      <Grid item container direction="row" className="tree_item_icons">
        <MultiChoicePopover
          path={`${choice.path}-${childrenLength + 1}`}
          isTopLevel={false}
        />

        <DeleteIcon
          fontSize="small"
          onClick={() => {
            dispatch(deletePath(choice.path));
          }}
        />
      </Grid>
    </Grid>
  );

  const recursiveRenderTreeNodes = (choice: Choice): any => {
    const children = findChildrenInList(choice.path, choiceList);

    if (children.length === 0) {
      return (
        <TreeItem
          className="tree_item"
          nodeId={choice.path}
          label={renderTreeItemLabel(choice, children.length)}
        />
      );
    }
    return (
      <TreeItem
        className="parent_tree_item"
        nodeId={choice.path}
        label={renderTreeItemLabel(choice, children.length)}>
        {children.map((mappedChoice: Choice) =>
          recursiveRenderTreeNodes(mappedChoice)
        )}
      </TreeItem>
    );
  };
  return (
    <div className="multi_choice">
      <TreeView
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}>
        {choiceList
          .filter((firstLevel) => firstLevel.path.length === 1)
          .map((node) => recursiveRenderTreeNodes(node))}
      </TreeView>
      <MultiChoicePopover
        path={(
          choiceList.filter((toFilter) => toFilter.path.length === 1).length + 1
        ).toString()}
        isTopLevel={true}
      />
    </div>
  );
};

const findChildrenInList = (path: string, list: Choice[]): Choice[] => {
  return list.filter(
    (toFilter) =>
      toFilter.path.startsWith(path) &&
      toFilter.path != path &&
      toFilter.path.length === path.length + 2
  );
};

export default MultiChoice;
