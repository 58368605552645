import { Stack } from "@mui/material";
import React, { FC } from "react";
import Zone from "../../models/backend/form/Zone.model";
import FormColumn from "./FormColumn";

export interface RowProps {
  zone: Zone;
}
const Row: FC<RowProps> = ({ zone }) => {
  return (
    <Stack direction="row" spacing={2}>
      {zone.columns.map((column, index) => (
        <FormColumn key={index} column={column} />
      ))}
    </Stack>
  );
};

export default Row;
