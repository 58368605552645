import { FormLabel, Grid, Link, Popover, TextField } from "@mui/material";

import React, { FC, useState } from "react";
import BaseUserFieldProps from "../../models/BaseUserFieldProps.model";
import "./urlfield.scss";
export interface UserURLFieldProps extends BaseUserFieldProps {
  titleInputPlaceholder?: string;
  URLInputPlaceholder?: string;
}
export interface URLValue {
  title?: string;
  url?: string;
}

const defaultValue: URLValue = {
  title: "",
  url: "",
};
const UserURLField: FC<UserURLFieldProps> = ({
  value,
  readOnly,
  URLInputPlaceholder,
  titleInputPlaceholder,
  disabled,
  isInTable,
  label,
  onChange,
  useInternalState,
  error,
}) => {
  const [internalState, setInternalState] = useState<URLValue>(value);
  const getCurrentValue = () => (useInternalState ? internalState : value);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const updateValue = (changeValue: any) =>
    useInternalState ? setInternalState(changeValue) : onChange(changeValue);

  return (
    <div className="user-url-field-root">
      {readOnly ? (
        <div className="link">
          <Link href={getCurrentValue().url}>
            {!!getCurrentValue().title
              ? getCurrentValue().title
              : getCurrentValue().url}
          </Link>
        </div>
      ) : isInTable ? (
        <>
          <TextField
            onClick={(e) => setAnchorEl(e.currentTarget)}
            variant="standard"
            value={!!value.title ? value.title : value.url}
            disabled={disabled}></TextField>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}>
            {renderTextFields(
              getCurrentValue(),
              disabled,
              updateValue,
              titleInputPlaceholder,
              URLInputPlaceholder,
              error
            )}
          </Popover>
        </>
      ) : (
        <Grid>
          <FormLabel component="legend">{label}</FormLabel>
          {renderTextFields(
            getCurrentValue(),
            disabled,
            updateValue,
            titleInputPlaceholder,
            URLInputPlaceholder,
            error
          )}
        </Grid>
      )}
    </div>
  );
};

const renderTextFields = (
  value: any,
  disabled: boolean | undefined,
  updateValue: any,
  titleInputPlaceholder?: string,
  URLInputPlaceholder?: string,
  error?: boolean
) => {
  return (
    <Grid container direction="column" spacing={2} padding="1em">
      <Grid item>
        <TextField
          disabled={disabled}
          label={titleInputPlaceholder ? titleInputPlaceholder : "Title"}
          value={value.title}
          onChange={(e) => updateValue({ ...value, title: e.target.value })}
          variant="standard"
        />
      </Grid>
      <Grid item>
        <TextField
          error={error}
          disabled={disabled}
          label={URLInputPlaceholder ? URLInputPlaceholder : "URL"}
          value={value.url}
          onChange={(e) => updateValue({ ...value, url: e.target.value })}
          variant="standard"
        />
      </Grid>
    </Grid>
  );
};
export default UserURLField;
