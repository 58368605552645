import { FormLabel, Stack, Switch, Typography } from "@mui/material";
import React, { FC, useState } from "react";
import BaseUserFieldProps from "../../models/BaseUserFieldProps.model";
import "./switch.scss";
export interface UserSwitchFieldProps extends BaseUserFieldProps {
  onLabel?: string;
  offLabel?: string;
}
const UserSwitchField: FC<UserSwitchFieldProps> = ({
  onLabel,
  offLabel,
  readOnly,
  value,
  onChange,
  useInternalState,
  isInTable,
  label,
  disabled
}) => {
  const [internalState, setInternalState] = useState(value);
  return (
    <Stack className="user-switch-field-root">
      {!isInTable && <FormLabel component="legend">{label}</FormLabel>}
      <Stack direction="row">
        <Typography className="label">{offLabel}</Typography>
        <Switch
          disabled={readOnly|| disabled}
          color="primary"
          checked={useInternalState ? internalState : value}
          onChange={(_, changeValue: boolean) =>
            useInternalState
              ? setInternalState(changeValue)
              : onChange(changeValue)
          }
        />
        <Typography className="label">{onLabel}</Typography>
      </Stack>
    </Stack>
  );
};
 
export default UserSwitchField;
