import { WorkflowNodeConfiguration } from "../WorkflowNode.model";
import colors from "../../../app-admin/theme/colors.module.scss";
const defaultWorkFlowNodeTypes: WorkflowNodeConfiguration[] = [
  {
    color: colors.stageColor,
    type: "Stage",
  },
  { color: colors.reviewStageColor, type: "StageReview" },
  { color: colors.conditionColor, type: "Condition" },
];

export default defaultWorkFlowNodeTypes;
