import { useMsal } from "@azure/msal-react";
import ExitToApp from "@mui/icons-material/ExitToApp";
import { Avatar, Button, Grid, Popover, Stack } from "@mui/material";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import EdisonTypography from "../../app-shared/edison/typography/EdisonTypography";
import "./avatarmenuitem.scss";


export interface AvatarMenuItemProps {
  src: string;
  userName: string;
}
function handleLogout(instance: any) {
  instance.logoutPopup().catch((e: unknown) => {
    console.error(e);
  });
}
const AvatarMenuItem: FC<AvatarMenuItemProps> = ({
  src,
  userName,
}) => {
  const { instance } = useMsal();
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const { t } = useTranslation(["common"]);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <div className="avatar-menu-item-root">
      <Stack direction="row"  className={"avatar-container"}>
        <Avatar src={src} onClick={(e) => setAnchorEl(e.currentTarget)} className="avatar" />
      </Stack>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}>
        <Grid
          container
          direction="column"
          spacing={2}
          className="avatar-popover-container">
          <Grid item>
            <Button
              size="small"
              color="inherit"
              onClick={() => handleLogout(instance)}
              startIcon={<ExitToApp fontSize="medium" />}>
              <div className="popover-row-text">
                <EdisonTypography
                  variant="t8"
                  title={t("exit")}
                  disableUppercase={true}
                />
              </div>
            </Button>
          </Grid>
        </Grid>
      </Popover>
    </div>
  );
};

export default AvatarMenuItem;
