import CloseIcon from "@mui/icons-material/Close";
import { Grid, IconButton } from "@mui/material";
import React, { FC, useRef, useState } from "react";
import { DropTargetMonitor, useDrag, useDrop, XYCoord } from "react-dnd";
import {
  ExpressionUnit,
  Operand,
} from "../../../../models/calculatedfield/calculatedfield.model";
import "./operatoroperand.scss";
import UserOperand from "./UserOperand";

export interface OperatorOperandProps {
  expressionUnit: ExpressionUnit;
  removeExpressionUnit: (number: number) => void;
  index: number;
  id: string;
  moveExpression: any;
}
interface DragItem {
  index: number;
  id: string;
  type: string;
}

const OperandOperator: FC<OperatorOperandProps> = ({
  expressionUnit,
  index,
  removeExpressionUnit,
  id,
  moveExpression,
}) => {
  const [mouseOver, setMouseOver] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<any, any, any>({
    accept: "Expression",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get horizontal middle
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Get pixels to the left
      const hoverClientX = (clientOffset as XYCoord).x - hoverBoundingRect.left;
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return;
      }

      // Time to actually perform the action
      moveExpression(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "Expression",
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <Grid
      container
      className="operator-operand-container"
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
      ref={ref}
      data-handler-id={handlerId}>
      <Grid item className="operator-content">
        {renderUnit(expressionUnit, index)}
      </Grid>
      <Grid
        item
        className="delete-container"
        style={{ visibility: `${!mouseOver ? "hidden" : "visible"}` }}>
        <IconButton size="small" onClick={() => removeExpressionUnit(index)}>
          <CloseIcon className="close-icon" />
        </IconButton>
      </Grid>
    </Grid>
  );
};

const renderUnit = (expressionUnit: ExpressionUnit, index: number): any => {
  switch (expressionUnit.type) {
    case "number":
      return (
        <UserOperand
          index={index}
          value={expressionUnit.value as number}
          type="number"
        />
      );
    case "operator":
      return expressionUnit.value;

    case "operand":
      return (expressionUnit.value as Operand).name;
    case "text":
      return (
        <UserOperand
          index={index}
          value={expressionUnit.value as string}
          type="string"
        />
      );
  }
};
export default OperandOperator;
