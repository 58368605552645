import {
  Icon,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import DefaultIcon from "@mui/icons-material/FileCopy";
import clsx from "clsx";
import { Location } from "history";
import React, { ComponentType, FC, ReactElement } from "react";
import { NavLink, useLocation } from "react-router-dom";
import RouteItem from "../../models/RouteItem.model";
import "./menuItem.scss";
import EdisonTypography from "../../app-shared/edison/typography/EdisonTypography";

const MenuItem: FC<RouteItem> = (route: RouteItem): ReactElement => {
  const location = useLocation();

  const handleNavigate = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ): void => {
    if (!route.enabled) e.preventDefault();
  };

  return (
    <>
      <NavLink
        to={`${route.path}`}
        style={{ textDecoration: "none", color: "inherit" }}
        onClick={handleNavigate}
        className={clsx("menu-item", {
          ["list-item-disabled"]: !route.enabled,
        })}>
        <Tooltip title={route.tooltip || ""} placement="right">
          <ListItem button disabled={!route.enabled} className="list-item">
            <ListItemIcon className="list-item">
              <IconButton
                className={clsx({
                  ["selected"]: location.pathname === route.path,
                })}
                size="small">
                {route.icon}
              </IconButton>
            </ListItemIcon>
            <EdisonTypography
              title={route.title || "home"}
              disableUppercase={true}
              variant="t9"
            />
          </ListItem>
        </Tooltip>
      </NavLink>
    </>
  );
};

export default MenuItem;
