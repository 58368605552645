import { Checkbox, IconButton, TableCell, TableRow } from "@mui/material";
import React, { FC } from "react";
import "./tablerow.scss";

export interface Props {
  row: any;
  columns: any;
  icons?: any;
  isSelected: boolean;
  onChange: any;
  showCheckBox: boolean;
}
const EdisonTableRow: FC<Props> = ({
  row,
  columns,
  icons,
  isSelected,
  onChange,
  showCheckBox,
}) => {
  return (
    <TableRow
      className="table-row"
      hover={true}
      onClick={() => onChange(!isSelected)}>
      {showCheckBox && (
        <TableCell width={"3em"}>
          <Checkbox
            checked={isSelected}
            onChange={(e) => {
              onChange(e.target.checked);
            }}
          />
        </TableCell>
      )}

      {columns.map((column: any) => (
        <TableCell key={column.id} align="left">
          {getFieldFromItem(row, column.reference)}
        </TableCell>
      ))}
      {icons?.map((iconToMap: any, index: number) => (
        <TableCell key={index}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              iconToMap.action(row.id);
            }}>
            {<iconToMap.icon />}
          </IconButton>
        </TableCell>
      ))}
    </TableRow>
  );
};

const getFieldFromItem = (row: any, columnReference: any) => {
  if (!row[columnReference]) {
    return null;
  }
  return !!row[columnReference]
    ? row[columnReference]
    : row.configuration[columnReference];
};

export default EdisonTableRow;
