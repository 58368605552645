import {
  DependencyId,
  ExpressionUnit,
  Operand,
} from "../../../../models/calculatedfield/calculatedfield.model";
import FieldItem from "../../../../models/FieldTypes/FieldItem.model";

const getDependenciesFromList = (
  list: ExpressionUnit[],
  fieldToEdit: FieldItem | null,
  calculatedFieldIds: number[]
): DependencyId[] => {
  const dependencyIds: DependencyId[] = [];
  const seen = new Set();
  list.forEach((expression) => {
    if (
      expression.type === "operand" &&
      calculatedFieldIds.includes((expression.value as Operand).id)
    ) {
      if (fieldToEdit) {
        const matched = fieldToEdit.fieldDependencies?.find(
          (depToFind) => depToFind.fieldId === (expression.value as Operand).id
        );
        if (matched) {
          if (matched.parentFieldId === 0) {
            dependencyIds.push({
              ...matched,
              parentFieldId: fieldToEdit.id,
            });
            return;
          }
          dependencyIds.push(matched);
          return;
        }
        dependencyIds.push({
          id: 0,
          fieldId: (expression.value as Operand).id,
          parentFieldId: fieldToEdit.id,
        });
        return;
      }

      dependencyIds.push({
        id: 0,
        fieldId: (expression.value as Operand).id,
        parentFieldId: 0,
      });
    }
  });

  return dependencyIds.filter((el) => {
    const duplicate = seen.has(el.fieldId);
    seen.add(el.fieldId);
    return !duplicate;
  });
};

export default getDependenciesFromList;
