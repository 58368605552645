import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid, Paper } from "@mui/material";
import React, { FC, useRef, useState } from "react";
import { useDrag } from "react-dnd";
import { useTranslation } from "react-i18next";
import { COLUMN } from "../../config/constants";
import { selectFormDesignerIsEdit, setColumnTitle } from "../../features/livepreview/livepreviewSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import "./column.scss";
import DropZone from "./DropZone";
import EditableHeader from "./EditableHeader";
import UserComponent from "./UserComponent";

export interface ColumnProps {
  data: any;
  components: any;
  handleDrop: any;
  path: string;
  removeColumn: any;
  removeComponent: any;
}

const Column: FC<ColumnProps> = ({
  data,
  components,
  handleDrop,
  path,
  removeColumn,
  removeComponent,
}) => {
  const ref = useRef(null);
  const { t } = useTranslation(["admin"]);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const dispatch = useAppDispatch();
  const isEdit = useAppSelector(selectFormDesignerIsEdit)

  const [{ isDragging }, drag] = useDrag({
    type: COLUMN,
    item: {
      type: COLUMN,
      id: data.id,
      children: data.children,
      path,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(ref);

  const renderComponent = (component: any, currentPath: string) => {
    return (
      <UserComponent
        key={component.id}
        data={component}
        components={components}
        path={currentPath}
        removeComponent={removeComponent}
        readOnly={!isEdit}
      />
    );
  };

  return (
    <>
      <Paper
        variant="outlined"
        className={`base draggable column`}
        ref={ref}
        style={{ width: "100%", opacity }}>
        {/* TODO: Reach a conclusion on re-size and either implement this back or remove it */}
        {/* <div className="re-size">
          <ArrowLeftIcon
            color={"disabled"}
            className={`re-size_arrow re-size_arrow-left`}
            fontSize="small"
            onMouseMove={(e) => {
              e.preventDefault();
            }}
            onClick={() => setColumnSize((prev) => (prev <= 0 ? 0 : prev - 1))}
          />
        </div> */}
        <div className={"column-content"}>
          <div className={`column_header`}>
            <Grid item>
              {isCollapsed ? (
                <ExpandMoreIcon
                  onClick={() => setIsCollapsed(false)}
                  className={`pointer`}
                />
              ) : (
                <ExpandLessIcon
                  onClick={() => setIsCollapsed(true)}
                  className={`pointer`}
                />
              )}
            </Grid>
            <Grid container justifyContent="flex-start">
              <EditableHeader
                title={data.title}
                onText={(title: string) =>
                  dispatch(setColumnTitle({ title, path }))
                }
              />
            </Grid>
            <Grid item>
              <CancelOutlinedIcon
                className={`pointer`}
                fontSize="small"
                onClick={() => removeColumn(path)}
              />
            </Grid>
          </div>
          {!isCollapsed && (
            <>
              {data.children.map((component: any, index: number) => {
                const currentPath = `${path}-${index}`;

                return (
                  <React.Fragment key={component.id}>
                    <DropZone
                      data={{
                        path: currentPath,
                        childrenCount: data.children.length,
                      }}
                      onDrop={handleDrop}
                    />
                    {renderComponent(component, currentPath)}
                  </React.Fragment>
                );
              })}
              <DropZone
                data={{
                  path: `${path}-${data.children.length}`,
                  childrenCount: data.children.length,
                }}
                onDrop={handleDrop}
                isLast
              />
            </>
          )}
        </div>
        {/* TODO: Reach a conclusion on re-size and either implement this back or remove it */}
        {/* <div className="re-size">
          <ArrowRightIcon
            color={"disabled"}
            className={`re-size_arrow re-size_arrow-right`}
            fontSize="small"
            onClick={() => setColumnSize((prev) => (prev >= 9 ? 9 : prev + 1))}
          />
        </div> */}
      </Paper>
    </>
  );
};
export default Column;
