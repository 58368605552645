import { Box, Tab, Tabs } from "@mui/material";
import React, { FC } from "react";

export interface EdisonTabsProps {
  tabs: any;
  indicatorColour: any;
  height: string;
  scrollable: boolean;
  tabTitleBoxStyling: any;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ height: "80%", overflowY: "auto" }}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function TabProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const EdisonTabs: FC<EdisonTabsProps> = ({
  tabs,
  indicatorColour,
  height,
  scrollable,
  tabTitleBoxStyling,
}) => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ height: height }}>
      <Box sx={tabTitleBoxStyling}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant={scrollable ? "scrollable" : "standard"}
          scrollButtons={scrollable}
          TabIndicatorProps={{ style: { background: indicatorColour } }}
          textColor="inherit">
          {tabs.map((tab: any, index: number) => (
            <Tab key={index} label={tab.label} {...TabProps(index)} />
          ))}
        </Tabs>
      </Box>
      {tabs.map((tab: any, index: number) => (
        <TabPanel key={index} index={index} value={value}>
          {tab.children}
        </TabPanel>
      ))}
    </Box>
  );
};

export default EdisonTabs;
