import { Box, Typography } from "@mui/material";
import React, { FC, useState } from "react";
import "./typography.scss";
import typographyTypes from "../../../models/typography/Typography.enum";
import classNames from "classnames";

export interface EdisonTypographyProps {
  title: string;
  variant: string;
  disableUppercase?: boolean;
}

const EdisonTypography: FC<EdisonTypographyProps> = ({
  title,
  variant,
  disableUppercase,
}) => {
  const variantData =
    typographyTypes.find((typography) => typography.variantName === variant) ||
    typographyTypes[0];

  return (
    <Typography
      component="div"
      className={classNames("edison-typography", {
        uppercase: !disableUppercase,
      })}>
      <Box
        letterSpacing={variantData.letterSpacing}
        fontSize={variantData.fontSize}
        fontWeight={variantData.fontWeight}
        className="edison-typography-box">
        {title}
      </Box>
    </Typography>
  );
};
export default EdisonTypography;
