import React, { FC, useEffect, useState } from "react";
import Item from "../../../models/Item.model";
import EdisonSearchBar from "../../../app-shared/edison/searchbar/EdisonSearchBar";
import EdisonTabs from "../../../app-shared/edison/tabs/EdisonTabs";

import colors from "../../theme/colors.module.scss";

export interface SearchableTabsProps {
  tabs: SingleTab[];
  tabsToSearch: string[];
}

export interface SingleTab {
  label: string;
  children: any;
}

const SearchableTabs: FC<SearchableTabsProps> = ({ tabs, tabsToSearch }) => {
  const [searchFilter, setSearchFilter] = useState("");
  const [searchTabs, setSearchTab] = useState(tabs);

  useEffect(() => {
    setSearchTab(
      tabs.map((item) =>
        tabsToSearch.includes(item.label)
          ? {
              ...item,
              children: {
                ...item.children,
                props: {
                  ...item.children.props,
                  data: (item.children.props.data as Item[]).filter(
                    (toFilter) =>
                      toFilter.name
                        .toLowerCase()
                        .includes(searchFilter.toLowerCase()) ||
                      toFilter.displayName
                        .toLowerCase()
                        .includes(searchFilter.toLowerCase())
                  ),
                },
              },
            }
          : item
      )
    );
  }, [tabs, searchFilter]);
  return (
    <div style={{ height: "100%", marginTop: "1em" }}>
      <EdisonSearchBar
        searchFilter={searchFilter}
        setSearchFilter={setSearchFilter}
        width="100%"
      />

      <EdisonTabs
        tabs={searchTabs}
        height="90%"
        scrollable={false}
        indicatorColour={colors.primaryColor}
        tabTitleBoxStyling={{
          borderBottom: 1,
          borderColor: "divider",
        }}
      />
    </div>
  );
};

export default SearchableTabs;
