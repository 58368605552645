import { Stack } from "@mui/material";
import React, { FC } from "react";
import Column from "../../models/backend/form/Column.model";
import { selectFormReadOnly } from "../../redux/reducers/formsSlice";
import { useAppSelector } from "../../redux/store/hooks";
import TypeToComponentMapper from "../../utils/typeToComponentMapper";
import LabeledData from "../labeleddata/LabeledData";
export interface ColumnProps {
  column: Column;
}
const FormColumn: FC<ColumnProps> = ({ column }) => {
  const readOnly = useAppSelector(selectFormReadOnly);
  const [value, setValue] = React.useState();
  return (
    <Stack spacing={1}>
      {column.fields.map((columnField, index) => (
        <LabeledData
          key={index}
          label={
            columnField.field?.displayName ? columnField.field.displayName : ""
          }>
          {TypeToComponentMapper(readOnly, columnField.field, value, setValue)}
        </LabeledData>
      ))}
      {column.tables.map((columnTable: any, index: number) => (
        <LabeledData key={index} label={"Table"}>
          {TypeToComponentMapper(readOnly, columnTable.table, value, setValue)}
        </LabeledData>
      ))}
    </Stack>
  );
};

export default FormColumn;
