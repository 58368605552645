import Item from "../../models/Item.model";

const defaultItem = (dataType: string, configuration?: unknown): Item => ({
  name: "",
  displayName: "",
  id: 0,
  description: "",
  modified: new Date(),
  configuration: configuration,
  dataType: dataType,
  isDeleted: false,
  isSystem: false,
});

export default defaultItem;
