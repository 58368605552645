import { createTheme, Theme } from "@mui/material/styles";
import colorPallete from "./colorPallete.json";

// define themes based on app
// TODO: eventually split this up into different files
export const ideasTheme: Theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: colorPallete.ideasColor,
    },
    secondary: {
      main: colorPallete.secondaryColor,
    },
  },
});

export const adminTheme: Theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: colorPallete.primaryColor,
      contrastText: "#fff"
    },
    secondary: {
      main: colorPallete.secondaryColor,
    },
    warning: {
      main: colorPallete.warning,
    },
    grey: {
      50: colorPallete.lightGrey,
      100: colorPallete.grey100,
    },
  },
});

export const projectsTheme: Theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: colorPallete.projectsColor,
      contrastText: "#fff"
    },
    secondary: {
      main: colorPallete.secondaryColor,
    },
  },
});

export const casesTheme: Theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: colorPallete.casesColor,
    },
    secondary: {
      main: colorPallete.secondaryColor,
    },
  },
});
export const darkTheme: Theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: colorPallete.darkPrimaryColor,
    },
    secondary: {
      main: colorPallete.secondaryColor,
    },
  },
});

// end theme definition

export function getThemeByName(theme: string): Theme {
  const selectedTheme = themeMap[theme];

  document.documentElement.style.setProperty(
    "--primary-color",
    selectedTheme.palette.primary.main
  );
  document.documentElement.style.setProperty(
    "--secondary-color",
    selectedTheme.palette.secondary.main
  );
  return selectedTheme;
}

const themeMap: { [key: string]: Theme } = {
  ideasTheme,
  adminTheme,
  projectsTheme,
  casesTheme,
};
