import { Skeleton } from "@mui/material";
import React, { FC } from "react";
import "./skeletontext.scss";

export interface Props {
  className?: string;
  rows: number;
}

const EdisonSkeletonText: FC<Props> = ({ className, rows }) => {
  const skeletonRows = Array.from({ length: rows }, (_, i) => i);

  return (
    <div className="skeleton-text">
      {skeletonRows.map((row) => (
        <Skeleton key={row} animation="wave" />
      ))}
    </div>
  );
};

export default EdisonSkeletonText;
