export const msalConfig = {
  auth: {
    clientId: "d7c4c8f8-b6c7-42a8-a137-c1f7dd93498e",
    // authority: "Enter_the_Cloud_Instance_Id_Here/Enter_the_Tenant_Info_Here",
    redirectUri: "/",
    postLogoutRedirectUri: "/",
    authority:
      "https://login.microsoftonline.com/e5ec45c9-6c62-49ba-a625-d3f101129c1e",
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["api://d7c4c8f8-b6c7-42a8-a137-c1f7dd93498e/edison365.default"],
};

export const graphRequest = {
  scopes: ["User.Read.All"], // TODO: need "Presence.Read", "Presence.Read.All" to get status
};

const region = "westeu";

// // Direct endpoint
// const contentHubEndpoint =
//   "https://api-edison365-contenthub-dev-westeu-001.azurewebsites.net/";

//Endpoint using API Gateway
const apiGateway = `https://api-gateway-edison365-dev-${region}-001.edison365.com`;

const apiPaths = {
  management: "/management/",
  projects: "/projects/",
  contentHub: "/contenthub/",
  common: "/common/",
};

export const apiConfig = {
  // contentHubFieldsEndpoint: `${contentHubEndpoint}api/fields`,
  commonApi: `${apiGateway}${apiPaths.common}api/`,
  commonAnnouncementEndpoint: `${apiGateway}${apiPaths.common}api/announcement`,

  contentHubApi: `${apiGateway}${apiPaths.contentHub}api/`,
  contentHubFieldsEndpoint: `${apiGateway}${apiPaths.contentHub}api/fields`,
  contentHubTablesEndpoint: `${apiGateway}${apiPaths.contentHub}api/tables`,
  contentHubFormsEndpoint: `${apiGateway}${apiPaths.contentHub}api/forms`,
  contentHubWorkflowEndpoint: `${apiGateway}${apiPaths.contentHub}api/workflows`,

  projectsApi: `${apiGateway}${apiPaths.projects}api/`,
  projectTypesEndpoint: `${apiGateway}${apiPaths.projects}api/projecttypes`,
  projectsEndpoint: `${apiGateway}${apiPaths.projects}api/projects`,

  apiGatewayApi: `${apiGateway}/api/`,
  supportEndpoint: "https://support.edison365.com/",
};
// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphEndpoint: "https://graph.microsoft.com/v1.0",
};
