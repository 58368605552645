import { FormLabel } from "@mui/material";
import { TreeSelect } from "antd";
import React, { FC, useState } from "react";
import { BaseFieldProps } from "../../models/BaseFieldProps";
import BaseUserFieldProps from "../../models/BaseUserFieldProps.model";

export interface Choice {
  label: string;
  value: string;
  children?: Choice[];
}

export interface UserCheckBoxGroupProps extends BaseUserFieldProps {
  choices: Choice[];
  multiLevel?: boolean;
}
const { TreeNode } = TreeSelect;
const UserCheckBoxGroup: FC<UserCheckBoxGroupProps> = ({
  value,
  readOnly,
  label,
  isInTable,
  choices,
  multiLevel,
  onChange,
  useInternalState,
}) => {
  const [internalState, setInternalState] = useState<string[]>(value);
  return (
    <>
      {!isInTable && <FormLabel component="legend">{label}</FormLabel>}
      <TreeSelect
        showSearch
        style={{ width: "100%" }}
        value={useInternalState ? internalState : (value as string[])}
        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
        treeCheckable={true}
        multiple
        disabled={readOnly}
        onChange={(changeValue: string[]) =>
          useInternalState
            ? setInternalState(changeValue)
            : onChange(changeValue)
        }
        placeholder="Please select">
        {multiLevel
          ? choices.map((choice) => recursiveRenderTreeNodes(choice))
          : choices.map((choice: Choice) => (
              <TreeNode
                key={choice.value}
                value={choice.value}
                title={choice.label}></TreeNode>
            ))}
      </TreeSelect>
    </>
  );
};
const recursiveRenderTreeNodes = (choice: Choice): any => {
  if (!choice.children || choice.children.length === 0) {
    return (
      <TreeNode
        key={choice.value}
        value={choice.value}
        title={choice.label}></TreeNode>
    );
  }
  return (
    <TreeNode key={choice.value} value={choice.value} title={choice.label}>
      {choice.children.map((choice: Choice) =>
        recursiveRenderTreeNodes(choice)
      )}
    </TreeNode>
  );
};

export default UserCheckBoxGroup;
