import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import EdisonFormInput from "../../app-shared/edison/forminput/EdisonFormInput";
import EdisonFormSwitch from "../../app-shared/edison/formswitch/EdisonFormSwitch";

interface Props {
  defaultConfiguration?: any;
  isEdit: boolean;
}

export const TextboxInputs: FC<Props> = ({ defaultConfiguration, isEdit }) => {
  const { t } = useTranslation(["admin"]);
  return (
    <div>
      <EdisonFormInput
        disabled={false}
        name="maxLength"
        label={t("maxLength")}
        defaultValue={
          !!defaultConfiguration ? defaultConfiguration.maxLength : ""
        }
        requiredMessage={t("maxLengthRequiredMessage")}
        type="number"
      />
      <EdisonFormSwitch
        name="isMultiLine"
        label={t("multipleLines")}
        isDisabled={false}
        defaultValue={
          !!defaultConfiguration ? defaultConfiguration.isMultiLine : false
        }
      />
    </div>
  );
};
