import React from "react";
import { FC } from "react";
import { useDrag } from "react-dnd";

export interface FeatureItemProps {
  // name: string;
  key: string;
  data: any;
  type: string;
  children?: any;
}

const FeatureItem: FC<FeatureItemProps> = ({
  // name,
  data,
  type,
  children,
}) => {
  const [{ opacity, cursor }, drag] = useDrag({
    type: type,
    item: data,
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
      cursor: "pointer",
    }),
  });

  return (
    <div className="sideBarItem" ref={drag} style={{ opacity, cursor }}>
      <div>{children}</div>
    </div>
  );
};

export default FeatureItem;
