import { translations as adminTranslations } from "./app-admin/i18n/adminApp";
// import {translations as projectTranslations} from "EDProjects/translations"
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import common_en from "./i18n/en/common.json";
import common_pt from "./i18n/pt/common.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      common: common_en,
      admin: adminTranslations.en, // 'common' is our custom namespace
      // projects: projectTranslations.en,
    },
    pt: {
      common: common_pt,
      admin: adminTranslations.pt,
      // projects: projectTranslations.pt,
    },
  },
  interpolation: { escapeValue: false }, // React already does escaping
  lng: "en", // language to use

  ns: ["common", "admin"],
  fallbackLng: "en",
  debug: true,
});
