export const translations: Record<string, string> = {
  administration: "Administration",
  fieldType: "FIELD TYPE",
  lastUpdated: "Last Updated",
  type: "TYPE",
  icon: "ICON",
  createField: "Create Field",
  buttonsPageDescriptionP1:
    "Create custom buttons for use on forms to enable clickable links to other sites.",
  buttonsPageDescriptionP2:
    "Use of the tokens will make a button URL dynamic by passing in the properties from the Project.",
  Tokens: "Tokens",
  createButton: "Create Button",
  cancel: "Cancel",
  save: "Save",
  label: "Label",
  internalName: "Internal Name",
  linkUrl: "Link Url",
  description: "Description",
  descriptionRequiredMessage: "Description required",
  internalNameRequiredMessage: "Internal name is required",
  labelRequiredMessage: "Label is required",
  linkUrlRequiredMessage: "Link Url is required",
  loadingErrorMessage:
    "Couldn&apos;t load the data at this time. Please refresh the page or contact your administrator.",
  defaultTitle: "Default Title",
  defaultValue: "Default Value",
  systemFields: "System Fields",
  createTable: "Create Table",
  system: "SYSTEM",
  custom: "CUSTOM",
  Administration: "ADMINISTRATION",
  database: "DATABASE",
  dragAndDrop: "DRAG & DROP INTO PREVIEW",
  cardDragAndDrop: "DRAG & DROP ONTO CARD",
  dndToReorder: "DRAG & DROP TO REORDER",
  globalusers: "GLOBAL USERS",
  fields: "FIELDS",
  features: "FEATURES",
  featureDescriptionTab: "Add a New Tab",
  featureDescriptionOneCol: "Add One Column Zone",
  featureDescriptionTwoCol: "Add Two Column Zone",
  featureDescriptionOneThirdTwoThirds: "Add One Third Two Thirds Zone",
  featureDescriptionTwoThirdsOneThird: "Add Two Thirds One Third Zone",
  featureDescriptionThreeCol: "Add Three Column Zone",
  tables: "TABLES",
  buttons: "BUTTONS",
  forms: "FORMS",
  types: "TYPES",
  news: "NEWS",
  theme: "THEME",
  notification: "NOTIFICATION",
  pwa: "PWA",
  productlinks: "PRODUCT LINKS",
  teams: "TEAMS",
  yammer: "YAMMER",
  reports: "REPORTS",
  customerinfo: "CUSTOMER INFO",
  cardDesigner: "CARD DESIGNER",
  cardFeatures: "CARD FEATURES",
  cardType: "CARD TYPE",
  newField: "NEW FIELD",
  newfield: "NEW FIELD",
  newButton: "NEW BUTTON",
  newbutton: "NEW BUTTON",
  newTable: "CREATE TABLE",
  newtable: "NEW TABLE",
  editTable: "EDIT TABLE",
  edittable: "EDIT TABLE",
  editField: "EDIT FIELD",
  editfield: "EDIT FIELD",
  notifications: "Notifications",
  name: "Name",
  tableType: "Table Type",
  copyFromTable: "Copy From Table",
  copy: "Copy",
  fieldsAvailableToAdd: "Fields Available to Add",
  tableConfiguration: "Table Configuration",
  typeRequiredMessage: "Type is required",
  multipleLinesRequiredMessage: "Multiple lines choice is required",
  decimalRequiredMessage: "Decimal places choice is required",
  minimumValueRequiredMessage: "Minimum value required",
  maximumValueRequiredMessage: "Maximum value required",
  textBox: "Textbox",
  richText: "Rich Text",
  number: "Number",
  image: "Image",
  choice: "Choice",
  multiChoice: "Multiple Choice",
  multiLevelChoice: "Multi-Level Choice",
  multiValue: "Multiple Values",
  rag: "RAG Status",
  date: "Date",
  switch: "Switch",
  people: "People Picker",
  url: "URL",
  calculated: "Calculated",
  multipleLines: "Multiple Lines of Text",
  fieldTypes: "Field Type",
  periodicUnits: "Periodic Units",
  defaultView: "Default View",
  dataType: "Data Type",
  percentage: "Percentage",
  currency: "Currency",
  format: "Format",
  decimalPlaces: "Decimal Places",
  minimumValue: "Minimum Value",
  maximumValue: "Maximum Value",
  maxLength: "Maximum Length",
  noDataErrorMessage: "No Data",
  onLabel: "On Label",
  offLabel: "Off Label",
  onLabelValueRequiredMessage: "On Label Value Required",
  offLabelValueRequiredMessage: "Off Label Value Required",
  choiceRequiredMessage: "Choice value required",
  maxLengthRequiredMessage: "Maximum Length Value Required",
  standard: "Standard",
  periodic: "Periodic",
  details: "Details",
  months: "Months",
  quarters: "Quarters",
  years: "Years",
  cost: "Cost",
  effort: "Effort",
  material: "Material",
  task: "Task",
  scope: "Scope",
  ideas: "Ideas",
  businessCase: "Business Case",
  projects: "Projects",
  properties: "Properties",
  scopeRequiredMessage: "Scope is Required",
  newForm: "NEW FORM",
  createForm: "Create Form",
  editForm: "Edit Form",
  expression: "Expression",
  operators: "Operators",
  fieldsLower: "Fields",
  test: "Test",
  condition: "Condition",
  livePreview: "Live Preview",
  featurePreview: "FEATURE PREVIEW",
  comparators: "Comparators",
  if: "If",
  formDesigner: "Form Designer",
  newNumber: "New Number",
  numericalValue: "Numerical Value",
  fieldsDescription:
    "Create and edit system and custom fields for use on forms ",
  tablesDescription: "Create and edit system and custom for use on forms",
  buttonsDescription:
    "Create and edit system and custom buttons for use on forms",
  formsDescription: "Create and edit custom forms",
  formDesignerDescription:
    "Add a personal touch to your forms using fields, tables and buttons",
  typesDescription: "Create and edit types for Projects and Businesscases",
  newsDescription:
    "Create and edit the news items which are displayed to all users on the homepage",
  workflowDescription:
    "Create and edit your workflows for all your automation purposes ",
  yammerDescription: "This is the description",
  reportsDescription:
    "Create and edit reports for all your statistical and visual needs",
  cardDesignerDescription:
    "Edit and customise your discover cards with system and custom user data",
  addChoice: "Add Choice",
  userValue: "User Value",
  newText: "New Text",
  text: "Text",
  enterText: "Enter Text",
  size: "Size",
  addCustomText: "Add Custom Text",
  addCustomNumber: "Add Custom Number",
  add: "Add",
  hidden: "Hidden",
  update: "Update",
  restore: "Restore",
  backgroundColour: "Background Colour",
  nodeOptions: "Node Options",
  multipleChoice: "Multiple Choices",
  addNewRow: "Add new row",
  defaultLivepreviewElementTitle: "Header",
  allowManagersApproval: "Allow Managers Approval",
  autoProgress: "Auto-Progress",
  hasReview: "Has Review",
  outputStage: "Output Stage",
  inputStage: "Input Stage",
  stageOptions: "Stage Options",
  requiredApproversCount: "Required Reviewers count",
  eventActions: "Event Actions",
  eventTypes: "Event Types",
  CreateBusinessCase: "Create Business Case",
  CreateIdea: "Create Idea",
  CreateProject: "Create Project",
  CreateVersion: "Create Version",
  stage: "Stage",
  events: "Events",
  flags: "Flags",
  form: "Form",
  OnComplete: "On Complete",
  OnStart: "On Start",
  reviewers: "Reviewers",
  noReviewersMessage: "No Reviewers found.",
  workflowName: "Workflow Name",
  workflowOptions: "Workflow Options",
  trueFalseToggle: "True False Toggle (WIP)",
  workflowDesigner: "Workflow Designer",
  exit: "Exit",
  components: "Components",
  newWorkflow: "New Workflow",
  StageReview: "Review Stage",
  Condition: "Condition",
  Stage: "Stage",
  addTask: "Add Task",
  addEvent: "Add Event",
  newType: "New Type",
  createType: "Create Type",
  activeTypes: "Active Types",
  deletedTypes: "Deleted Types",
  export: "Export",
  deleteConnection: "Delete Connection",
  readOnly: "Read Only",
  visibility: "Visibility",
  weeks: "Weeks",
  taskView: "Task View",
  boardView: "Board View",
  undo: "UNDO",
  redo: "REDO",
  zoomIn: "ZOOM IN",
  zoomOut: "ZOOM OUT",
  zoomToFit: "ZOOM TO FIT",
  addCard: "Add Card",
  viewCard: "View Card",
  editCar: "Edit Card",
  removeCard: "Remove Card",
  recordType: "Record Type",
  typeName: "Type Name",
  processManagers: "Process Managers",
  owners: "Owners",
  readers: "Readers",
  workflow: "Workflow",
  noMatches: "No Matches Found",
};
