import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { AppBar, CssBaseline, IconButton, Stack, Toolbar } from "@mui/material";
import React, { FC, ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { routes } from "../../config";
import { apiConfig } from "../../config/authConfig";
import {
  getMicrosoftGraphUserAsync,
  getUserPhotoAsync,
  selectUser,
  selectUserPhoto,
} from "../../features/user/userSlice";
import RouteItem from "../../models/RouteItem.model";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import AppItem from "../AppItem/AppItem";
import IconSelectMenu from "../IconSelectMenu/IconSelectMenu";
import AvatarMenuItem from "../MenuItem/AvatarMenuItem";
import "./header.scss";

interface Props {
  open: boolean;
  handleMenuOpen?: () => void;
}

const Header: FC<Props> = ({}): ReactElement => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();
  const userPhoto: any = useAppSelector(selectUserPhoto);
  const user: any = useAppSelector(selectUser);

  useEffect(() => {
    dispatch(getUserPhotoAsync("me"));
  }, []);

  useEffect(() => {
    dispatch(getMicrosoftGraphUserAsync());
  }, []);

  return (
    <>
      <CssBaseline />
      <AppBar
        position="fixed"
        color="secondary"
        elevation={0}
        className={"app-bar"}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar className={"toolbar"}>
          <div className={"left-content"}>
            {routes
              .filter((route: RouteItem) => !!route.isApp)
              .map((route: RouteItem) => (
                <AppItem
                  key={`${route.key}`}
                  title={t(route.title ?? "")}
                  icon={route.icon}
                  tooltip={t(route.tooltip ?? "")}
                  path={route.path}
                  enabled={route.enabled}
                  component={route.component}
                  subRoutes={route.subRoutes}
                  themeName={route.themeName}
                />
              ))}
          </div>
          <Stack direction="row" spacing={1} className={"right-content"}>
            <IconSelectMenu />
            <IconButton size="small" color="inherit">
              <NotificationsNoneIcon />
            </IconButton>

            <IconButton
              onClick={() => window.open(apiConfig.supportEndpoint, "_blank")}
              size="small"
              color="inherit">
              <HelpOutlineIcon className="question-icon" />
            </IconButton>

            <AvatarMenuItem src={userPhoto} userName={user.displayName} />
          </Stack>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
