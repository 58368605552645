import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getProjectTypes, postProjectType } from "../../../services/APIService";
import { RootState } from "../../store/store";

export interface typesState {
  types: any[];
  status: "idle" | "loading" | "failed";
}

const initialState: typesState = {
  types: [],
  status: "idle",
};
export const getTypesAsync = createAsyncThunk(
  "types/getTypes",
  async (_, { rejectWithValue }) => {
    const response: any = await getProjectTypes();
    if (!(response.status as number).toString().startsWith("2"))
      return rejectWithValue(response.data);
    return response.data;
  }
);

export const createTypesAsync = createAsyncThunk(
  "types/createType",
  async (data: unknown, { rejectWithValue }) => {
    console.log("api req", data);
    const response: any = await postProjectType(data);
    console.log("000", response);
    if (!(response.status as number).toString().startsWith("2"))
      return rejectWithValue(response.data);
    return response.data;
  }
);

export const typesSlice = createSlice({
  name: "types",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTypesAsync.fulfilled, (state, action) => {
        if (!action.payload) return;
        state.types = action.payload;
      })
      .addCase(createTypesAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createTypesAsync.fulfilled, (state, action) => {
        state.status = "idle";
        if (!action.payload) return;
        state.types.push(action.payload);
      });
  },
});

export const selectTypes = (state: RootState): Array<any> => {
  console.log(state);
  return state.types.types;
};
export default typesSlice.reducer;
