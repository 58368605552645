import {
  CardContent,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import { AdminButton } from "../../components/adminbutton/AdminButton";
import AdminTable from "../../components/admintable/AdminTable";
import Layout from "../../components/layout/Layout";
import Loading from "../../components/loading/Loading";
import { routes } from "../../config/routes";
import {
  getFieldsAsync,
  recycleFieldAsync,
  selectAllNonDeletedFields,
} from "../../features/fields/fieldsSlice";
import ColumnModel from "../../../models/Column.model";
import Item from "../../../models/Item.model";
import RouteItem from "../../../models/RouteItem.model";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { RootState } from "../../store/store";
import EdisonCard from "../../../app-shared/edison/card/EdisonCard";
import "./fields.scss";

const Fields: FC = () => {
  const { url } = useRouteMatch();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["admin"]);

  const fields = useAppSelector(selectAllNonDeletedFields);
  const fieldsStatus = useAppSelector(
    (state: RootState) => state.fields.status
  );

  useEffect(() => {
    if (fieldsStatus === "idle") {
      dispatch(getFieldsAsync());
    }
  }, []);

  const [state, setState] = useState({
    checkedB: true,
    isLoading: true,
    hasError: false,
  });

  const columns: ColumnModel[] = [
    {
      id: 1,
      name: t("label"),
      reference: "displayName",
    },
    {
      id: 2,
      name: t("fieldType"),
      reference: "dataType",
    },
    {
      id: 3,
      name: t("lastUpdated"),
      reference: "modified",
    },
  ];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const deleteRow = async (ids: number[]) => {
    ids.forEach((id: number) => dispatch(recycleFieldAsync(id)));
  };

  const editRow = async (ids: number[]) => {
    history.push({
      pathname: `/admin/fields/editfield`,
      state: {
        title: t("editField"),
        item: fields.find((item: Item) => item.id === ids[0]),
      },
    });
  };

  const route = routes.find(
    (routeToFind: RouteItem) => routeToFind.key == "newfield"
  );

  return (
    <Layout>
      <div className="fields-page-root">
        <EdisonCard>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <AdminButton
                pathName={!!route ? route.path : `${url}/newfield`}
                title={t("newField")}
                buttonText={t("createField")}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                value="top"
                control={
                  <Switch
                    checked={state.checkedB}
                    onChange={handleChange}
                    color="primary"
                    name="checkedB"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
                className="uppercase-text"
                label={t("systemFields") as string}
                labelPlacement="top"
              />
            </Grid>
          </Grid>
        </EdisonCard>

        {fieldsStatus !== "loading" ? (
          <Grid item xs={12}>
            <EdisonCard>
              <CardContent>
                {fields.length > 0 ? (
                  <AdminTable
                    data={fields}
                    columns={columns}
                    editAction={editRow}
                    deleteAction={deleteRow}
                  />
                ) : (
                  <Typography variant="body1">
                    {t("loadingErrorMessage")}
                  </Typography>
                )}
              </CardContent>
            </EdisonCard>
          </Grid>
        ) : (
          <Loading />
        )}
      </div>
    </Layout>
  );
};
export default Fields;
