import React, { FC } from "react";
import EdisonFormSwitch from "../../app-shared/edison/formswitch/EdisonFormSwitch";

import { useTranslation } from "react-i18next";

interface Props {
  defaultConfiguration?: any;
  isEdit: boolean;
}

export const PeoplePickerInputs: FC<Props> = ({
  defaultConfiguration,
  isEdit,
}) => {
  const { t } = useTranslation(["admin"]);
  return (
    <div>
      <EdisonFormSwitch
        name="isMultiValue"
        label={t("multiValue")}
        isDisabled={false}
        defaultValue={
          !!defaultConfiguration ? defaultConfiguration.isMultiValue : false
        }
      />
    </div>
  );
};
