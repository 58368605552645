import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Grid, Paper } from "@mui/material";
import React, { FC, useRef, useState } from "react";
import { DropTargetMonitor, useDrag, useDrop, XYCoord } from "react-dnd";
import { useTranslation } from "react-i18next";
import { COLUMN, TAB } from "../../config/constants";
import {
  moveTabIndex,
  removeTab,
  selectCurrentTab,
  setCurrentTab,
  setCurrentTabTitle,
} from "../../features/livepreview/livepreviewSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import "./tab.scss";
import EditableHeader from "./EditableHeader";
import classNames from "classnames";

interface DraggableDNDItem {
  index: number;
  id: string;
}

export interface TabProps {
  data: any;
  id: string;
  index: number;
}

const Tab: FC<TabProps> = ({ data, id, index }) => {
  const { t } = useTranslation(["admin"]);
  const dispatch = useAppDispatch();
  const currentTab = useAppSelector(selectCurrentTab);
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<any, any, any>({
    accept: TAB,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DraggableDNDItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get horizontal middle
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Get pixels to the left
      const hoverClientX = (clientOffset as XYCoord).x - hoverBoundingRect.left;
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return;
      }
      // Time to actually perform the action
      dispatch(
        moveTabIndex({
          dragIndex: dragIndex,
          hoverIndex: hoverIndex,
        })
      );
      //   moveTab(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: TAB,
    item: () => {
      return { id: data.id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));
  return (
    <>
      <Paper
        variant="outlined"
        className={classNames("base draggable lp-tab", {
          selected: currentTab.id === data.id,
        })}
        onFocus={() => dispatch(setCurrentTab(data.id))}
        ref={ref}
        data-handler-id={handlerId}>
        <div className={"lp-tab_header"}>
          <Grid container flexWrap={"nowrap"} justifyContent="flex-start">
            <EditableHeader
              title={data.title}
              onText={(title: string) => dispatch(setCurrentTabTitle(title))}
            />
            <CancelOutlinedIcon
              className={`cancel-icon`}
              fontSize="small"
              onClick={(e) => {
                e.stopPropagation();
                dispatch(removeTab(data.id));
              }}
            />
          </Grid>
        </div>
      </Paper>
    </>
  );
};
export default Tab;
