import { TextField } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  selectExpressionBoxClicked,
  updateUserOperandInList,
} from "../../../features/fields/calculatedFieldSlice";
import { useAppSelector } from "../../../store/hooks";
import "./useroperand.scss";
export interface UserOperandProps {
  index: number;
  value: number | string;
  type: "number" | "string";
}
export interface UpdateUserOperandPayload {
  index: number;
  newValue: number | string;
}
const UserOperand: FC<UserOperandProps> = ({ index, value, type }) => {
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useDispatch();

  const expressionBoxClicked = useAppSelector(selectExpressionBoxClicked);
  useEffect(() => {
    setIsEdit(false);
  }, [expressionBoxClicked]);
  return (
    <div
      onMouseDown={(e) => {
        e.stopPropagation();
        setIsEdit((prev) => !prev);
      }}
      className={`user-operand-container ${type === "string" && "string"} `}>
      {isEdit ? (
        <TextField
          value={value}
          size="small"
          type={type}
          hiddenLabel
          onChange={(e) =>
            type === "number"
              ? dispatch(
                  updateUserOperandInList({
                    index: index,
                    newValue: Number(e.target.value),
                  })
                )
              : dispatch(
                  updateUserOperandInList({
                    index: index,
                    newValue: e.target.value,
                  })
                )
          }
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
        />
      ) : (
        value
      )}
    </div>
  );
};

export default UserOperand;
