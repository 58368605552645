import { Grid } from "@mui/material";
import React from "react";
import Person from "../../models/Person.model";
import PersonIcon from "@mui/icons-material/Person";
import "./leaderboard.scss";
import EdisonTypography from "../../app-shared/edison/typography/EdisonTypography";

interface leaderBoardPerson extends Person {
  placement: number;
}

const mockedLeaderBoard: leaderBoardPerson[] = [
  {
    forename: "John",
    surname: "Doe",
    id: "1",
    placement: 1,
    icon: "JD",
  },
  {
    forename: "Jane",
    surname: "Doe",
    id: "2",
    placement: 2,
    icon: "JD",
  },
  {
    forename: "Paul",
    surname: "Smith",
    id: "3",
    placement: 3,
    icon: "JD",
  },
];
const Leaderboard = () => {
  return (
    <Grid container direction="row" className={"leaderboard"}>
      {mockedLeaderBoard
        .sort((personA, personB) => sort(personA, personB))
        .map((person, index) => (
          <Grid
            key={index}
            container
            direction="row"
            className={"leaderboard-person"}>
            <div className={"placement-div"}>
              <EdisonTypography
                variant="t1"
                title={"#" + person.placement}
                disableUppercase={true}
              />
            </div>
            <PersonIcon />
            <EdisonTypography
              variant="t2"
              title={person.forename + " " + person.surname}
              disableUppercase={true}
            />
          </Grid>
        ))}
    </Grid>
  );
};
export default Leaderboard;

const sort = (personA: leaderBoardPerson, personB: leaderBoardPerson) =>
  personA.placement - personB.placement;
