import { Button, FormLabel, Grid } from "@mui/material";
import React, { FC, useCallback, useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";
import {
  addExpressionUnitToConditionBlock,
  addUnitToExpressionList,
  moveUnitInExpressionList,
  removeUnitFromExpressionList,
  selectCalculatedFieldsList,
  selectEditExpressionSet,
  selectExpressionBoxClicked,
  selectExpressionList,
  selectFieldToEdit,
  selectIsCondition,
  selectIsRetrieving,
  setConditionList,
  setEditExpressionSet,
  setExpressionBoxClicked,
  setExpressionList,
  setIsCondition,
  setIsRetrieving,
} from "../../features/fields/calculatedFieldSlice";
import {
  getIndividualFieldAsync,
  selectAllFieldsByType,
  selectAllNumberAndCalculatedFields,
} from "../../features/fields/fieldsSlice";
import {
  ExpressionUnit,
  fieldFormatList,
} from "../../../models/calculatedfield/calculatedfield.model";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { RootState } from "../../store/store";
import EdisonButton from "../../../app-shared/edison/button/EdisonButton";
import EdisonRadioGroupInput from "../../../app-shared/edison/radiogroupinput/EdisonRadioGroupInput";

import SearchableTabs, { SingleTab } from "../tabs/SearchableTabs";
import "./calculatedfieldinput.scss";
import ConditionExpression from "./condition/ConditionExpression";
import ExpressionsTab from "./tabs/ExpressionsTab";
import FieldsTab from "./tabs/FieldsTab";
import FieldsTabRow from "./tabs/FieldsTabRow";
import OperandOperator from "./units/OperatorOperand";
import getAvailableFields from "./utils/getAvailableFields";
import {
  transformConditionToFrontend,
  transformExpressionToFrontend,
} from "./utils/transform";

export interface CalculatedFieldInputProps {
  name: string;
  isEdit: boolean;
}

const CalculatedFieldInput: FC<CalculatedFieldInputProps> = ({
  isEdit,
  name,
}) => {
  const { t } = useTranslation(["admin"]);
  const dispatch = useAppDispatch();
  const [error, setError] = useState<string>("");

  const isCondition = useAppSelector(selectIsCondition);
  const fields = useAppSelector(selectAllNumberAndCalculatedFields);

  const expressionList = useAppSelector(selectExpressionList);
  const fieldToEdit = useAppSelector(selectFieldToEdit);
  const fieldsStatus = useAppSelector(
    (state: RootState) => state.fields.status
  );
  const isRetrieving = useAppSelector(selectIsRetrieving);
  const calculatedFieldList = useAppSelector(selectCalculatedFieldsList);
  const numberFields = useAppSelector((state) =>
    selectAllFieldsByType(state, "number")
  );
  const editExpressionSet = useAppSelector(selectEditExpressionSet);
  const expressionBoxClicked = useAppSelector(selectExpressionBoxClicked);

  useEffect(() => {
    if (
      !isRetrieving &&
      fields.filter((field) => field.dataType === "calculated").length !==
        calculatedFieldList.length &&
      fields.length > 0 &&
      fieldsStatus === "idle"
    ) {
      dispatch(setIsRetrieving(true));
      fields.forEach((field) => {
        if (field.dataType === "calculated") {
          dispatch(getIndividualFieldAsync(field.id));
        }
      });
    }
  }, [fields, fieldsStatus]);

  useEffect(() => {
    if (isEdit && !!fieldToEdit && fields.length > 0 && !editExpressionSet) {
      dispatch(setEditExpressionSet(true));
      if (
        fieldToEdit.configuration.calculatedExpression[0].expressionType ===
        "calc"
      ) {
        dispatch(
          setExpressionList(
            transformExpressionToFrontend(
              fieldToEdit.configuration.calculatedExpression[0],
              fields
            )
          )
        );
      } else {
        dispatch(
          setConditionList(
            transformConditionToFrontend(
              fieldToEdit.configuration.calculatedExpression,
              fields
            )
          )
        );
      }
    }
  }, [isEdit, fieldToEdit, fields]);

  const addExpressionUnit = (unitToAdd: ExpressionUnit) => {
    setError("");
    isCondition
      ? dispatch(addExpressionUnitToConditionBlock(unitToAdd))
      : dispatch(addUnitToExpressionList(unitToAdd));
  };

  const moveExpression = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      dispatch(
        moveUnitInExpressionList({
          dragIndex: dragIndex,
          hoverIndex: hoverIndex,
        })
      );
    },
    [expressionList]
  );

  const defaultTabs: SingleTab[] = [
    {
      label: t("fieldsLower"),
      children: (
        <FieldsTab
          addExpressionUnit={addExpressionUnit}
          data={getAvailableFields(
            calculatedFieldList,
            numberFields,
            fieldToEdit,
            isEdit
          )}
        />
      ),
    },
    {
      label: t("operators"),
      children: (
        <ExpressionsTab
          isCondition={isCondition}
          addExpressionUnit={addExpressionUnit}
        />
      ),
    },
    {
      label: t("condition"),
      children: (
        <FieldsTabRow
          isDisabled={false}
          fieldName={t("if").toUpperCase()}
          onClick={() => dispatch(setIsCondition(!isCondition))}
        />
      ),
    },
  ];
  return (
    <DndProvider backend={HTML5Backend}>
      <Grid container direction="column" className="calculated-field-container">
        <Grid item>
          {(!isEdit || fieldToEdit) && (
            <EdisonRadioGroupInput
              title={t("format")}
              type="format"
              options={fieldFormatList}
              defaultValue={
                !!fieldToEdit
                  ? fieldToEdit.configuration.format
                  : fieldFormatList[0]
              }
              textData={{
                percentage: t("percentage"),
                text: t("text"),
                currency: t("currency"),
              }}
              horizontal={true}
            />
          )}
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={4} className="tabs-box">
            <SearchableTabs tabs={defaultTabs} tabsToSearch={[t("fields")]} />
          </Grid>
          <Grid
            item
            container
            direction="row"
            xs={8}
            justifyContent="space-between">
            <Grid item container className="expression-box-label-container">
              <Grid item className="title">
                <FormLabel component="legend">{t("expression")}</FormLabel>
              </Grid>
              <Grid item>
                <EdisonButton
                  onClick={() => dispatch(setIsCondition(false))}
                  name="Clear All"
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              className="expression-box"
              direction="row"
              onMouseDown={() =>
                dispatch(setExpressionBoxClicked(!expressionBoxClicked))
              }>
              {isCondition ? (
                <ConditionExpression />
              ) : (
                expressionList.map(
                  (expressionUnit: ExpressionUnit, index: number) => (
                    <OperandOperator
                      key={index}
                      expressionUnit={expressionUnit}
                      removeExpressionUnit={(indexToRemove: number) => {
                        dispatch(removeUnitFromExpressionList(indexToRemove));
                      }}
                      index={index}
                      id={index.toString()}
                      moveExpression={moveExpression}
                    />
                  )
                )
              )}
            </Grid>
            <Grid item className="error-box">
              <div hidden={error === ""}> {error}</div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DndProvider>
  );
};

export default CalculatedFieldInput;
