import { Action, ThunkAction } from "@reduxjs/toolkit";
import { store } from "../../store/store";
import counterReducer from "../features/example/counterSlice";
import calculatedFieldSlice from "../features/fields/calculatedFieldSlice";
import fieldsSlice from "../features/fields/fieldsSlice";
import multiChoiceFieldSlice from "../features/fields/multiChoiceSlice";
import formsSlice from "../features/forms/formsSlice";
import livepreviewSlice from "../features/livepreview/livepreviewSlice";

import tablesSlice from "../features/tables/tablesSlice";
import workflowSlice from "../features/workflow/workflowSlice";
import typesSlice from "../features/types/typesSlice";

export const adminReducers = {
  counter: counterReducer,
  fields: fieldsSlice,
  tables: tablesSlice,
  forms: formsSlice,
  calculatedField: calculatedFieldSlice,
  multiChoiceField: multiChoiceFieldSlice,
  livepreview: livepreviewSlice,
  workflow: workflowSlice,
  types: typesSlice,
};

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
