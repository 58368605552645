import { IconButton, Menu, MenuItem } from "@mui/material";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import React, { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import "./iconSelectMenu.scss";
import EdisonTypography from "../../app-shared/edison/typography/EdisonTypography";

const availableLanguages = [
  {
    id: "en",
    name: "English",
  },
  { id: "pt", name: "Portugues" },
];

const IconSelectMenu: FC = (): ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [t, i18n] = useTranslation("common");

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <>
      <IconButton
        size="small"
        color="inherit"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}>
        <LanguageOutlinedIcon fontSize="medium" />
      </IconButton>
      <Menu
        className={"language-menu"}
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {availableLanguages.map((language) => (
          <MenuItem
            key={language.id}
            onClick={() => changeLanguage(language.id)}>
            <EdisonTypography
              variant="t8"
              title={language.name}
              disableUppercase={true}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default IconSelectMenu;
