export default function formatModifiedDate(date: Date): string {
  return new Intl.DateTimeFormat(["en-GB"], {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  })
    .format(new Date(date))
    .replaceAll("/", " ");
}
