import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import EdisonFormInput from "../../app-shared/edison/forminput/EdisonFormInput";
import EdisonFormSwitch from "../../app-shared/edison/formswitch/EdisonFormSwitch";

interface Props {
  defaultConfiguration?: any;
}

export const ChoiceInputs: FC<Props> = ({ defaultConfiguration }) => {
  const { t } = useTranslation(["admin"]);
  return (
    <>
      <EdisonFormInput
        name="choice"
        label={t("choice")}
        defaultValue={!!defaultConfiguration ? defaultConfiguration.choice : ""}
        requiredMessage={t("choiceRequiredMessage")}
        multiline={true}
        rows={10}
        disabled={false}
        placeholder=" Enter Choice #1
        Enter Choice #2
        Enter Choice #3"
      />
      <EdisonFormSwitch
        name="isMultiChoice"
        label={t("multipleChoice")}
        isDisabled={false}
        defaultValue={
          !!defaultConfiguration ? defaultConfiguration.isMultiChoice : false
        }
      />
    </>
  );
};
