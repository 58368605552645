import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import EdisonButton from "../../app-shared/edison/button/EdisonButton";
import UserCheckBoxGroup from "../../app-shared/user/checkboxgroup/UserCheckBoxGroup";
import UserDatePicker from "../../app-shared/user/datepicker/UserDatePicker";
import UserImage from "../../app-shared/user/image/UserImage";
import UserNumberField from "../../app-shared/user/number/UserNumberField";
import UserRichTextField from "../../app-shared/user/richtext/UserRichTextField";
import UserSwitchField from "../../app-shared/user/switch/UserSwitchField";
import UserTextField from "../../app-shared/user/textfield/UserTextField";
import UserURLField from "../../app-shared/user/url/UserURLField";
import WrappedPeoplePicker from "../components/wrappedpeoplepicker/WrappedPeoplePicker";
import { Choice, SharedChoice } from "../models/common/MultiChoiceField.model";
import { currencyCodeToSymbol } from "./currencies";


const TypeToComponentMapper = (
  readOnly: boolean,
  component: any,
  value?: any,
  setValue?: any,
  useInternalState = true ///TODO Add logic depending on if we want to use internal state or not
): ReactNode => {
  const { t } = useTranslation(["admin"]);


  switch (component.dataType) {
    case "Number":
      return (
        <UserNumberField
          {...component.configuration}
          numberType={component.configuration.format}
          label={component.displayName}
          configuration={
            component.configuration.format === "currency"
              ? {
                  ...component.configuration,
                  currency: currencyCodeToSymbol(
                    component.configuration.currency
                  ),
                }
              : component.configuration
          }
          useInternalState={useInternalState}
          readOnly={readOnly}
          value={value ? value : 3}
        />
      );
    case "Date":
      return (
        <UserDatePicker
          {...component.configuration}
          name={component.displayName}
          label={component.displayName}
          useInternalState={useInternalState}
          readOnly={readOnly}
          value={value ? value : new Date()}
        />
      );
    case "People":
      return (
        <WrappedPeoplePicker
          readOnly={readOnly}
          component={component}
          useInternalState={useInternalState}
          value={value}
          setValue={setValue}
        />
      );

    case "MultiChoice":
      return (
        <UserCheckBoxGroup
          label={component.displayName}
          choices={parseMultiChoiceLayout(component.configuration.choiceList)}
          multiLevel={true}
          useInternalState={useInternalState}
          readOnly={readOnly}
          value={
            value
              ? value
              : [
                  parseMultiChoiceLayout(component.configuration.choiceList)[0]
                    .value,
                ]
          }
        />
      );
    case "RichText":
      return (
        <UserRichTextField
          {...component.configuration}
          name={component.displayName}
          label={component.displayName}
          useInternalState={useInternalState}
          initialValue={[
            {
              type: "paragraph",

              children: [{ text: "A line of text in a paragraph." }],
            },
          ]} //TODO: update initial value when getting data from backend
          readOnly={readOnly}
        />
      );
    case "Image":
      return (
        <UserImage
          {...component.configuration}
          name={component.displayName}
          label={component.displayName}
          useInternalState={useInternalState}
          readOnly={readOnly}
          value={
            value ? value : { preview: "https://via.placeholder.com/150/" }
          }
        />
      );
      case "Switch":
        return (
          <UserSwitchField
            {...component.configuration}
            name={component.displayName}
            label={component.displayName}
            useInternalState={useInternalState}
            readOnly={readOnly}
            value={value ? value : true}
          />
        );
      case "Url":
        return (
          <UserURLField
            {...component.configuration}
            name={component.displayName}
            label={component.displayName}
            useInternalState={useInternalState}
            readOnly={readOnly}
            isInTable={true}
            value={value ? value : { title: "google", url: "https://google.com" }}
          />
        );
        case "Button":
          return (
            <EdisonButton
              name={component.displayName}
            />
          );
          case "TextBox":
            return (
              <UserTextField
                {...component.configuration}
                name={component.name}
                label={component.displayName}
                useInternalState={useInternalState}
                readOnly={readOnly}
                value={value ? value : "Text field text"}
              />
            );
    case "Task":
      return (
        <div> task table</div>
        // <UserTaskTable
        //   isEditing={true}
        //   showCriticalPaths={true}
        //   showTimeline={false}
        //   showBoardView={false}
        //   taskViewText={t("taskView")}
        //   boardViewText={t("boardView")}
        //   undoText={t("undo")}
        //   redoText={t("redo")}
        //   zoomInText={t("zoomIn")}
        //   zoomOutText={t("zoomOut")}
        //   zoomToFitText={t("zoomToFit")}
        //   addCardText={t("addCard")}
        //   viewCardText={t("viewCard")}
        //   editCardText={t("editCard")}
        //   removeCardText={t("removeCard")}
        //   columns={[
        //     {
        //       name: "id",
        //       type: "number",
        //       label: "Id",
        //       disabled: true,
        //       hidden: false,
        //     },
        //     {
        //       name: "name",
        //       type: "text",
        //       label: "Name",
        //       disabled: false,
        //       required: true,
        //       requiredMessage: "Text Field is required.",
        //       hidden: false,
        //     },
        //     {
        //       name: "startDate",
        //       type: "date",
        //       label: "Start Date",
        //       disabled: false,
        //       required: true,
        //       requiredMessage: "Please select a date",
        //       invalidDateMessage: "Invalid date inputed",
        //       hidden: false,
        //     },
        //     {
        //       name: "endDate",
        //       type: "date",
        //       label: "End Date",
        //       disabled: false,
        //       required: true,
        //       requiredMessage: "Please select a date",
        //       invalidDateMessage: "Invalid date inputed",
        //       hidden: false,
        //     },
        //     {
        //       name: "duration",
        //       type: "number",
        //       label: "Duration",
        //       disabled: false,
        //       hidden: false,
        //     },
        //     {
        //       name: "percentDone",
        //       type: "number",
        //       label: "% Complete",
        //       disabled: false,
        //       hidden: false,
        //     },
        //     {
        //       name: "wbs",
        //       type: "number",
        //       label: "Wbs",
        //       disabled: false,
        //       hidden: false,
        //     },
        //     {
        //       name: "effort",
        //       type: "number",
        //       label: "Effort",
        //       disabled: false,
        //       hidden: false,
        //     },
        //     {
        //       name: "predecessor",
        //       label: "Predecessor",
        //       disabled: false,
        //       hidden: false,
        //     },
        //     {
        //       name: "successor",
        //       label: "Successor",
        //       disabled: false,
        //       hidden: false,
        //     },
        //     {
        //       name: "schedulingmodecolumn",
        //       label: "Scheduling Mode",
        //       disabled: false,
        //       hidden: false,
        //     },
        //     {
        //       name: "constrainttype",
        //       label: "Constrain Type",
        //       disabled: false,
        //       hidden: false,
        //     },
        //     {
        //       name: "constraintdate",
        //       label: "Constraint Date",
        //       disabled: false,
        //       hidden: false,
        //     },
        //     {
        //       name: "manuallyscheduled",
        //       label: "Manually Scheduled",
        //       type: "boolean",
        //       disabled: false,
        //       hidden: false,
        //     },
        //   ]}
        //   data={[
        //     {
        //       id: 1,
        //       name: "Setup web server",
        //       percentDone: 25,
        //       duration: 9,
        //       startDate: "2021-01-14",
        //       rollup: true,
        //       endDate: "2021-01-23",
        //       customField: "T 1",
        //       deadlinedate: "2021-01-25",
        //       showInTimeline: true,
        //       status: "inprogress",
        //     },
        //     {
        //       id: 2,
        //       name: "Configure firewall",
        //       percentDone: 50,
        //       startDate: "2021-01-14",
        //       rollup: true,
        //       duration: 3,
        //       endDate: "2021-01-17",
        //       deadlineDate: "2021-01-19",
        //       customField: "T 2",
        //       showInTimeline: true,
        //       status: "done",
        //     },
        //     {
        //       id: 3,
        //       name: "Setup load balancer",
        //       percentDone: 75,
        //       startDate: "2021-01-14",
        //       rollup: true,
        //       duration: 3,
        //       endDate: "2021-01-17",
        //       customField: "T 3",
        //       showInTimeline: true,
        //       status: "done",
        //     },
        //     {
        //       id: 4,
        //       name: "Run tests",
        //       percentDone: 100,
        //       startDate: "2021-01-23",
        //       rollup: true,
        //       duration: 21,
        //       endDate: "2021-02-13",
        //       customField: "T 4",
        //       showInTimeline: true,
        //       status: "inprogress",
        //       children: [
        //         {
        //           id: 5,
        //           name: "Website Design",
        //           percentDone: 100,
        //           startDate: "2021-01-23",
        //           rollup: true,
        //           duration: 21,
        //           endDate: "2021-02-13",
        //           customField: "T 5",
        //           status: "todo",
        //         },
        //       ],
        //     },
        //     {
        //       id: 6,
        //       name: "Inform management about decision",
        //       percentDone: 0,
        //       startDate: "2021-01-14",
        //       rollup: true,
        //       duration: 2,
        //       endDate: "2021-01-16",
        //       cost: 5000,
        //       status: "inprogress",
        //       baselines: [
        //         { startDate: "2021-01-14", endDate: "2021-01-15" },
        //         { startDate: "2021-01-14", endDate: "2021-01-15" },
        //       ],
        //     },
        //     {
        //       id: 7,
        //       name: "Website Design 11",
        //       percentDone: 100,
        //       startDate: "2021-01-23",
        //       rollup: true,
        //       duration: 21,
        //       endDate: "2021-02-13",
        //       customField: "T 1",
        //       inactive: true,
        //       status: "todo",
        //     },
        //   ]}
        //   dependenciesData={[{ id: 1, fromTask: 2, toTask: 3 }]}
        // />
      );

    default:
      return <div> not currently implemented</div>;
  }
};

const parseMultiChoiceLayout = (choiceList: Choice[]): SharedChoice[] => {
  return choiceList
    .filter((choice) => choice.path.length === 1)
    .map((choice) => ({
      label: choice.label,
      value: choice.path,
      children: recurseGetChildren(choiceList, choice.path),
    }));
};
const recurseGetChildren = (
  choiceList: Choice[],
  parentPath: string
): SharedChoice[] => {
  const directChildren = choiceList.filter(
    (choice) =>
      choice.path.startsWith(parentPath) &&
      choice.path !== parentPath &&
      choice.path.length === parentPath.length + 2
  );
  if (directChildren.length === 0) {
    return [];
  }
  return directChildren.map((choice) => ({
    label: choice.label,
    value: choice.path,
    children: recurseGetChildren(choiceList, choice.path),
  }));
};

export default TypeToComponentMapper;
