import { Paper, Stack } from "@mui/material";
import React, { FC } from "react";
import "./projecttypecard.scss"
export interface ProjectTypeCardProps {
  projectType: any;
}
const ProjectTypeCard: FC<ProjectTypeCardProps> = ({ projectType }) => {
  return (
    <Paper className="project-type-card-root">
      <Stack spacing={1}>
        <img
          src={projectType.imageUrl}
          className="project-type-image"></img>
        <strong> {projectType.displayName}</strong>
        <div> {projectType.description}</div>
      </Stack>
    </Paper>
  );
};

export default ProjectTypeCard;
