import { Grid } from "@mui/material";
import React from "react";
import PersonIcon from "@mui/icons-material/Person";
import Person from "../../models/Person.model";
import "./recentActivity.scss";
import EdisonTypography from "../../app-shared/edison/typography/EdisonTypography";

interface recentActivity {
  person: Person;
  activity: string;
}

const mockedRecentActivity: recentActivity[] = [
  {
    person: {
      forename: "John",
      surname: "Doe",
      id: "1",
      icon: "JD",
    },
    activity:
      "Fixed bug. Lorem ipsum dolor sit amet, consectetur adipiscing elit.  ",
  },
  {
    person: {
      forename: "Jane",
      surname: "Doe",
      id: "2",
      icon: "JD",
    },
    activity: "Deployed new solution. Mauris tempor finibus faucibus.  ",
  },
  {
    person: {
      forename: "Paul",
      surname: "Smith",
      id: "2",
      icon: "PS",
    },
    activity: "Started new sprint. Suspendisse semper ornare ultrices. ",
  },
];
export const RecentActivity = () => {
  return (
    <div>
      {mockedRecentActivity.map((activity, index) => (
        <Grid
          container
          key={index}
          direction="row"
          className={"activity-block"}>
          <PersonIcon />
          <EdisonTypography
            variant="t2"
            title={activity.person.forename + " " + activity.person.surname}
            disableUppercase={true}
          />
          <div className={"text-block"}>
            <EdisonTypography
              variant="t8"
              title={activity.activity}
              disableUppercase={true}
            />
          </div>
        </Grid>
      ))}
    </div>
  );
};
