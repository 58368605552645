
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import React from "react";

import Projects from "../pages/projects/Projects";
import ExploreIcon from '@mui/icons-material/Explore';
import TaskIcon from '@mui/icons-material/Task';
import ViewProject from "../pages/viewproject/ViewProject";
import RouteItem from "../../models/RouteItem.model";



const projectRoutes: Array<RouteItem> = [

  
    {
      key: "projectsHome",
      path: "/projects/home",
      enabled: true,
      component: Projects,
      icon: <SettingsApplicationsIcon/>,//TODO => Get correct icon
      appendDivider: true,
      description: "projects home page",
    },
    {
      key: "projectsDicover",
      path: "/projects/discover",
      enabled: true,
      component: Projects,
      icon: <ExploreIcon/>,
      appendDivider: true,
      description: "discover projects page",
    },
    {
      key: "projectView",
      path: "/projects/viewproject",
      enabled: true,
      component: ViewProject,
      icon: <TaskIcon/>,
      appendDivider: true,
      description: "view single projects page",
    },

]
export default projectRoutes