// icons
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import StorageOutlinedIcon from "@mui/icons-material/StorageOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import ViewColumnOutlinedIcon from "@mui/icons-material/ViewColumnOutlined";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import DialpadOutlinedIcon from "@mui/icons-material/DialpadOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import WorkOutlinedIcon from "@mui/icons-material/WorkOutlined";
import SubjectOutlinedIcon from "@mui/icons-material/SubjectOutlined";
import BrushOutlinedIcon from "@mui/icons-material/BrushOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PlaylistAddCheckOutlinedIcon from "@mui/icons-material/PlaylistAddCheckOutlined";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";

// components
import Fields from "../pages/fields/Fields";
import RouteItem from "../../models/RouteItem.model";
import React from "react";
import NewField from "../pages/newfield/NewField";
import Buttons from "../pages/buttons/Buttons";
import Tables from "../pages/tables/Tables";
import NewButton from "../pages/newbutton/NewButton";
import NewTable from "../pages/newtable/NewTable";
import FormDesigner from "../pages/formdesigner/FormDesigner";
import CardDesigner from "../pages/carddesigner/CardDesigner";
import { Forms } from "../pages/forms/Forms";
import WorkflowDesigner from "../pages/workflowdesigner/WorkflowDesigner";
import TypesPage from "../pages/types/TypesPage";

//TODO: create pages for all this
// const Fields: React.FC = () => <div>fields</div>;
// const NewField: React.FC = () => <div>new field</div>;
// const Buttons: React.FC = () => <div>types</div>;
const News: React.FC = () => <div>news</div>;
// const Tables: React.FC = () => <div>theme</div>;
// const NewButton: React.FC = () => <div>notifications</div>;
// const NewTable: React.FC = () => <div>pwa</div>;
// const FormDesigner: React.FC = () => <div>product links</div>;
// const CardDesigner: React.FC = () => <div>teams</div>;
const Yammer: React.FC = () => <div>yammer</div>;
const Reports: React.FC = () => <div>reports</div>;
// const WorkflowDesigner: React.FC = () => <div>customer info</div>;
// const TypesPage: React.FC = () => <div>customer info</div>;
// const Forms: React.FC = () => <div>customer info</div>;

// define app routes
export const routes: Array<RouteItem> = [
  // ADMIN FIELDS - START
  // Commented unecessary routes, reffering to BUG 3837 - might be needed in the future again

  // {
  //   key: "globalusers",
  //   path: "/admin/globalusers",
  //   enabled: true,
  //   component: GlobalUsers,
  //   icon: GroupOutlinedIcon,
  //   appendDivider: true,
  //   isAdminOption: true,
  // },

  {
    key: "fields",
    path: "/admin/fields",
    enabled: true,
    component: Fields,
    icon: <ViewColumnOutlinedIcon color="primary" />,
    appendDivider: true,
    isAdminOption: true,
    description: "fieldsDescription",
  },
  {
    key: "tables",
    path: "/admin/tables",
    enabled: true,
    component: Tables,
    icon: <TableChartOutlinedIcon color="primary" />,
    appendDivider: true,
    isAdminOption: true,
    description: "tablesDescription",
  },
  {
    key: "buttons",
    path: "/admin/buttons",
    enabled: true,
    component: Buttons,
    icon: <DialpadOutlinedIcon color="primary" />,
    appendDivider: true,
    isAdminOption: true,
    description: "buttonsDescription",
  },
  {
    key: "forms",
    path: "/admin/forms",
    enabled: true,
    component: Forms,
    icon: <DescriptionOutlinedIcon color="primary" />,
    appendDivider: true,
    isAdminOption: true,
    description: "formsDescription",
  },
  {
    key: "formDesigner",
    path: "/admin/forms/formDesigner",
    enabled: true,
    component: FormDesigner,
    icon: <DescriptionOutlinedIcon color="primary" />,
    appendDivider: true,
    isAdminOption: true,
    description: "formDesignerDescription",
  },
  {
    key: "types",
    path: "/admin/types",
    enabled: true,
    component: TypesPage,
    icon: <WorkOutlinedIcon color="primary" />,
    appendDivider: true,
    isAdminOption: true,
    description: "typesDescription",
  },
  {
    key: "news",
    path: "/admin/news",
    enabled: true,
    component: News,
    icon: <SubjectOutlinedIcon color="primary" />,
    appendDivider: true,
    isAdminOption: true,
    description: "newsDescription",
  },
  {
    key: "workflowDesigner",
    path: "/admin/workflowDesigner",
    enabled: true,
    component: WorkflowDesigner,
    icon: <SubjectOutlinedIcon color="primary" />,
    appendDivider: true,
    isAdminOption: true,
    description: "workflowDescription",
  },
  // {
  //   key: "theme",
  //   path: "/admin/theme",
  //   enabled: true,
  //   component: Theme,
  //   icon: BrushOutlinedIcon,
  //   appendDivider: true,
  //   isAdminOption: true,
  // },
  // {
  //   key: "notifications",
  //   path: "/admin/notifications",
  //   enabled: true,
  //   component: Notifications,
  //   icon: EmailOutlinedIcon,
  //   appendDivider: true,
  //   isAdminOption: true,
  // },
  // {
  //   key: "pwa",
  //   path: "/admin/pwa",
  //   enabled: true,
  //   component: PWA,
  //   icon: PlaylistAddCheckOutlinedIcon,
  //   appendDivider: true,
  //   isAdminOption: true,
  // },
  // {
  //   key: "productlinks",
  //   path: "/admin/productlinks",
  //   enabled: true,
  //   component: ProductLinks,
  //   icon: LinkOutlinedIcon,
  //   appendDivider: true,
  //   isAdminOption: true,
  // },
  // {
  //   key: "teams",
  //   path: "/admin/teams",
  //   enabled: true,
  //   component: Teams,
  //   icon: StorageOutlinedIcon,
  //   appendDivider: true,
  //   isAdminOption: true,
  // },
  {
    key: "yammer",
    path: "/admin/yammer",
    enabled: true,
    component: Yammer,
    icon: <StorageOutlinedIcon color="primary" />,
    appendDivider: true,
    isAdminOption: true,
    description: "yammerDescription",
  },
  {
    key: "reports",
    path: "/admin/reports",
    enabled: true,
    component: Reports,
    icon: <AssessmentOutlinedIcon color="primary" />,
    appendDivider: true,
    isAdminOption: true,
    description: "reportsDescription",
  },
  // {
  //   key: "customerinfo",
  //   path: "/admin/customerinfo",
  //   enabled: true,
  //   component: CustomerInfo,
  //   icon: ContactsOutlinedIcon,
  //   appendDivider: true,
  //   isAdminOption: true,
  // },
  {
    key: "cardDesigner",
    path: "/admin/cardDesigner",
    enabled: true,
    component: CardDesigner,
    icon: <DashboardOutlinedIcon color="primary" />,
    appendDivider: true,
    isAdminOption: true,
    description: "cardDesignerDescription",
  },
  // {
  //   key: "database",
  //   path: "/admin/database",
  //   enabled: true,
  //   component: Database,
  //   icon: StorageOutlinedIcon,
  //   appendDivider: true,
  //   isAdminOption: true,
  //   description: "databaseDescription",
  // },

  // ADMIN FIELDS - END
  {
    key: "newfield",
    path: "/admin/fields/newfield",
    enabled: true,
    component: NewField,
    icon: <SettingsApplicationsIcon color="primary" />,
    appendDivider: true,
    isAdminOption: false,
    description: "newFieldDescription",
  },
  {
    key: "newbutton",
    path: "/admin/buttons/newbutton",
    enabled: true,
    component: NewButton,
    icon: <SettingsApplicationsIcon color="primary" />,
    appendDivider: true,
    isAdminOption: false,
    description: "newButtonDescription",
  },
  {
    key: "editbutton",
    path: "/admin/buttons/editbutton",
    enabled: true,
    component: NewButton,
    icon: <SettingsApplicationsIcon color="primary" />,
    appendDivider: true,
    isAdminOption: false,
    description: "editButtonDescription",
  },
  {
    key: "editfield",
    path: "/admin/fields/editfield",
    enabled: true,
    component: NewField,
    icon: <SettingsApplicationsIcon color="primary" />,
    appendDivider: true,
    isAdminOption: false,
    description: "editFieldDescription",
  },
  {
    key: "newtable",
    path: "/admin/tables/newtable",
    enabled: true,
    component: NewTable,
    icon: <SettingsApplicationsIcon color="primary" />,
    appendDivider: true,
    isAdminOption: false,
    description: "newTableDescription",
  },
  {
    key: "edittable",
    path: "/admin/tables/edittable",
    enabled: true,
    component: NewTable,
    icon: <SettingsApplicationsIcon color="primary" />,
    appendDivider: true,
    isAdminOption: false,
    description: "editTableDescription",
  },
];
