export interface Currency {
  currencyName: string;
  currencyNumericCode: number;
  currencyMinorUnits: number | string;
  currencySymbol: string;
}

export const currencyCodeToSymbol=(code: number): string=>{
  const currency = Object.values(currencies)[code]

  return currency? currency.currencySymbol: ""
}
export const currencies = {
  AFN: {
    currencyName: "Afghani",
    currencyNumericCode: 971,
    currencyMinorUnits: 2,
    currencySymbol: "AFN",
  },
  EUR: {
    currencyName: "Euro",
    currencyNumericCode: 978,
    currencyMinorUnits: 2,
    currencySymbol: "€",
  },
  ALL: {
    currencyName: "Lek",
    currencyNumericCode: 8,
    currencyMinorUnits: 2,
    currencySymbol: "ALL",
  },
  DZD: {
    currencyName: "Algerian Dinar",
    currencyNumericCode: 12,
    currencyMinorUnits: 2,
    currencySymbol: "DZD",
  },
  USD: {
    currencyName: "US Dollar",
    currencyNumericCode: 840,
    currencyMinorUnits: 2,
    currencySymbol: "$",
  },
  AOA: {
    currencyName: "Kwanza",
    currencyNumericCode: 973,
    currencyMinorUnits: 2,
    currencySymbol: "AOA",
  },
  XCD: {
    currencyName: "East Caribbean Dollar",
    currencyNumericCode: 951,
    currencyMinorUnits: 2,
    currencySymbol: "EC$",
  },
  ARS: {
    currencyName: "Argentine Peso",
    currencyNumericCode: 32,
    currencyMinorUnits: 2,
    currencySymbol: "ARS",
  },
  AMD: {
    currencyName: "Armenian Dram",
    currencyNumericCode: 51,
    currencyMinorUnits: 2,
    currencySymbol: "AMD",
  },
  AWG: {
    currencyName: "Aruban Florin",
    currencyNumericCode: 533,
    currencyMinorUnits: 2,
    currencySymbol: "AWG",
  },
  AUD: {
    currencyName: "Australian Dollar",
    currencyNumericCode: 36,
    currencyMinorUnits: 2,
    currencySymbol: "A$",
  },
  AZN: {
    currencyName: "Azerbaijan Manat",
    currencyNumericCode: 944,
    currencyMinorUnits: 2,
    currencySymbol: "AZN",
  },
  BSD: {
    currencyName: "Bahamian Dollar",
    currencyNumericCode: 44,
    currencyMinorUnits: 2,
    currencySymbol: "BSD",
  },
  BHD: {
    currencyName: "Bahraini Dinar",
    currencyNumericCode: 48,
    currencyMinorUnits: 3,
    currencySymbol: "BHD",
  },
  BDT: {
    currencyName: "Taka",
    currencyNumericCode: 50,
    currencyMinorUnits: 2,
    currencySymbol: "BDT",
  },
  BBD: {
    currencyName: "Barbados Dollar",
    currencyNumericCode: 52,
    currencyMinorUnits: 2,
    currencySymbol: "BBD",
  },
  BYN: {
    currencyName: "Belarusian Ruble",
    currencyNumericCode: 933,
    currencyMinorUnits: 2,
    currencySymbol: "BYN",
  },
  BZD: {
    currencyName: "Belize Dollar",
    currencyNumericCode: 84,
    currencyMinorUnits: 2,
    currencySymbol: "BZD",
  },
  XOF: {
    currencyName: "CFA Franc BCEAO",
    currencyNumericCode: 952,
    currencyMinorUnits: 0,
    currencySymbol: "CFA",
  },
  BMD: {
    currencyName: "Bermudian Dollar",
    currencyNumericCode: 60,
    currencyMinorUnits: 2,
    currencySymbol: "BMD",
  },
  INR: {
    currencyName: "Indian Rupee",
    currencyNumericCode: 356,
    currencyMinorUnits: 2,
    currencySymbol: "₹",
  },
  BTN: {
    currencyName: "Ngultrum",
    currencyNumericCode: 64,
    currencyMinorUnits: 2,
    currencySymbol: "BTN",
  },
  BOB: {
    currencyName: "Boliviano",
    currencyNumericCode: 68,
    currencyMinorUnits: 2,
    currencySymbol: "BOB",
  },
  BAM: {
    currencyName: "Convertible Mark",
    currencyNumericCode: 977,
    currencyMinorUnits: 2,
    currencySymbol: "BAM",
  },
  BWP: {
    currencyName: "Pula",
    currencyNumericCode: 72,
    currencyMinorUnits: 2,
    currencySymbol: "BWP",
  },
  NOK: {
    currencyName: "Norwegian Krone",
    currencyNumericCode: 578,
    currencyMinorUnits: 2,
    currencySymbol: "NOK",
  },
  BRL: {
    currencyName: "Brazilian Real",
    currencyNumericCode: 986,
    currencyMinorUnits: 2,
    currencySymbol: "R$",
  },
  BND: {
    currencyName: "Brunei Dollar",
    currencyNumericCode: 96,
    currencyMinorUnits: 2,
    currencySymbol: "BND",
  },
  BGN: {
    currencyName: "Bulgarian Lev",
    currencyNumericCode: 975,
    currencyMinorUnits: 2,
    currencySymbol: "BGN",
  },
  BIF: {
    currencyName: "Burundi Franc",
    currencyNumericCode: 108,
    currencyMinorUnits: 0,
    currencySymbol: "BIF",
  },
  CVE: {
    currencyName: "Cabo Verde Escudo",
    currencyNumericCode: 132,
    currencyMinorUnits: 2,
    currencySymbol: "CVE",
  },
  KHR: {
    currencyName: "Riel",
    currencyNumericCode: 116,
    currencyMinorUnits: 2,
    currencySymbol: "KHR",
  },
  XAF: {
    currencyName: "CFA Franc BEAC",
    currencyNumericCode: 950,
    currencyMinorUnits: 0,
    currencySymbol: "FCFA",
  },
  CAD: {
    currencyName: "Canadian Dollar",
    currencyNumericCode: 124,
    currencyMinorUnits: 2,
    currencySymbol: "CA$",
  },
  KYD: {
    currencyName: "Cayman Islands Dollar",
    currencyNumericCode: 136,
    currencyMinorUnits: 2,
    currencySymbol: "KYD",
  },
  CLP: {
    currencyName: "Chilean Peso",
    currencyNumericCode: 152,
    currencyMinorUnits: 0,
    currencySymbol: "CLP",
  },
  CNY: {
    currencyName: "Yuan Renminbi",
    currencyNumericCode: 156,
    currencyMinorUnits: 2,
    currencySymbol: "CN¥",
  },
  COP: {
    currencyName: "Colombian Peso",
    currencyNumericCode: 170,
    currencyMinorUnits: 2,
    currencySymbol: "COP",
  },
  KMF: {
    currencyName: "Comorian Franc ",
    currencyNumericCode: 174,
    currencyMinorUnits: 0,
    currencySymbol: "KMF",
  },
  CDF: {
    currencyName: "Congolese Franc",
    currencyNumericCode: 976,
    currencyMinorUnits: 2,
    currencySymbol: "CDF",
  },
  NZD: {
    currencyName: "New Zealand Dollar",
    currencyNumericCode: 554,
    currencyMinorUnits: 2,
    currencySymbol: "NZ$",
  },
  CRC: {
    currencyName: "Costa Rican Colon",
    currencyNumericCode: 188,
    currencyMinorUnits: 2,
    currencySymbol: "CRC",
  },
  HRK: {
    currencyName: "Kuna",
    currencyNumericCode: 191,
    currencyMinorUnits: 2,
    currencySymbol: "HRK",
  },
  CUP: {
    currencyName: "Cuban Peso",
    currencyNumericCode: 192,
    currencyMinorUnits: 2,
    currencySymbol: "CUP",
  },
  CUC: {
    currencyName: "Peso Convertible",
    currencyNumericCode: 931,
    currencyMinorUnits: 2,
    currencySymbol: "CUC",
  },
  ANG: {
    currencyName: "Netherlands Antillean Guilder",
    currencyNumericCode: 532,
    currencyMinorUnits: 2,
    currencySymbol: "ANG",
  },
  CZK: {
    currencyName: "Czech Koruna",
    currencyNumericCode: 203,
    currencyMinorUnits: 2,
    currencySymbol: "CZK",
  },
  DKK: {
    currencyName: "Danish Krone",
    currencyNumericCode: 208,
    currencyMinorUnits: 2,
    currencySymbol: "DKK",
  },
  DJF: {
    currencyName: "Djibouti Franc",
    currencyNumericCode: 262,
    currencyMinorUnits: 0,
    currencySymbol: "DJF",
  },
  DOP: {
    currencyName: "Dominican Peso",
    currencyNumericCode: 214,
    currencyMinorUnits: 2,
    currencySymbol: "DOP",
  },
  EGP: {
    currencyName: "Egyptian Pound",
    currencyNumericCode: 818,
    currencyMinorUnits: 2,
    currencySymbol: "EGP",
  },
  SVC: {
    currencyName: "El Salvador Colon",
    currencyNumericCode: 222,
    currencyMinorUnits: 2,
    currencySymbol: "SVC",
  },
  ERN: {
    currencyName: "Nakfa",
    currencyNumericCode: 232,
    currencyMinorUnits: 2,
    currencySymbol: "ERN",
  },
  ETB: {
    currencyName: "Ethiopian Birr",
    currencyNumericCode: 230,
    currencyMinorUnits: 2,
    currencySymbol: "ETB",
  },
  FKP: {
    currencyName: "Falkland Islands Pound",
    currencyNumericCode: 238,
    currencyMinorUnits: 2,
    currencySymbol: "FKP",
  },
  FJD: {
    currencyName: "Fiji Dollar",
    currencyNumericCode: 242,
    currencyMinorUnits: 2,
    currencySymbol: "FJD",
  },
  XPF: {
    currencyName: "CFP Franc",
    currencyNumericCode: 953,
    currencyMinorUnits: 0,
    currencySymbol: "CFPF",
  },
  GMD: {
    currencyName: "Dalasi",
    currencyNumericCode: 270,
    currencyMinorUnits: 2,
    currencySymbol: "GMD",
  },
  GEL: {
    currencyName: "Lari",
    currencyNumericCode: 981,
    currencyMinorUnits: 2,
    currencySymbol: "GEL",
  },
  GHS: {
    currencyName: "Ghana Cedi",
    currencyNumericCode: 936,
    currencyMinorUnits: 2,
    currencySymbol: "GHS",
  },
  GIP: {
    currencyName: "Gibraltar Pound",
    currencyNumericCode: 292,
    currencyMinorUnits: 2,
    currencySymbol: "GIP",
  },
  GTQ: {
    currencyName: "Quetzal",
    currencyNumericCode: 320,
    currencyMinorUnits: 2,
    currencySymbol: "GTQ",
  },
  GBP: {
    currencyName: "Pound Sterling",
    currencyNumericCode: 826,
    currencyMinorUnits: 2,
    currencySymbol: "£",
  },
  GNF: {
    currencyName: "Guinean Franc",
    currencyNumericCode: 324,
    currencyMinorUnits: 0,
    currencySymbol: "GNF",
  },
  GYD: {
    currencyName: "Guyana Dollar",
    currencyNumericCode: 328,
    currencyMinorUnits: 2,
    currencySymbol: "GYD",
  },
  HTG: {
    currencyName: "Gourde",
    currencyNumericCode: 332,
    currencyMinorUnits: 2,
    currencySymbol: "HTG",
  },
  HNL: {
    currencyName: "Lempira",
    currencyNumericCode: 340,
    currencyMinorUnits: 2,
    currencySymbol: "HNL",
  },
  HKD: {
    currencyName: "Hong Kong Dollar",
    currencyNumericCode: 344,
    currencyMinorUnits: 2,
    currencySymbol: "HK$",
  },
  HUF: {
    currencyName: "Forint",
    currencyNumericCode: 348,
    currencyMinorUnits: 2,
    currencySymbol: "HUF",
  },
  ISK: {
    currencyName: "Iceland Krona",
    currencyNumericCode: 352,
    currencyMinorUnits: 0,
    currencySymbol: "ISK",
  },
  IDR: {
    currencyName: "Rupiah",
    currencyNumericCode: 360,
    currencyMinorUnits: 2,
    currencySymbol: "IDR",
  },
  XDR: {
    currencyName: "SDR (Special Drawing Right)",
    currencyNumericCode: 960,
    currencyMinorUnits: "N.A.",
    currencySymbol: "XDR",
  },
  IRR: {
    currencyName: "Iranian Rial",
    currencyNumericCode: 364,
    currencyMinorUnits: 2,
    currencySymbol: "IRR",
  },
  IQD: {
    currencyName: "Iraqi Dinar",
    currencyNumericCode: 368,
    currencyMinorUnits: 3,
    currencySymbol: "IQD",
  },
  ILS: {
    currencyName: "New Israeli Sheqel",
    currencyNumericCode: 376,
    currencyMinorUnits: 2,
    currencySymbol: "₪",
  },
  JMD: {
    currencyName: "Jamaican Dollar",
    currencyNumericCode: 388,
    currencyMinorUnits: 2,
    currencySymbol: "JMD",
  },
  JPY: {
    currencyName: "Yen",
    currencyNumericCode: 392,
    currencyMinorUnits: 0,
    currencySymbol: "¥",
  },
  JOD: {
    currencyName: "Jordanian Dinar",
    currencyNumericCode: 400,
    currencyMinorUnits: 3,
    currencySymbol: "JOD",
  },
  KZT: {
    currencyName: "Tenge",
    currencyNumericCode: 398,
    currencyMinorUnits: 2,
    currencySymbol: "KZT",
  },
  KES: {
    currencyName: "Kenyan Shilling",
    currencyNumericCode: 404,
    currencyMinorUnits: 2,
    currencySymbol: "KES",
  },
  KPW: {
    currencyName: "North Korean Won",
    currencyNumericCode: 408,
    currencyMinorUnits: 2,
    currencySymbol: "KPW",
  },
  KRW: {
    currencyName: "Won",
    currencyNumericCode: 410,
    currencyMinorUnits: 0,
    currencySymbol: "₩",
  },
  KWD: {
    currencyName: "Kuwaiti Dinar",
    currencyNumericCode: 414,
    currencyMinorUnits: 3,
    currencySymbol: "KWD",
  },
  KGS: {
    currencyName: "Som",
    currencyNumericCode: 417,
    currencyMinorUnits: 2,
    currencySymbol: "KGS",
  },
  LAK: {
    currencyName: "Lao Kip",
    currencyNumericCode: 418,
    currencyMinorUnits: 2,
    currencySymbol: "LAK",
  },
  LBP: {
    currencyName: "Lebanese Pound",
    currencyNumericCode: 422,
    currencyMinorUnits: 2,
    currencySymbol: "LBP",
  },
  LSL: {
    currencyName: "Loti",
    currencyNumericCode: 426,
    currencyMinorUnits: 2,
    currencySymbol: "LSL",
  },
  ZAR: {
    currencyName: "Rand",
    currencyNumericCode: 710,
    currencyMinorUnits: 2,
    currencySymbol: "ZAR",
  },
  LRD: {
    currencyName: "Liberian Dollar",
    currencyNumericCode: 430,
    currencyMinorUnits: 2,
    currencySymbol: "LRD",
  },
  LYD: {
    currencyName: "Libyan Dinar",
    currencyNumericCode: 434,
    currencyMinorUnits: 3,
    currencySymbol: "LYD",
  },
  CHF: {
    currencyName: "Swiss Franc",
    currencyNumericCode: 756,
    currencyMinorUnits: 2,
    currencySymbol: "CHF",
  },
  MOP: {
    currencyName: "Pataca",
    currencyNumericCode: 446,
    currencyMinorUnits: 2,
    currencySymbol: "MOP",
  },
  MKD: {
    currencyName: "Denar",
    currencyNumericCode: 807,
    currencyMinorUnits: 2,
    currencySymbol: "MKD",
  },
  MGA: {
    currencyName: "Malagasy Ariary",
    currencyNumericCode: 969,
    currencyMinorUnits: 2,
    currencySymbol: "MGA",
  },
  MWK: {
    currencyName: "Malawi Kwacha",
    currencyNumericCode: 454,
    currencyMinorUnits: 2,
    currencySymbol: "MWK",
  },
  MYR: {
    currencyName: "Malaysian Ringgit",
    currencyNumericCode: 458,
    currencyMinorUnits: 2,
    currencySymbol: "MYR",
  },
  MVR: {
    currencyName: "Rufiyaa",
    currencyNumericCode: 462,
    currencyMinorUnits: 2,
    currencySymbol: "MVR",
  },
  MRU: {
    currencyName: "Ouguiya",
    currencyNumericCode: 929,
    currencyMinorUnits: 2,
    currencySymbol: "MRU",
  },
  MUR: {
    currencyName: "Mauritius Rupee",
    currencyNumericCode: 480,
    currencyMinorUnits: 2,
    currencySymbol: "MUR",
  },
  XUA: {
    currencyName: "ADB Unit of Account",
    currencyNumericCode: 965,
    currencyMinorUnits: "N.A.",
    currencySymbol: "XUA",
  },
  MXN: {
    currencyName: "Mexican Peso",
    currencyNumericCode: 484,
    currencyMinorUnits: 2,
    currencySymbol: "MX$",
  },
  MDL: {
    currencyName: "Moldovan Leu",
    currencyNumericCode: 498,
    currencyMinorUnits: 2,
    currencySymbol: "MDL",
  },
  MNT: {
    currencyName: "Tugrik",
    currencyNumericCode: 496,
    currencyMinorUnits: 2,
    currencySymbol: "MNT",
  },
  MAD: {
    currencyName: "Moroccan Dirham",
    currencyNumericCode: 504,
    currencyMinorUnits: 2,
    currencySymbol: "MAD",
  },
  MZN: {
    currencyName: "Mozambique Metical",
    currencyNumericCode: 943,
    currencyMinorUnits: 2,
    currencySymbol: "MZN",
  },
  MMK: {
    currencyName: "Kyat",
    currencyNumericCode: 104,
    currencyMinorUnits: 2,
    currencySymbol: "MMK",
  },
  NAD: {
    currencyName: "Namibia Dollar",
    currencyNumericCode: 516,
    currencyMinorUnits: 2,
    currencySymbol: "NAD",
  },
  NPR: {
    currencyName: "Nepalese Rupee",
    currencyNumericCode: 524,
    currencyMinorUnits: 2,
    currencySymbol: "NPR",
  },
  NIO: {
    currencyName: "Cordoba Oro",
    currencyNumericCode: 558,
    currencyMinorUnits: 2,
    currencySymbol: "NIO",
  },
  NGN: {
    currencyName: "Naira",
    currencyNumericCode: 566,
    currencyMinorUnits: 2,
    currencySymbol: "NGN",
  },
  OMR: {
    currencyName: "Rial Omani",
    currencyNumericCode: 512,
    currencyMinorUnits: 3,
    currencySymbol: "OMR",
  },
  PKR: {
    currencyName: "Pakistan Rupee",
    currencyNumericCode: 586,
    currencyMinorUnits: 2,
    currencySymbol: "PKR",
  },
  PAB: {
    currencyName: "Balboa",
    currencyNumericCode: 590,
    currencyMinorUnits: 2,
    currencySymbol: "PAB",
  },
  PGK: {
    currencyName: "Kina",
    currencyNumericCode: 598,
    currencyMinorUnits: 2,
    currencySymbol: "PGK",
  },
  PYG: {
    currencyName: "Guarani",
    currencyNumericCode: 600,
    currencyMinorUnits: 0,
    currencySymbol: "PYG",
  },
  PEN: {
    currencyName: "Sol",
    currencyNumericCode: 604,
    currencyMinorUnits: 2,
    currencySymbol: "PEN",
  },
  PHP: {
    currencyName: "Philippine Peso",
    currencyNumericCode: 608,
    currencyMinorUnits: 2,
    currencySymbol: "PHP",
  },
  PLN: {
    currencyName: "Zloty",
    currencyNumericCode: 985,
    currencyMinorUnits: 2,
    currencySymbol: "PLN",
  },
  QAR: {
    currencyName: "Qatari Rial",
    currencyNumericCode: 634,
    currencyMinorUnits: 2,
    currencySymbol: "QAR",
  },
  RON: {
    currencyName: "Romanian Leu",
    currencyNumericCode: 946,
    currencyMinorUnits: 2,
    currencySymbol: "RON",
  },
  RUB: {
    currencyName: "Russian Ruble",
    currencyNumericCode: 643,
    currencyMinorUnits: 2,
    currencySymbol: "RUB",
  },
  RWF: {
    currencyName: "Rwanda Franc",
    currencyNumericCode: 646,
    currencyMinorUnits: 0,
    currencySymbol: "RWF",
  },
  SHP: {
    currencyName: "Saint Helena Pound",
    currencyNumericCode: 654,
    currencyMinorUnits: 2,
    currencySymbol: "SHP",
  },
  WST: {
    currencyName: "Tala",
    currencyNumericCode: 882,
    currencyMinorUnits: 2,
    currencySymbol: "WST",
  },
  STN: {
    currencyName: "Dobra",
    currencyNumericCode: 930,
    currencyMinorUnits: 2,
    currencySymbol: "STN",
  },
  SAR: {
    currencyName: "Saudi Riyal",
    currencyNumericCode: 682,
    currencyMinorUnits: 2,
    currencySymbol: "SAR",
  },
  RSD: {
    currencyName: "Serbian Dinar",
    currencyNumericCode: 941,
    currencyMinorUnits: 2,
    currencySymbol: "RSD",
  },
  SCR: {
    currencyName: "Seychelles Rupee",
    currencyNumericCode: 690,
    currencyMinorUnits: 2,
    currencySymbol: "SCR",
  },
  SLL: {
    currencyName: "Leone",
    currencyNumericCode: 694,
    currencyMinorUnits: 2,
    currencySymbol: "SLL",
  },
  SGD: {
    currencyName: "Singapore Dollar",
    currencyNumericCode: 702,
    currencyMinorUnits: 2,
    currencySymbol: "SGD",
  },
  XSU: {
    currencyName: "Sucre",
    currencyNumericCode: 994,
    currencyMinorUnits: "N.A.",
    currencySymbol: "XSU",
  },
  SBD: {
    currencyName: "Solomon Islands Dollar",
    currencyNumericCode: 90,
    currencyMinorUnits: 2,
    currencySymbol: "SBD",
  },
  SOS: {
    currencyName: "Somali Shilling",
    currencyNumericCode: 706,
    currencyMinorUnits: 2,
    currencySymbol: "SOS",
  },
  SSP: {
    currencyName: "South Sudanese Pound",
    currencyNumericCode: 728,
    currencyMinorUnits: 2,
    currencySymbol: "SSP",
  },
  LKR: {
    currencyName: "Sri Lanka Rupee",
    currencyNumericCode: 144,
    currencyMinorUnits: 2,
    currencySymbol: "LKR",
  },
  SDG: {
    currencyName: "Sudanese Pound",
    currencyNumericCode: 938,
    currencyMinorUnits: 2,
    currencySymbol: "SDG",
  },
  SRD: {
    currencyName: "Surinam Dollar",
    currencyNumericCode: 968,
    currencyMinorUnits: 2,
    currencySymbol: "SRD",
  },
  SZL: {
    currencyName: "Lilangeni",
    currencyNumericCode: 748,
    currencyMinorUnits: 2,
    currencySymbol: "SZL",
  },
  SEK: {
    currencyName: "Swedish Krona",
    currencyNumericCode: 752,
    currencyMinorUnits: 2,
    currencySymbol: "SEK",
  },
  SYP: {
    currencyName: "Syrian Pound",
    currencyNumericCode: 760,
    currencyMinorUnits: 2,
    currencySymbol: "SYP",
  },
  TWD: {
    currencyName: "New Taiwan Dollar",
    currencyNumericCode: 901,
    currencyMinorUnits: 2,
    currencySymbol: "NT$",
  },
  TJS: {
    currencyName: "Somoni",
    currencyNumericCode: 972,
    currencyMinorUnits: 2,
    currencySymbol: "TJS",
  },
  TZS: {
    currencyName: "Tanzanian Shilling",
    currencyNumericCode: 834,
    currencyMinorUnits: 2,
    currencySymbol: "TZS",
  },
  THB: {
    currencyName: "Baht",
    currencyNumericCode: 764,
    currencyMinorUnits: 2,
    currencySymbol: "THB",
  },
  TOP: {
    currencyName: "Pa’anga",
    currencyNumericCode: 776,
    currencyMinorUnits: 2,
    currencySymbol: "TOP",
  },
  TTD: {
    currencyName: "Trinidad and Tobago Dollar",
    currencyNumericCode: 780,
    currencyMinorUnits: 2,
    currencySymbol: "TTD",
  },
  TND: {
    currencyName: "Tunisian Dinar",
    currencyNumericCode: 788,
    currencyMinorUnits: 3,
    currencySymbol: "TND",
  },
  TRY: {
    currencyName: "Turkish Lira",
    currencyNumericCode: 949,
    currencyMinorUnits: 2,
    currencySymbol: "TRY",
  },
  TMT: {
    currencyName: "Turkmenistan New Manat",
    currencyNumericCode: 934,
    currencyMinorUnits: 2,
    currencySymbol: "TMT",
  },
  UGX: {
    currencyName: "Uganda Shilling",
    currencyNumericCode: 800,
    currencyMinorUnits: 0,
    currencySymbol: "UGX",
  },
  UAH: {
    currencyName: "Hryvnia",
    currencyNumericCode: 980,
    currencyMinorUnits: 2,
    currencySymbol: "UAH",
  },
  AED: {
    currencyName: "UAE Dirham",
    currencyNumericCode: 784,
    currencyMinorUnits: 2,
    currencySymbol: "AED",
  },
  UYU: {
    currencyName: "Peso Uruguayo",
    currencyNumericCode: 858,
    currencyMinorUnits: 2,
    currencySymbol: "UYU",
  },
  UYW: {
    currencyName: "Unidad Previsional",
    currencyNumericCode: 927,
    currencyMinorUnits: 4,
    currencySymbol: "UYW",
  },
  UZS: {
    currencyName: "Uzbekistan Sum",
    currencyNumericCode: 860,
    currencyMinorUnits: 2,
    currencySymbol: "UZS",
  },
  VUV: {
    currencyName: "Vatu",
    currencyNumericCode: 548,
    currencyMinorUnits: 0,
    currencySymbol: "VUV",
  },
  VES: {
    currencyName: "Bolívar Soberano",
    currencyNumericCode: 928,
    currencyMinorUnits: 2,
    currencySymbol: "VES",
  },
  VND: {
    currencyName: "Dong",
    currencyNumericCode: 704,
    currencyMinorUnits: 0,
    currencySymbol: "₫",
  },
  YER: {
    currencyName: "Yemeni Rial",
    currencyNumericCode: 886,
    currencyMinorUnits: 2,
    currencySymbol: "YER",
  },
  ZMW: {
    currencyName: "Zambian Kwacha",
    currencyNumericCode: 967,
    currencyMinorUnits: 2,
    currencySymbol: "ZMW",
  },
  ZWL: {
    currencyName: "Zimbabwe Dollar",
    currencyNumericCode: 932,
    currencyMinorUnits: 2,
    currencySymbol: "ZWL",
  },
};
