import { Grid, Typography, Box } from "@mui/material";
import React, { FC, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import "./expandableMenu.scss";
import classNames from "classnames";
import EdisonTypography from "../../../app-shared/edison/typography/EdisonTypography";

export interface ExpandableMenuProps {
  title: string;
  children: any;
  sticky?: boolean;
}
const ExpandableMenu: FC<ExpandableMenuProps> = ({
  title,
  children,
  sticky,
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  return (
    <Grid
      container
      direction="column"
      className={classNames("expandable-menu", { sticky: sticky })}>
      <div className="expandable-menu_header">
        <EdisonTypography title={title} variant="t2" />
        {isExpanded ? (
          <ArrowDropUpIcon onClick={() => setIsExpanded(false)} />
        ) : (
          <ArrowDropDownIcon onClick={() => setIsExpanded(true)} />
        )}
      </div>
      {isExpanded && children}
    </Grid>
  );
};

export default ExpandableMenu;
