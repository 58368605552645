import {
  Divider,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Node } from "react-flow-renderer";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  selectAllFieldsByTypeList,
  selectAllNonDeletedFields,
} from "../../../../features/fields/fieldsSlice";
import {
  selectCurrentElement,
  selectIsEdit,
  setSelectedElement,
  updateElement,
} from "../../../../features/workflow/workflowSlice";
import { ExpressionUnit } from "../../../../../models/calculatedfield/calculatedfield.model";
import WorkflowFrontendCondition from "../../../../../models/workflow/frontend/WorkflowFrontendCondition.model";
import { useAppSelector } from "../../../../store/hooks";
import EdisonButton from "../../../../../app-shared/edison/button/EdisonButton";
import EdisonTypography from "../../../../../app-shared/edison/typography/EdisonTypography";
import { moveDNDObjectInArray } from "../../../../utils/dndHelpers";
import removeWhitespaces from "../../../../utils/removeWhitespaces";
import ExpressionsTab from "../../../calculatedfield/tabs/ExpressionsTab";
import OperandOperator from "../../../calculatedfield/units/OperatorOperand";
import SearchableTabs, { SingleTab } from "../../../tabs/SearchableTabs";
import updateNodeCustomData from "../../utils/updateNodeCustomData";
import ConditionListFieldsTab from "./ConditionListFieldsTab";
import "./conditionoptions.scss";
const ConditionOptions = () => {
  const { t } = useTranslation(["admin"]);
  const currentNode = useAppSelector(selectCurrentElement);
  const fields = useAppSelector((state) =>
    selectAllFieldsByTypeList(state, ["number", "calculated", "text"])
  );
  const isEdit = useAppSelector(selectIsEdit);
  const dispatch = useDispatch();
  const [expressionList, setExpressionList] = useState<ExpressionUnit[]>([]);
  const [toggled, setToggled] = useState<boolean>(true);

  useEffect(() => {
    if (currentNode) {
      const currentExpressionList = (
        currentNode.data as WorkflowFrontendCondition
      ).expressionList;
      if (currentExpressionList) {
        setExpressionList(currentExpressionList);
      } else {
        setExpressionList([]);
      }
    }
  }, [currentNode]);

  const moveExpression = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setExpressionList((prev) =>
        moveDNDObjectInArray(prev, dragIndex, hoverIndex)
      );
    },
    [currentNode]
  );

  const removeUnit = (indexToRemove: number) => {
    setExpressionList((prev) =>
      prev.filter((_, index: number) => index !== indexToRemove)
    );
  };
  const addExpressionUnit = (unitToAdd: ExpressionUnit) => {
    setExpressionList((prev) => [...prev, unitToAdd]);
  };
  const defaultTabs: SingleTab[] = [
    {
      label: "fields",
      children: (
        <ConditionListFieldsTab
          data={fields}
          addExpressionUnit={addExpressionUnit}
        />
      ),
    },
    {
      label: t("operators"),
      children: (
        <ExpressionsTab
          isCondition={true}
          addExpressionUnit={addExpressionUnit}
        />
      ),
    },
  ];
  return (
    <Stack direction="column" spacing={2} className="condition-options-root">
      <div>
        <Stack direction="column" spacing={1}>
          <Stack direction="column">
            <Typography> {t("label")}</Typography>
            <TextField
              className="no-label-input"
              value={currentNode ? currentNode.data.displayName : ""}
              onChange={(e) => {
                if (currentNode) {
                  dispatch(
                    updateElement({
                      ...currentNode,
                      data: {
                        ...currentNode.data,
                        displayName: e.target.value,
                      },
                    })
                  );
                }
              }}
            />
          </Stack>
          <Stack direction="column">
            <Typography> {t("internalName")}</Typography>
            <TextField
              disabled={true}
              className="no-label-input"
              value={
                isEdit
                  ? currentNode?.data.name
                  : currentNode
                  ? removeWhitespaces(currentNode.data.displayName)
                  : ""
              }
            />
          </Stack>
          <EdisonTypography title={t("expression")} variant="t4" />
          <Grid container direction="row" className="expression-box">
            {expressionList.map(
              (expressionUnit: ExpressionUnit, index: number) => (
                <OperandOperator
                  key={index}
                  expressionUnit={expressionUnit}
                  removeExpressionUnit={removeUnit}
                  index={index}
                  id={index.toString()}
                  moveExpression={moveExpression}
                />
              )
            )}
          </Grid>
          <Divider />
          <div>
            <EdisonTypography title={t("components")} variant="t4" />
            <div className="tabs-box">
              <SearchableTabs tabs={defaultTabs} tabsToSearch={["fields"]} />
            </div>
          </div>
        </Stack>
      </div>

      <Divider />
      <Stack direction="row" className="toggle-container">
        {t("trueFalseToggle")}
        <Switch value={toggled} onChange={() => setToggled((prev) => !prev)} />
      </Stack>
      <Divider />
      <Stack direction="row" spacing={2}>
        <EdisonButton
          disabled={expressionList.length === 0}
          name={t("save")}
          onClick={() => {
            dispatch(
              updateElement(
                updateNodeCustomData(
                  "expressionList",
                  expressionList,
                  currentNode as Node
                )
              )
            );
            dispatch(setSelectedElement(null));
          }}
        />
        <EdisonButton
          name={t("cancel")}
          onClick={() => {
            dispatch(setSelectedElement(null));
          }}
        />
      </Stack>
    </Stack>
  );
};

export default ConditionOptions;
