
const publicImagesUrl = "https://cdn-dev.edison365.com/edison365-public/Images"
const token= "tk=RVSYUwvyUGjiAPaQCRPIGbfEs_0NwihK0pxBH5A9XWvoB854DTGvv2OL-Q8S3AoLR2h055u8srwi39o" 

export const CDNValues= {
    edisonLogo: `${publicImagesUrl}/edison365_logo_grey.png?${token}`,
    businessCaseSVG:
    `${publicImagesUrl}/businessCase.svg?${token}`,
    projectsSVG:`${publicImagesUrl}/projects.svg?${token}`,
    ideasSVG: `${publicImagesUrl}/ideas.svg?${token}`,
    adminSVG:  `${publicImagesUrl}/admin.svg?${token}`
}