import {
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Stack,
} from "@mui/material";
import React, { FC } from "react";
import { SIDEBAR_ITEM } from "../../config/constants";
import FeatureSection from "../../../models/FeatureSection.model";
import Item from "../../../models/Item.model";
import { getFieldTypes, getTableDataTypes } from "../../utils/dataTypes";
import FeatureItem from "../livePreview/FeatureItem";
import "./featurelist.scss";

interface FeatureListProps {
  featureSections: FeatureSection;
  data: Item[];
}

const featuresTabHelper = (section: any, item: Item) => {
  switch (section.id) {
    case "textFields":
      return (
        item.dataType === getFieldTypes().textBox ||
        item.dataType === getFieldTypes().richText
      );
    case "numberFields":
      return (
        item.dataType === getFieldTypes().number ||
        item.dataType === getFieldTypes().calculated
      );
    case "switchFields":
      return item.dataType === getFieldTypes().switch;
    case "URLFields":
      return item.dataType === getFieldTypes().url;
    case "dateFields":
      return item.dataType === getFieldTypes().date;
    case "richTextFields":
      return item.dataType === getFieldTypes().richText;
    case "imageFields":
      return item.dataType === getFieldTypes().image;
    case "peoplePicker":
      return item.dataType === getFieldTypes().people;
    case "multiChoice":
      return item.dataType === getFieldTypes().multiChoice;
    case "standardTables":
      return item.dataType === getTableDataTypes().standard;
    case "periodicTables":
      return item.dataType === getTableDataTypes().periodic;
    case "tasksTables":
      return item.dataType === getTableDataTypes().task;
    case "buttons":
      return item;
  }
};

const FeatureList: FC<FeatureListProps> = ({
  featureSections,
  data: features,
}) => {
  return (
    <List dense className="feature-list-container" subheader={<li />}>
      {featureSections.sections.map((section, sectionIndex) => {
        return (
          <li key={`section-${section.name}`}>
            <ul className="nested-list">
              {section.name ? (
                <ListSubheader
                  className="sub-header"
                  disableGutters
                  key={sectionIndex}>
                  <Stack direction="row" spacing={1}>
                    <div className="icon-container">
                      <section.icon />
                    </div>
                    <div> {section.name}</div>
                  </Stack>
                </ListSubheader>
              ) : (
                ""
              )}
              {features
                .filter((feature) => featuresTabHelper(section, feature))
                .map((item, index) => {
                  return (
                    <FeatureItem
                      key={index.toString()}
                      data={{
                        id: item.id,
                        type: SIDEBAR_ITEM,
                        component: {
                          type: item.dataType,
                          ...item,
                        },
                      }}
                      type={SIDEBAR_ITEM}>
                      <ListItem disableGutters key={index}>
                        <ListItemText
                          className="list-item"
                          primary={item.displayName}
                        />
                      </ListItem>
                    </FeatureItem>
                  );
                })}
            </ul>
          </li>
        );
      })}
    </List>
  );
};

export default FeatureList;
