import { CssBaseline } from "@mui/material";
import React, { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import ILocation from "../../../models/ILocation.model";
import EdisonTypography from "../../../app-shared/edison/typography/EdisonTypography";
import EdisonCard from "../../../app-shared/edison/card/EdisonCard";
import "./layout.scss";

interface Props {
  children: ReactNode;
  backgroundColor?: string;
  titleMarginBottom?: string;
}

const Layout: FC<Props> = ({
  children,
  backgroundColor,
  titleMarginBottom,
}) => {
  const { t } = useTranslation(["admin"]);
  const location: ILocation = useLocation();
  const { title } = !location.state
    ? { title: t("administration") }
    : location.state;
  return (
    <div className="layout-container">
      <CssBaseline />

      <EdisonCard backgroundColor={backgroundColor}>
        {/* <BreadcrumbNav /> */}
        <EdisonTypography title={title} variant="t1" />
        <div>{children}</div>
      </EdisonCard>
    </div>
  );
};

export default Layout;
