import { CardContent, Grid, Typography } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import EditIcon from "@mui/icons-material/Edit";
import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import { AdminButton } from "../../components/adminbutton/AdminButton";
import AdminTable from "../../components/admintable/AdminTable";
import Layout from "../../components/layout/Layout";
import Loading from "../../components/loading/Loading";
import { routes } from "../../config/routes";
import {
  getAllForms,
  getFormsAsync,
  getFormsStatus,
} from "../../features/forms/formsSlice";
import Item from "../../../models/Item.model";
import RouteItem from "../../../models/RouteItem.model";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import EdisonCard from "../../../app-shared/edison/card/EdisonCard";
import getItemListColumns from "../../utils/getItemsListColumns";
import navigate from "../../utils/navigate";
import "./forms.scss";

export const Forms: FC = () => {
  const { t } = useTranslation(["admin"]);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { url } = useRouteMatch();
  const list = useAppSelector(getAllForms);
  const formsStatus = useAppSelector(getFormsStatus);

  useEffect(() => {
    if (formsStatus === "idle") {
      dispatch(getFormsAsync());
    }
  }, []);

  const deleteRow = async (ids: number[]) => {
    console.log("Deleting table");
  };
  const editRow = async (ids: number[]) => {
    navigate(`/admin/forms/formDesigner`, history, {
      title: t("formDesigner"),
      item: list.find((item: Item) => item.id === ids[0]),
    });
  };
  const route = routes.find(
    (routeToFind: RouteItem) => routeToFind.key == "formDesigner"
  );
  return (
    <Layout>
      <div className="forms-page-root">
        <EdisonCard>
          <Grid container spacing={8}>
            <Grid item>
              <AdminButton
                pathName={!!route ? route.path : `${url}/formDesigner`}
                title={t("newForm")}
                buttonText={t("createForm")}
                configuration={list}
              />
            </Grid>
          </Grid>
        </EdisonCard>
        {formsStatus !== "loading" ? (
          <Grid>
            <EdisonCard>
              <CardContent>
                {formsStatus === "idle" ? (
                  list.length > 0 ? (
                    <AdminTable
                      data={list}
                      columns={getItemListColumns(t)}
                      editAction={editRow}
                      deleteAction={deleteRow}
                    />
                  ) : (
                    <Grid className="centered">
                      <Typography variant="caption">
                        {t("noDataErrorMessage")}
                      </Typography>
                    </Grid>
                  )
                ) : (
                  <Typography variant="body1">
                    {t("loadingErrorMessage")}
                  </Typography>
                )}
              </CardContent>
            </EdisonCard>
          </Grid>
        ) : (
          <Loading />
        )}
      </div>
    </Layout>
  );
};
