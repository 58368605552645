import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { TextField } from "@mui/material";
import React, { FC, useState } from "react";
import BaseUserFieldProps from "../../models/BaseUserFieldProps.model";
import "./datepicker.scss";

export interface UserDatePickerProps extends BaseUserFieldProps {
  invalidDateMessage?: string;
}

const UserDatePicker: FC<UserDatePickerProps> = ({
  label,
  disabled,
  readOnly,
  value,
  onChange,
  isInTable,
  useInternalState,
}) => {
  const [internalState, setInternalState] = useState(value);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="user-date-picker">
        <DesktopDatePicker
          label={!isInTable && !readOnly && label}
          onChange={(changeValue) =>
            useInternalState
              ? setInternalState(changeValue)
              : onChange(changeValue)
          }
          value={useInternalState ? internalState : value}
          disabled={disabled || readOnly}
          inputFormat="dd/MM/yyyy"
          minDate={new Date("1000-01-01")}
          renderInput={(params) => {
            const { InputProps, ...rest } = params;
            return readOnly ? (
              <TextField {...rest} className="read-only" />
            ) : (
              <TextField {...params} />
            );
          }}
        />
      </div>
    </LocalizationProvider>
  );
};

export default UserDatePicker;
