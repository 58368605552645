import { RootState } from "../../store/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Choice } from "../../../models/multiChoiceField.model";

export interface multiChoiceFieldState {
  choiceList: Choice[];
}

const initialState: multiChoiceFieldState = {
  choiceList: [],
};

export const multiChoiceFieldSlice = createSlice({
  name: "multiChoiceField",
  initialState,
  reducers: {
    setChoiceList: (state, action: PayloadAction<Choice[]>) => {
      state.choiceList = action.payload;
    },
    addNewChoice: (state, action: PayloadAction<Choice>) => {
      state.choiceList.push(action.payload);
    },
    removeChoice: (state, action: PayloadAction<Choice>) => {
      state.choiceList = state.choiceList.filter(
        (toRemove) => toRemove.path !== action.payload.path
      );
    },
    deletePath: (state, action: PayloadAction<string>) => {
      state.choiceList = state.choiceList.filter(
        (toFilter) =>
          !toFilter.path.startsWith(action.payload) &&
          toFilter.path !== action.payload
      );
    },
  },
});
export const { setChoiceList, addNewChoice, removeChoice, deletePath } =
  multiChoiceFieldSlice.actions;

export const selectChoiceList = (state: RootState): Choice[] =>
  state.multiChoiceField.choiceList;

export const selectChildrenInPath = (
  state: RootState,
  path: string
): Choice[] =>
  (state.multiChoiceField as multiChoiceFieldState).choiceList.filter(
    (toFilter) =>
      toFilter.path.startsWith(path) &&
      toFilter.path != path &&
      toFilter.path.length === path.length + 2
  );
export default multiChoiceFieldSlice.reducer;
