import { Button, Typography } from "@mui/material";
import React, { FC } from "react";
import colors from "../../theme/colors.module.scss";
export interface CustomButtonProps {
  color?: string;
  innerText: string;
  onClick: any;
  disabled?: boolean;
}
const CustomButton: FC<CustomButtonProps> = ({
  color,
  innerText,
  onClick,
  disabled,
}) => {
  return (
    <Button
      disabled={disabled}
      variant="outlined"
      size="large"
      style={{
        color: disabled ? colors.grey100 : color ? color : colors.primaryColor,
        backgroundColor: colors.white,
        // paddingTop: "0.7em",
        // paddingBottom: "0.7em",
      }}
      onClick={onClick}>
      <Typography>{innerText} </Typography>
    </Button>
  );
};

export default CustomButton;
