import { Node } from "react-flow-renderer";

const updateNodeCustomData = (
  propertyToUpdate: string,
  newValue: unknown,
  node: Node,
): Node => {
  return {
        ...node,
        data: {
          ...node.data,
          [propertyToUpdate]: newValue
        },
      };
};

export default updateNodeCustomData;

