import { Grid } from "@mui/material";
import React, { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import HomeCard from "../Card/HomeCard";
import EdisonSkeletonActivity from "../../app-shared/edison/skeletons/activity/EdisonSkeletonActivity";
import EdisonSkeletonCard from "../../app-shared/edison/skeletons/card/EdisonSkeletonCard";
import EdisonSkeletonText from "../../app-shared/edison/skeletons/text/EdisonSkeletonText";
import Leaderboard from "../Leaderboard/Leaderboard";
import NewsEvents from "../NewsEvents/NewsEvents";
import { RecentActivity } from "../RecentActivity/RecentActivity";
import "./homeLayout.scss";

interface Props {
  isLoading: boolean;
}

const HomeLayout: FC<Props> = ({ isLoading }): ReactElement => {
  const { t } = useTranslation(["common"]);

  return (
    <div className={"home-layout"}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <HomeCard title={t("news&events")}>
            {!isLoading ? <NewsEvents /> : <EdisonSkeletonText rows={6} />}
          </HomeCard>
        </Grid>
        <Grid item xs={6}>
          <HomeCard title={t("recentActivity")}>
            {!isLoading ? (
              <RecentActivity />
            ) : (
              <EdisonSkeletonActivity rows={3} />
            )}
          </HomeCard>
        </Grid>
        <Grid item xs={12}>
          <HomeCard title={t("leaderboard")}>
            {!isLoading ? <Leaderboard /> : <EdisonSkeletonText rows={2} />}
          </HomeCard>
        </Grid>
        <Grid item xs={12}>
          <HomeCard title={t("highlightedProjects")}>
            <div className={"card-placeholder"}>
              <EdisonSkeletonCard loading={isLoading} />
              <EdisonSkeletonCard loading={isLoading} />
              <EdisonSkeletonCard loading={isLoading} />
            </div>
          </HomeCard>
        </Grid>
      </Grid>
    </div>
  );
};

export default HomeLayout;
