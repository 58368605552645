import { Stack } from "@mui/material";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  selectCurrentElement,
  selectDisplayEvents,
} from "../../../features/workflow/workflowSlice";
import { WorkFlowNode } from "../../../../models/workflow/WorkflowNode.model";
import { useAppSelector } from "../../../store/hooks";
import EdisonTypography from "../../../../app-shared/edison/typography/EdisonTypography";
import ConditionOptions from "./conditions/ConditionOptions";
import ConnectionOptions from "./connectionoptions/ConnectionOptions";
import NodeEventOptions from "./events/NodeEventOptions";
import NodeEvents from "./events/NodeEvents";
import NodeFlags from "./flags/NodeFlags";
import NodeForm from "./form/NodeForm";
import NodeStage from "./stage/NodeStage";
import NodeTasks from "./tasks/NodeTasks";
import WorkflowInformation from "./workflowinfo/WorkflowInformation";

const RightWorkflowPanel: FC = () => {
  const currentElement = useAppSelector(selectCurrentElement);
  const { t } = useTranslation(["admin"]);
  const displayEvent = useAppSelector(selectDisplayEvents);

  return (
    <div>
      {displayEvent ? (
        <Stack direction="column" spacing={1}>
          <EdisonTypography title={t("events")} variant="t2" />
          <NodeEventOptions eventType={displayEvent} />
        </Stack>
      ) : currentElement ? (
        "target" in currentElement ? (
          <ConnectionOptions />
        ) : (currentElement.data as WorkFlowNode).configuration.type ===
          "Condition" ? (
          <Stack direction="column" spacing={1}>
            <EdisonTypography title={t("condition")} variant="t2" />{" "}
            <ConditionOptions />
          </Stack>
        ) : (
          <Stack spacing={2}>
            <EdisonTypography title={t("nodeOptions")} variant="t2" />
            {currentElement &&
              ["Stage", "StageReview"].includes(
                currentElement.data.configuration.type
              ) && (
                <Stack spacing={2}>
                  <NodeStage />
                  {currentElement.type === "StageReview" && <NodeTasks />}
                </Stack>
              )}
            <NodeForm />
            <NodeEvents />
            <NodeFlags />
          </Stack>
        )
      ) : (
        <Stack direction="column" spacing={1}>
          <EdisonTypography title={t("workflowOptions")} variant="t2" />
          <WorkflowInformation />
        </Stack>
      )}
    </div>
  );
};

export default RightWorkflowPanel;
