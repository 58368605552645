import React, { FC, ReactNode } from "react";
import EdisonTypography from "../typography/EdisonTypography";
import "./edisonflyout.scss";
import { Button, SwipeableDrawer } from "@mui/material";
import Divider from "@mui/material/Divider";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

export interface EdisonFlyoutProps {
  title: string;
  children: ReactNode;
  isOpen: boolean;
  firstButtonText: string;
  secondButtonText: string;
  firstButtonAction: () => void;
  secondButtonAction: () => void;
  iconClickAction: () => void;
  handleOpen: () => void;
  handleClosed: () => void;
}

const EdisonFlyout: FC<EdisonFlyoutProps> = ({
  title,
  children,
  isOpen,
  firstButtonText,
  secondButtonText,
  firstButtonAction,
  secondButtonAction,
  iconClickAction,
  handleOpen,
  handleClosed,
}) => {
  return (
    // <span className="edison-flyout">
    <SwipeableDrawer
      className="edison-flyout"
      anchor="right"
      open={isOpen}
      onClose={() => handleClosed()}
      onOpen={() => handleOpen()}
      hideBackdrop={true}>
      <div className="flyout-title">
        <CloseOutlinedIcon onClick={() => iconClickAction()} />
        <EdisonTypography variant="t4" title={title} />
      </div>
      <Divider />
      <div className="flyout-content">{children}</div>
      <Divider />
      <div className="flyout-buttons">
        <Button onClick={() => firstButtonAction()} variant="contained">
          {firstButtonText}
        </Button>
        <Button onClick={() => secondButtonAction()} variant="outlined">
          {secondButtonText}
        </Button>
      </div>
    </SwipeableDrawer>
    // </span>
  );
};

export default EdisonFlyout;
