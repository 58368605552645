import { TextField } from "@mui/material";
import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";

export interface EdisonFormInputProps {
  name: string;
  label: string;
  defaultValue: string;
  requiredMessage?: string;
  multiline?: boolean;
  rows?: number;
  disabled: boolean;
  inheritedValue?: string;
  placeholder?: string;
  type?: string;
  maxLength?: number;
  fullWidth?: boolean;
  size?: "small" | "medium";
}

const EdisonFormInput: FC<EdisonFormInputProps> = ({
  name,
  label,
  defaultValue,
  requiredMessage,
  multiline,
  rows,
  disabled,
  inheritedValue,
  placeholder,
  type,
  maxLength,
  fullWidth,
  size,
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          label={label}
          variant="outlined"
          fullWidth={fullWidth}
          size={size}
          value={!!inheritedValue ? inheritedValue : value}
          onChange={onChange}
          error={!!error}
          helperText={error ? error.message : " "}
          multiline={multiline}
          rows={rows}
          disabled={disabled}
          placeholder={
            !!placeholder && placeholder.length > 0 ? placeholder : undefined
          }
          type={type}
          inputProps={{
            maxLength: maxLength,
          }}
        />
      )}
      rules={{ required: requiredMessage }}
    />
  );
};

export default EdisonFormInput;
