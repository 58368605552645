import { Button, FormLabel, Grid } from "@mui/material";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  ComparatorList,
  ExpressionUnit,
  OperatorList,
} from "../../../../models/calculatedfield/calculatedfield.model";
import "./expressionstab.scss";

export interface ExpressionsTabProps {
  isCondition: boolean;
  addExpressionUnit: (unitToAdd: ExpressionUnit) => void;
}
const ExpressionsTab: FC<ExpressionsTabProps> = ({
  isCondition,
  addExpressionUnit,
}) => {
  const { t } = useTranslation(["admin"]);
  return (
    <div>
      <Grid container direction="row" className="expression-column">
        <Grid item xs={6}>
          <Grid item container>
            <FormLabel component="legend" className="table-header">
              {t("operators")}
            </FormLabel>
          </Grid>
          {OperatorList.map((operator: any, index: number) => (
            <Grid item container key={index}>
              <Button
                onClick={() => addExpressionUnit(operator)}
                className="button-text">
                 {operator.value} 
              </Button>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={6}>
          <Grid item container>
            <FormLabel component="legend" className="table-header">
              {t("comparators")}
            </FormLabel>
          </Grid>
          {ComparatorList.map((comparator: any, index: number) => (
            <Grid item container key={index}>
              <Button
                disabled={!isCondition}
                onClick={() => addExpressionUnit(comparator)}
                className="button-text">
                 {comparator.value}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </div>
  );
};
export default ExpressionsTab;
