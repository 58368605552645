import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import { Grid, IconButton, Popover } from "@mui/material";
import React, { FC, useCallback, useEffect, useState } from "react";
import {
  moveExpressionUnitInBlock,
  removeConditionBlock,
  removeExpressionUnitFromBlock,
  selectCurrentFocusedRow,
  setIsCondition,
  updateBlockConditionType,
  updateFocusedRow,
} from "../../../features/fields/calculatedFieldSlice";
import {
  ConditionOperator,
  ExpressionUnit,
  RowType,
} from "../../../../models/calculatedfield/calculatedfield.model";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import OperandOperator from "../units/OperatorOperand";
import "./conditionrow.scss";
export interface ConditionRowProps {
  expressionList: ExpressionUnit[];
  conditionListIndex: number;
  rowType: RowType;
  conditionType: ConditionOperator;
}

const ConditionOperatorList: ConditionOperator[] = ["else", "elseIf"];

export const ConditionRow: FC<ConditionRowProps> = ({
  expressionList,
  conditionListIndex,
  rowType,
  conditionType,
}) => {
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [mouseOver, setMouseOver] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const currentFocusedRow = useAppSelector(selectCurrentFocusedRow);

  useEffect(() => {
    currentFocusedRow.rowIndex === conditionListIndex &&
    currentFocusedRow.rowType === rowType
      ? setIsSelected(true)
      : setIsSelected(false);
  }, [currentFocusedRow]);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const moveExpression = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      dispatch(
        moveExpressionUnitInBlock({
          dragIndex: dragIndex,
          hoverIndex: hoverIndex,
        })
      );
    },
    [expressionList]
  );

  const updateRowConditionType = (type: ConditionOperator) => {
    handleClose();
    dispatch(
      updateFocusedRow({
        rowIndex: conditionListIndex,
        rowType: type === "else" ? "else" : "condition",
      })
    );
    dispatch(updateBlockConditionType(type));
  };

  return (
    <Grid
      container
      direction="row"
      className={`condition-row ${
        isSelected || mouseOver ? "grey" : "secondary"
      }`}
      onMouseDown={() =>
        dispatch(
          updateFocusedRow({
            rowType: rowType,
            rowIndex: conditionListIndex,
          })
        )
      }
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}>
      <Grid
        item
        xs={1}
        className={`operator-box ${isSelected && "is-selected"}`}>
        <div className="condition-text">
          {rowType === "then" ? "then" : conditionType}
        </div>
        {!(conditionListIndex === 0 || rowType === "then") && (
          <>
            <IconButton onClick={handleClick}>
              <ArrowDropDownIcon />
            </IconButton>
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}>
              <Grid container direction="column">
                {ConditionOperatorList.map(
                  (operator: ConditionOperator, index: number) => (
                    <IconButton
                      key={index}
                      onClick={() => updateRowConditionType(operator)}>
                      {operator}
                    </IconButton>
                  )
                )}
              </Grid>
            </Popover>
          </>
        )}
      </Grid>
      <Grid item container direction="row" xs={11}>
        <Grid
          item
          container
          direction="row"
          xs={rowType === "condition" ? 11 : 12}>
          {expressionList.map(
            (expressionUnit: ExpressionUnit, conditionBlockIndex: number) => (
              <OperandOperator
                key={conditionBlockIndex}
                expressionUnit={expressionUnit}
                removeExpressionUnit={(number: number) => {
                  dispatch(
                    updateFocusedRow({
                      rowType: rowType,
                      rowIndex: conditionListIndex,
                    })
                  );
                  dispatch(removeExpressionUnitFromBlock(number));
                }}
                index={conditionBlockIndex}
                id={conditionBlockIndex.toString()}
                moveExpression={moveExpression}
              />
            )
          )}
        </Grid>
        {rowType === "condition" && (
          <Grid item container xs={1} className="remove-row-box">
            <IconButton
              size="small"
              onClick={() =>
                conditionListIndex === 0
                  ? dispatch(setIsCondition(false))
                  : dispatch(removeConditionBlock(conditionListIndex))
              }
              style={{ width: "fit-content", height: "fit-content" }}>
              <CloseIcon style={{ fontSize: 25 }} />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
