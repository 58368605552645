import { CircularProgress } from "@mui/material";
import React, { FC } from "react";
import "./loading.scss";


const Loading: FC = () => {


  return (
    <div className="loading-container">
      <CircularProgress size="8em" />
    </div>
  );
};
export default Loading;
