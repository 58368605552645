import { Divider, Stack, Typography, Avatar } from "@mui/material";
import React from "react";
import "./personalprofile.scss";
import { selectUserPhoto, selectUser } from "../../features/user/userSlice";
import { useAppSelector } from "../../store/hooks";
import EdisonTypography from "../../app-shared/edison/typography/EdisonTypography";

const PersonalProfile = () => {
  const userPhoto: any = useAppSelector(selectUserPhoto);
  const user: any = useAppSelector(selectUser);

  return (
    <Stack
      direction="row"
      className="personal-profile-root"
      spacing={2}
      justifyContent="space-between">
      <Stack direction="row" spacing={1} className="profile-info">
        <Stack direction="row" spacing={3}>
          <Avatar
            variant="square"
            src={userPhoto}
            sx={{ height: "126px", width: "126px" }}>
            {" "}
          </Avatar>
          <Stack direction="column" spacing={3} justifyContent="center">
            <EdisonTypography
              variant="t1"
              title={user.displayName}
              disableUppercase={true}
            />
            <EdisonTypography
              variant="t3"
              title={user.jobTitle}
              disableUppercase={true}
            />
          </Stack>
        </Stack>
      </Stack>

      <Stack direction="row" spacing={1}>
        <Stack direction="column" justifyContent="center">
          <div> superstar icon</div>
          <div> rank</div>
        </Stack>
        <Divider orientation="vertical" flexItem />
        <Stack direction="row">
          <Stack
            direction="column"
            spacing={1}
            className="profile-statistics"
            justifyContent="center">
            <EdisonTypography
              variant="t6"
              title={"My Statistics"}
              disableUppercase={true}
            />
            <Stack direction="row" spacing={2}>
              <EdisonTypography
                variant="t6"
                title={"owned"}
                disableUppercase={true}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PersonalProfile;
