import { Input } from "@mui/material";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import "./row.scss";

export interface EditableHeaderProps {
  onText: (title: string) => void;
  title: string;
}

const EditableHeader: FC<EditableHeaderProps> = ({ onText, title }) => {
  const { t } = useTranslation(["admin"]);
  // Underline has been disabled as a design test, might need it later
  const [isFocused, setIsFocused] = useState(false);

  return (
    <Input
      color="primary"
      defaultValue={title}
      placeholder={t("defaultLivepreviewElementTitle")}
      disableUnderline={!isFocused}
      onFocus={() => setIsFocused(false)}
      onBlur={() => setIsFocused(false)}
      size="small"
      onChange={(e) => {
        onText(e.target.value);
      }}
    />
  );
};
export default EditableHeader;
