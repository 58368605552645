import {
  Action, combineReducers, configureStore, ThunkAction
} from "@reduxjs/toolkit";
import { adminReducers } from "../app-admin/store/store";
import { projectsReducers } from "../app-projects/redux/store/store";
// import { projectsReducers } from "EDProjects/store";
import announcementsSlice from "../features/announcements/announcementsSlice";
import msGraphSlice from "../features/msGraph/msGraphSlice";
import projectSlice from "../features/projects/containerProjectSlice";
import projectTypesSlice from "../features/projectTypes/projectTypesSlice";
import userSlice from "../features/user/userSlice";


const rootReducer = combineReducers({
  ...adminReducers,
  ...projectsReducers,
  user: userSlice,
  announcements: announcementsSlice,
  msGraph: msGraphSlice,
  projectTypes: projectTypesSlice,
  containerProject: projectSlice,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
