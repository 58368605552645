import { Grid } from "@mui/material";
import React, { FC, useEffect } from "react";
import {
  announcement,
  selectAllAnnouncements,
} from "../../features/announcements/announcementsSlice";
import { useAppSelector } from "../../store/hooks";
import "./newsEvents.scss";
import EdisonTypography from "../../app-shared/edison/typography/EdisonTypography";

const NewsEvents: FC = () => {
  const announcements = useAppSelector(selectAllAnnouncements);

  return (
    <Grid>
      {announcements &&
        announcements.map((newsItem: announcement, index: number) => (
          <Grid className={"news-block"} key={index}>
            <EdisonTypography
              variant="t6"
              title={newsItem.title}
              disableUppercase={true}
            />{" "}
            <div className={"wrap-text"}>
              <EdisonTypography
                variant="t9"
                title={newsItem.message}
                disableUppercase={true}
              />
            </div>
          </Grid>
        ))}
    </Grid>
  );
};
export default NewsEvents;
