import { Box, Grid, Typography } from "@mui/material";
import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { section } from "../../config/constants";
import {
  selectCustomFields,
  selectSystemFields,
} from "../../features/fields/fieldsSlice";
import { useAppSelector } from "../../store/hooks";
import FeatureList from "../featurelist/FeatureList";
import SearchableTabs from "../tabs/SearchableTabs";
import "./cardfeatures.scss";
export interface CardFeaturesProps {
  tabs: any[];
}

const CardFeatures: FC = () => {
  const { t } = useTranslation(["admin"]);

  const systemFields = useAppSelector(selectSystemFields);
  const customFields = useAppSelector(selectCustomFields);

  const tabsContent = [
    {
      id: "ideas",
      label: t("ideas"),
      children: (
        <FeatureList featureSections={section[0]} data={systemFields} />
      ),
    },
    {
      id: "businessCase",
      label: t("businessCase"),
      children: (
        <FeatureList featureSections={section[0]} data={systemFields} />
      ),
    },
    {
      id: "projects",
      label: t("projects"),
      children: (
        <FeatureList featureSections={section[0]} data={systemFields} />
      ),
    },

    {
      id: "customFields",
      label: t("custom"),
      children: (
        <FeatureList featureSections={section[0]} data={customFields} />
      ),
    },
  ];

  return systemFields.length > 0 || customFields.length > 0 ? (
    <Grid container className="card-features-container">
      <Grid item alignContent="center" className="drag-drop-container">
        <Typography variant="subtitle1" display="block">
          <Box fontWeight="700" letterSpacing={5}>
            {t("cardFeatures")}
          </Box>
        </Typography>
        <Typography variant="subtitle2" display="block">
          <Box fontWeight="300" letterSpacing={5} className="drag-drop-heading">
            {t("cardDragAndDrop")}
          </Box>
        </Typography>
      </Grid>
      <div className="tab-container">
        <SearchableTabs
          tabs={tabsContent}
          tabsToSearch={[t("system"), t("custom")]}
        />
      </div>
    </Grid>
  ) : (
    <Typography variant="body1">{t("loadingErrorMessage")}</Typography>
  );
};

export default CardFeatures;
