import {
  createAsyncThunk,
  createSlice,
  isRejectedWithValue,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  deleteFields,
  getFields,
  getIndividualField,
  postFields,
  putFields,
} from "../../../services/APIService";
import FieldItem from "../../../models/FieldTypes/FieldItem.model";
import Item from "../../../models/Item.model";
import { RootState } from "../../store/store";
import formatModifiedDate from "../../utils/formatModifiedDate";

export interface fieldsState {
  value: number;
  fields: Array<Item>;
  individualField: Item | null;
  status: "idle" | "loading" | "failed";
}

const initialState: fieldsState = {
  value: 0,
  fields: [],
  status: "idle",
  individualField: null,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getFieldsAsync = createAsyncThunk(
  "fields/getFields",
  async (_, { rejectWithValue }) => {
    const response: any = await getFields();
    if (!(response.status as number).toString().startsWith("2"))
      return rejectWithValue(response.data);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const getIndividualFieldAsync = createAsyncThunk(
  "fields/getIndividualField",
  async (id: number, { rejectWithValue }) => {
    const response: any = await getIndividualField(id);
    if (!(response.status as number).toString().startsWith("2"))
      return rejectWithValue(response.data);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const recycleFieldAsync = createAsyncThunk(
  "fields/recycleField",
  async (id: number) => {
    await deleteFields(id);
    // The value we return becomes the `fulfilled` action payload
    return id;
  }
);

export const createFieldAsync = createAsyncThunk(
  "fields/createField",
  async (data: unknown, { rejectWithValue }) => {
    const response: any = await postFields(data);
    if (!(response.status as number).toString().startsWith("2"))
      return rejectWithValue(response.data);
    return response.data;
  }
);

export const updateFieldAsync = createAsyncThunk(
  "fields/updateField",
  async (data: unknown, { rejectWithValue }) => {
    const response: any = await putFields(data);
    if (!(response.status as number).toString().startsWith("2"))
      return rejectWithValue(response.data);
    return response.data;
  }
);

export const fieldsSlice = createSlice({
  name: "fields",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    incrementByAmount: (state, action: PayloadAction<number>) => {
      state.value += action.payload;
    },
    clearIndividualField: (state) => {
      state.individualField = null;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getFieldsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getFieldsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        // state.fields = state.fields.concat(action.payload);
        state.fields = action.payload;
      })
      .addCase(recycleFieldAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(recycleFieldAsync.fulfilled, (state, action) => {
        state.status = "idle";
        if (!action.payload) return;
        state.fields = state.fields.filter(
          (field: Item) => field.id !== action.payload
        );
      })
      .addCase(createFieldAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createFieldAsync.fulfilled, (state, action) => {
        state.status = "idle";
        if (!action.payload) return;
        state.fields.push(action.payload);
      })
      .addCase(updateFieldAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateFieldAsync.fulfilled, (state, action) => {
        state.status = "idle";
        if (!action.payload) return;
        const { id } = action.payload;
        state.fields = state.fields.map((field: Item) =>
          id !== field.id ? field : { ...action.payload }
        );
      })
      .addCase(getIndividualFieldAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getIndividualFieldAsync.fulfilled, (state, action) => {
        state.status = "idle";
        if (!action.payload) return;
        state.individualField = action.payload;
      });
  },
});

export const { increment, decrement, incrementByAmount } = fieldsSlice.actions;

export const selectAllNonDeletedFields = (state: RootState): Array<Item> =>
  state.fields.fields
    .map((dataToMap: Item) => {
      return {
        ...dataToMap,
        modified: formatModifiedDate(dataToMap.modified) as unknown as Date,
      };
    })
    .filter((res: Item) => !res.isDeleted && res.dataType !== "Button");
export const selectAllFields = (state: RootState): Array<Item> =>
  state.fields.fields.map((dataToMap: Item) => {
    return {
      ...dataToMap,
      modified: formatModifiedDate(dataToMap.modified) as unknown as Date,
    };
  });

export const selectAllButtons = (state: RootState): Array<Item> =>
  state.fields.fields
    .map((dataToMap: Item) => {
      return {
        ...dataToMap,
        modified: formatModifiedDate(dataToMap.modified) as unknown as Date,
      };
    })
    .filter((res: Item) => !res.isDeleted && res.dataType === "Button");

export const selectAllNumberAndCalculatedFields = (
  state: RootState
): Array<Item> =>
  state.fields.fields
    .map((dataToMap: Item) => {
      return {
        ...dataToMap,
        modified: formatModifiedDate(dataToMap.modified) as unknown as Date,
      };
    })
    .filter(
      (res: Item) =>
        !res.isDeleted &&
        (res.dataType === "number" || res.dataType === "calculated")
    );

export const selectAllFieldsByType = (state: RootState, type: string): Item[] =>
  state.fields.fields
    .map((dataToMap: Item) => {
      return {
        ...dataToMap,
        modified: formatModifiedDate(dataToMap.modified) as unknown as Date,
      };
    })
    .filter(
      (res: Item) =>
        !res.isDeleted && res.dataType.toLowerCase() === type.toLowerCase()
    );

export const selectAllFieldsByTypeList = (
  state: RootState,
  types: string[]
): Item[] =>
  state.fields.fields
    .map((dataToMap: Item) => {
      return {
        ...dataToMap,
        modified: formatModifiedDate(dataToMap.modified) as unknown as Date,
      };
    })
    .filter(
      (res: Item) =>
        !res.isDeleted &&
        types.find((type) => type.toLowerCase() === res.dataType.toLowerCase())
    );

export const selectFieldById = (state: RootState, fieldId: number): Item =>
  state.fields.fields.find((field: Item) => field.id === fieldId) ||
  state.fields.fields[0];

export const selectIndividualField = (state: RootState): FieldItem | null =>
  state.fields.individualField;

export const selectSystemFields = (state: RootState): Array<Item> =>
  state.fields.fields.filter((f: Item) => f.isSystem);

export const selectCustomFields = (state: RootState): Array<Item> =>
  state.fields.fields.filter((f: Item) => !f.isSystem);

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const selectCount = (state: RootState): number => state.counter.value;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//   (amount: number): AppThunk =>
//   (dispatch, getState) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//       dispatch(incrementByAmount(amount));
//     }
//   };

export const { clearIndividualField } = fieldsSlice.actions;
export default fieldsSlice.reducer;
