import AddIcon from "@mui/icons-material/Add";
import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import EdisonButton from "../../../app-shared/edison/button/EdisonButton";
import "./adminbutton.scss";
interface Props {
  pathName: string | undefined;
  title: string;
  buttonText: string;
  configuration?: unknown;
  startIcon?: any;
}

export const AdminButton: FC<Props> = ({
  pathName,
  title,
  buttonText,
  configuration,
  startIcon,
}) => {
  return (
    <NavLink
      to={{
        pathname: pathName,
        state: {
          title: title,
          configuration: configuration,
        },
      }}
      className="admin-button-navlink">
      <EdisonButton
        name={buttonText}
        startIcon={startIcon ? startIcon : <AddIcon />}
      />
    </NavLink>
  );
};
