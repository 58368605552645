import AddIcon from "@mui/icons-material/Add";
import { Button, Grid, Tooltip } from "@mui/material";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import "./fieldstabrow.scss";
export interface FieldsTabRowProps {
  fieldName: string;
  isDisabled: boolean;
  fieldType?: string;
  onClick?: any;
}
const FieldsTabRow: FC<FieldsTabRowProps> = ({
  fieldName,
  isDisabled,
  onClick,
  fieldType,
}) => {
  const { t } = useTranslation(["admin"]);
  return (
    <Button
      onClick={() => {
        onClick();
      }}
      disabled={isDisabled}
      className="fields-tab-row-root">
      <Grid container direction="row" className={`fields-tab-row-container`}>
        <Grid item xs={7} className="children-centered break-word">
          {fieldName}
        </Grid>
        {fieldType && (
          <Grid item xs={3} className="children-centered capitalize">
            {fieldType}
          </Grid>
        )}
        <Grid item xs={2} className="children-centered">
          <Tooltip title={t("add")!} placement="right" arrow>
            <Button disabled={isDisabled} className="add-icon">
              <AddIcon />
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    </Button>
  );
};

export default FieldsTabRow;
