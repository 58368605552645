
import { Action, ThunkAction } from "@reduxjs/toolkit";
import { store } from "../../../store/store";
import formsSlice from "../reducers/formsSlice";
import projectsSlice from "../reducers/projectsSlice";

export const projectsReducers = {
  projectForms: formsSlice,
  projects: projectsSlice,
};
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
