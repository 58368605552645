import { Button, Divider, Stack } from "@mui/material";
import React, { FC } from "react";
import { useDispatch } from "react-redux";
import {
  selectCurrentElement,
  selectDisplayEvents,
  setDisplayEvent,
  updateElement,
} from "../../../../features/workflow/workflowSlice";
import { eventActions } from "../../../../../models/workflow/constants/Events.constant";
import WorkflowStage from "../../../../../models/workflow/WorkflowStage.model";
import WorkflowStageEvent from "../../../../../models/workflow/WorkflowStageEvent.model";
import { useAppSelector } from "../../../../store/hooks";
import EdisonButton from "../../../../../app-shared/edison/button/EdisonButton";
import updateNodeCustomData from "../../utils/updateNodeCustomData";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { Node } from "react-flow-renderer";
import { useTranslation } from "react-i18next";
import "./nodeeventoptions.scss";

export interface NodeEventOptionsProps {
  eventType: string;
}
const NodeEventOptions: FC<NodeEventOptionsProps> = ({ eventType }) => {
  const dispatch = useDispatch();
  const currentElement = useAppSelector(selectCurrentElement);

  const { t } = useTranslation(["admin"]);
  return (
    <Stack direction="column" spacing={2} className="node-event-options-root">
      {eventActions.map((actionType: string, index: number) => (
        <Button
          key={index}
          className="action-option-container"
          disabled={checkIfActionDisabled(
            (currentElement?.data as WorkflowStage)?.events,
            eventType,
            actionType
          )}
          onClick={() => {
            const newEvent: WorkflowStageEvent = {
              type: eventType,
              action: actionType,
            };

            const currentEvents = (currentElement?.data as WorkflowStage)
              .events;
            if (currentEvents) {
              const matching = currentEvents.find(
                (matchingEvent: WorkflowStageEvent) =>
                  matchingEvent.type === eventType &&
                  matchingEvent.action === actionType
              );
              if (!matching) {
                dispatch(
                  updateElement(
                    updateNodeCustomData(
                      "events",
                      [...currentEvents, newEvent],
                      currentElement as Node
                    )
                  )
                );
              }
            } else {
              dispatch(
                updateElement(
                  updateNodeCustomData(
                    "events",
                    [newEvent],
                    currentElement as Node
                  )
                )
              );
            }
            dispatch(setDisplayEvent(null));
          }}>
          <FormatListBulletedIcon /> {t(actionType)}
        </Button>
      ))}
      <Divider />
      <EdisonButton
        name={t("cancel")}
        onClick={() => {
          dispatch(setDisplayEvent(null));
        }}
      />
    </Stack>
  );
};

const checkIfActionDisabled = (
  events: WorkflowStageEvent[] | null | undefined,
  eventType: string,
  actionType: string
): boolean => {
  if (events) {
    const found = events.find(
      (event: WorkflowStageEvent) =>
        event.action === actionType && event.type === eventType
    );
    return found ? true : false;
  }
  return false;
};
export default NodeEventOptions;
