import {
  Checkbox,
  Divider,
  Stack,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import React, { FC } from "react";

export interface SortableTableHeaderProps {
  onRequestSort: (event: React.MouseEvent<unknown>, itemKey: string) => void;
  orderBy: string;
  order: any;
  columns: any[];
  icons: any[];
  checked: boolean;
  onChange: any;
  showCheckBox: boolean;
}

const EdisonSortableTableHeader: FC<SortableTableHeaderProps> = ({
  columns,
  icons,
  order,
  orderBy,
  onRequestSort,
  onChange,
  checked,
  showCheckBox,
}) => {
  const createSortHandler =
    (itemKey: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, itemKey);
    };
  return (
    <TableHead>
      <TableRow className="uppercase-text">
        {showCheckBox && (
          <TableCell width={"3em"}>
            <Checkbox
              checked={checked}
              onChange={(e) => onChange(e.target.checked)}
            />
          </TableCell>
        )}

        {columns.map((column: any, index: number) => (
          <TableCell key={column.id} align="left">
            <Stack direction="row" spacing={2}>
              {(index > 0 || showCheckBox) && (
                <Divider orientation="vertical" flexItem />
              )}
              <TableSortLabel
                active={orderBy === column.id}
                direction={orderBy === column.reference ? order : "asc"}
                onClick={createSortHandler(column.reference)}>
                {column.name}
              </TableSortLabel>
            </Stack>
          </TableCell>
        ))}
        {icons.map((_icon, index) => (
          <TableCell key={index} />
        ))}
      </TableRow>
    </TableHead>
  );
};
export default EdisonSortableTableHeader;
