import { Stack } from "@mui/material";
import { nanoid } from "nanoid";
import React, { useState } from "react";
import { COMPONENT, ROW } from "../../../config/constants";
import { ItemDNDType } from "../../../../models/Livepreview/ItemDNDType.model";
import EdisonContentCard from "../../../../app-shared/edison/contentcard/EdisonContentCard";
import DropZone from "../../livePreview/DropZone";
import "./cardlivepreview.scss";
import CardLivePreviewRow from "./row/CardLivePreviewRow";

const CardLivePreview = () => {
  const [rows, setRows] = useState<ItemDNDType[]>([]);

  const handleDrop = (data: any, value: any) => {
    if (value.path) {
      //dropping from within card
      const currentRowIndex = parseInt(value.path[0]);
      const currentRow = rows[currentRowIndex];

      const toMove = getItemFromPath(rows, value.path);
      const removed = removeFromRow(rows, value.path);
      const newRows = [...removed, addNewRow(toMove, removed.length, 0)];

      const updated =
        currentRow && currentRow.children && currentRow.children.length === 0
          ? [
              ...newRows.slice(0, currentRowIndex),
              ...incrementRowsPath(newRows.slice(currentRowIndex + 1), -1),
            ]
          : newRows;
      setRows(updated);
    } else {
      // dropping from sidebar
      if (rows.length === 0) {
        setRows([addNewRow(value, 0, 0)]);
      } else {
        setRows((prev) => [...prev, addNewRow(value, prev.length, 0)]);
      }
    }
  };

  const reorderRows = (data: any, value: any, index: number) => {
    if (value.path) {
      //dropping from within card
      const toMove = getItemFromPath(rows, value.path);

      const updated = removeFromRow(rows, value.path);
      const afterInsert = updated.slice(index);

      const update = [
        ...updated.slice(0, index),
        addNewRow(toMove, index, 0),
        ...incrementRowsPath(afterInsert, 1),
      ];

      setRows(removeEmptyRows(update));
    } else {
      // dropping from sidebar
      const newRow = addNewRow(value, index, 0);
      const afterInsert = rows.slice(index);
      const updated = [
        ...rows.slice(0, index),
        newRow,
        ...incrementRowsPath(afterInsert, 1),
      ];
      setRows(removeEmptyRows(updated));
    }
  };
  const canDrop = (item: ItemDNDType, monitor: any) => {
    const itemPath = item.path;
    if (!itemPath) {
      return rows.length < 10;
    }
    return true;
  };
  return (
    <div className="card-live-preview-root">
      <EdisonContentCard
        id={nanoid()}
        title="Important title"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse vitae tincidunt odio."
        createdDate="25/12/2023"
        isCompact={false}
        imageSource="https://cdn-dev.edison365.com/edison365-public/Images/cardPlaceholder.png?tk=RVSYUwvyUGjiAPaQCRPIGbfEs_0NwihK0pxBH5A9XWvoB854DTGvv2OL-Q8S3AoLR2h055u8srwi39o">
        <Stack spacing={1}>
          {rows.map((row: ItemDNDType, index: number) => (
            <Stack key={index} spacing={1}>
              <DropZone
                data={rows}
                onDrop={(data: any, value: any) =>
                  reorderRows(data, value, index)
                }
                checkDrop={canDrop}
                className="dropzone-visible"
              />
              <CardLivePreviewRow row={row} updateRow={setRows} rows={rows} />
            </Stack>
          ))}
          <DropZone
            data={rows}
            onDrop={handleDrop}
            checkDrop={canDrop}
            className="dropzone-visible"
          />
        </Stack>
      </EdisonContentCard>
    </div>
  );
};

export const removeEmptyRows = (rows: ItemDNDType[]): ItemDNDType[] => {
  return rows.filter((row) => row.children && row.children.length !== 0);
};
export const incrementRowsPath = (rows: ItemDNDType[], increment: number) => {
  return rows.map((row: ItemDNDType) => {
    const updatedRootPath = parseInt(row.path as string) + increment;
    return {
      ...row,
      path: `${updatedRootPath}`,
      children: row.children?.map((child: ItemDNDType, index: number) => ({
        ...child,
        path: `${updatedRootPath}-${index}`,
      })),
    };
  });
};
export const getItemFromPath = (
  data: ItemDNDType[],
  path: string
): ItemDNDType => {
  const rowIndex = parseInt(path[0]);
  const childIndex = parseInt(path[2]);
  const children = data[rowIndex].children!;
  return children[childIndex] as unknown as ItemDNDType;
};

export const removeFromRow = (
  rows: ItemDNDType[],
  pathToRemove: string
): ItemDNDType[] => {
  const updated = rows.slice();
  const indexes = pathToRemove.split("-");
  const rowIndex = parseInt(indexes[0]);
  const children = updated[rowIndex].children;
  if (children) {
    updated[rowIndex].children = children
      .filter((child) => child.path !== pathToRemove)
      .map((child, index: number) => ({
        ...child,
        path: `${rowIndex}-${index}`,
      }));
    return updated;
  }
  return rows;
};
const addNewRow = (
  value: any,
  rootPath: number,
  index: number
): ItemDNDType => {
  const childId = nanoid();
  const childComponent: ItemDNDType = {
    id: childId,
    title: childId,
    type: COMPONENT,
    path: `${rootPath}-${index}`,
    component: value.component,
  };
  const parentId = nanoid();
  const parentZone: ItemDNDType = {
    id: parentId,
    title: parentId,
    type: ROW,
    path: rootPath.toString(),
    children: [childComponent],
  };
  return parentZone;
};
export default CardLivePreview;
