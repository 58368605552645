import {
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup
} from "@mui/material";
import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";

export interface Props {
  title: string;
  type: string;
  options: string[];
  defaultValue?: string;
  textData: any;
  horizontal: boolean;
}
const EdisonRadioGroupInput: FC<Props> = ({
  title,
  type,
  options,
  defaultValue,
  textData,
  horizontal,
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={type}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <Grid>
          <InputLabel>{title}</InputLabel>
          <RadioGroup
            name={type}
            value={value}
            onChange={onChange}
            row={horizontal}>
            {options.map((unit: string, index: number) => (
              <FormControlLabel
                value={unit}
                control={<Radio color="primary" />}
                label={!!textData[unit] ? textData[unit] : unit}
                key={index}
              />
            ))}
          </RadioGroup>
        </Grid>
      )}
    />
  );
};
export default EdisonRadioGroupInput;
