import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Button, IconButton, Stack } from "@mui/material";
import "antd/dist/antd.css";
import React, { FC, useEffect } from "react";
import { Node } from "react-flow-renderer";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  displayEventsType,
  selectCurrentElement,
  setDisplayEvent,
  updateElement,
} from "../../../../features/workflow/workflowSlice";
import { eventTypeList } from "../../../../../models/workflow/constants/Events.constant";
import WorkflowStage from "../../../../../models/workflow/WorkflowStage.model";
import WorkflowStageEvent from "../../../../../models/workflow/WorkflowStageEvent.model";
import { useAppSelector } from "../../../../store/hooks";
import EdisonTypography from "../../../../../app-shared/edison/typography/EdisonTypography";
import ExpandableMenu from "../../../expandableMenu/ExpandableMenu";
import updateNodeCustomData from "../../utils/updateNodeCustomData";
import "./nodeevents.scss";

const NodeEvents: FC = () => {
  const { t } = useTranslation(["admin"]);
  const currentNode = useAppSelector(selectCurrentElement);
  const dispatch = useDispatch();

  return (
    <ExpandableMenu title={t("events")}>
      <Stack direction="column" className="node-events-root" spacing={1}>
        {eventTypeList.map((eventType: displayEventsType, index: number) => (
          <Stack key={index} direction="column" spacing={1}>
            <EdisonTypography
              variant="t8"
              disableUppercase
              title={t(eventType)}
            />
            {(currentNode?.data as WorkflowStage).events
              ?.filter(
                (eventFilter: WorkflowStageEvent) =>
                  eventFilter.type === eventType
              )
              .map((event: WorkflowStageEvent, innerIndex: number) => (
                <Stack
                  key={innerIndex}
                  direction="row"
                  className="single-action-row">
                  <div className="action-title-container">
                    <EdisonTypography
                      variant="t7"
                      disableUppercase
                      title={t(event.action)}
                    />
                  </div>

                  <IconButton
                    onClick={() => {
                      const currentEvents = (currentNode?.data as WorkflowStage)
                        .events;
                      if (currentEvents) {
                        const updatedEvents = currentEvents.filter(
                          (eventFilter: WorkflowStageEvent) =>
                            !(
                              eventFilter.action === event.action &&
                              eventFilter.type === event.type
                            )
                        );
                        dispatch(
                          updateElement(
                            updateNodeCustomData(
                              "events",
                              updatedEvents,
                              currentNode as Node
                            )
                          )
                        );
                      }
                    }}>
                    <HighlightOffIcon />
                  </IconButton>
                </Stack>
              ))}
            <Button
              onClick={() => dispatch(setDisplayEvent(eventType))}
              className="add-event-button">
              <AddCircleOutlineIcon /> {t("addEvent")}
            </Button>
          </Stack>
        ))}
      </Stack>
    </ExpandableMenu>
  );
};

export default NodeEvents;
