import {
  Button,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from "@mui/material";
import { Parser } from "json2csv";
import React, { FC, useEffect, useState } from "react";
import EdisonSearchBar from "../searchbar/EdisonSearchBar";
import EdisonTableRow from "./row/EdisonTableRow";
import EdisonSortableTableHeader from "./sortableheader/EdisonSortableTableHeader";
import EdisonTypography from "../typography/EdisonTypography";
import "./edisontable.scss";
export interface TableButton {
  onClick: any;
  title: string;
  fill: boolean;
  visible: boolean;
  icon: any;
  type: string;
}
export interface EdisonTableProps {
  data: Array<any>;
  columns: any[];
  icons: any[];
  sortData: (data: any, order: any, orderBy: any) => any;
  buttons: TableButton[];
  showCheckBox: boolean;
  tableTitle?: string;
  animateSearchBar: boolean;
}
const EdisonTable: FC<EdisonTableProps> = ({
  data,
  columns,
  icons,
  sortData,
  buttons,
  showCheckBox,
  tableTitle,
  animateSearchBar,
}) => {
  const [searchFilter, setSearchFilter] = useState("");
  const [order, setOrder] = useState<string>("desc");
  const [orderBy, setOrderBy] = useState<string>(columns[0].reference);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const onRequestSort = (event: React.MouseEvent<unknown>, itemKey: any) => {
    const isAsc = orderBy === itemKey && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(itemKey);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    setPage(0);
  }, [data]);
  const getCurrentRows = () => {
    return sortData(
      filterDataBySearch(data, searchFilter, columns),
      order,
      orderBy
    ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };
  const isButtonDisabled = (type: string): boolean => {
    if (type === "edit") {
      return selectedRows.length !== 1;
    }
    if (type === "delete") {
      return selectedRows.length === 0;
    }

    return false;
  };

  const exportTableData = () => {
    const parser = new Parser();
    const csv = parser.parse(data);
    const blob = new Blob([csv], { type: "text/csv" });
    const a = document.createElement("a");
    a.download = tableTitle ? `${tableTitle}.csv` : "table.csv";
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
    console.log(csv);
  };
  return (
    <Stack className="edison-table-root">
      {tableTitle && (
        <EdisonTypography
          title={tableTitle}
          variant="t7"
          disableUppercase></EdisonTypography>
      )}
      <Stack direction="row" justifyContent={"space-between"}>
        <EdisonSearchBar
          searchFilter={searchFilter}
          setSearchFilter={setSearchFilter}
          animated={animateSearchBar}
          width="40em"
        />
        {buttons.length !== 0 && (
          <Stack direction="row" spacing={1}>
            {buttons
              .filter((button) => button.visible)
              .map((button, index) => (
                <Button
                  variant={button.fill ? "contained" : "outlined"}
                  style={
                    button.fill && !isButtonDisabled(button.type)
                      ? { color: "white" }
                      : {}
                  }
                  disableElevation
                  key={index}
                  disabled={isButtonDisabled(button.type)}
                  onClick={() =>
                    button.type === "export"
                      ? exportTableData()
                      : button.onClick(selectedRows)
                  }
                  startIcon={<button.icon />}>
                  {button.title}
                </Button>
              ))}
            {/* <TableFilter columns={columns} setFilter={{}} filterApplied={{}} /> */}
          </Stack>
        )}
      </Stack>
      <TableContainer>
        <Table>
          <EdisonSortableTableHeader
            onRequestSort={onRequestSort}
            columns={columns}
            order={order}
            orderBy={orderBy}
            icons={icons}
            checked={selectedRows.length === (getCurrentRows() as any[]).length}
            onChange={(checked: boolean) => {
              checked
                ? setSelectedRows(getCurrentRows().map((row: any) => row.id))
                : setSelectedRows([]);
            }}
            showCheckBox={showCheckBox}
          />
          <TableBody>
            {getCurrentRows().map((row: any, index: number) => (
              <EdisonTableRow
                key={index}
                row={row}
                columns={columns}
                icons={icons}
                isSelected={
                  selectedRows.filter((id) => id === row.id).length > 0
                }
                onChange={(checked: boolean) => {
                  checked
                    ? setSelectedRows([...selectedRows, row.id])
                    : setSelectedRows(
                        selectedRows.filter((id) => id !== row.id)
                      );
                }}
                showCheckBox={showCheckBox}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        classes={{
          displayedRows: "no-margin-bottom",
          selectLabel: "no-margin-bottom",
        }}
        rowsPerPageOptions={[
          5,
          10,
          25,
          {
            value: data.length,
            label: "All",
          },
        ]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Stack>
  );
};
const filterDataBySearch = (
  rows: any,
  searchFilter: string,
  columns: any[]
) => {
  if (searchFilter.length === 0) {
    return rows;
  }

  return rows.filter((row: any) => {
    let count = 0;
    columns.forEach((column) => {
      if (
        row[column.reference] &&
        (row[column.reference] as string)
          .toLowerCase()
          .includes(searchFilter.toLowerCase())
      ) {
        count += 1;
        return;
      }
    });

    return count !== 0;
  });
};

export default EdisonTable;
