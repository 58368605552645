const typographyTypes = [
  {
    id: 1,
    variantName: "t1",
    fontSize: 23,
    fontWeight: 700,
    letterSpacing: 5,
  },
  {
    id: 2,
    variantName: "t2",
    fontSize: 18,
    fontWeight: 500,
    letterSpacing: 5,
  },
  {
    id: 3,
    variantName: "t3",
    fontSize: 23,
    fontWeight: 300,
    letterSpacing: 0,
  },
  {
    id: 4,
    variantName: "t4",
    fontSize: 14,
    fontWeight: 700,
    letterSpacing: 5,
  },
  {
    id: 11,
    variantName: "t4a",
    fontSize: 14,
    fontWeight: 300,
    letterSpacing: 5,
  },
  {
    id: 5,
    variantName: "t5",
    fontSize: 13,
    fontWeight: 700,
    letterSpacing: 5,
  },
  {
    id: 6,
    variantName: "t6",
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 0.5,
  },
  {
    id: 7,
    variantName: "t7",
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0,
  },
  {
    id: 8,
    variantName: "t8",
    fontSize: 14,
    fontWeight: 300,
    letterSpacing: 0,
  },
  {
    id: 9,
    variantName: "t9",
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: 0.5,
  },

  {
    id: 10,
    variantName: "t10",
    fontSize: 11,
    fontWeight: 300,
    letterSpacing: 1.25,
  },
];

export default typographyTypes;
