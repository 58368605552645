import { withMsal } from "@azure/msal-react";
import React, { FC, ReactElement } from "react";
import Discover from "../../app-projects/pages/discover/Discover";
import EdisonCard from "../../app-shared/edison/card/EdisonCard";

// const EDProjectsRemote = React.lazy(() => import("EDProjects/ProjectsApp"));

// const EDProjects = withMsal(EDProjectsRemote);
const Projects: FC = (): ReactElement => {
  return (
    <div>
      <EdisonCard>
        <Discover />
      </EdisonCard>
    </div>
  );
};

export default Projects;
