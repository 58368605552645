import { Stack } from '@mui/material'
import React, { FC } from 'react'
import EdisonTypography from '../../../app-shared/edison/typography/EdisonTypography';


export interface LabeledDataProps{
    label: string;
    children: any;
}
const LabeledData:FC<LabeledDataProps> = ({label,children}) => {
  return (
   <Stack spacing={1}>
       <EdisonTypography title={label} variant="t2"/>
       <div>
           {children}
       </div>
       
   </Stack>
  )
}

export default LabeledData