import { Grid, InputAdornment, TextField } from "@mui/material";
import React, { FC, useState } from "react";
import BaseUserFieldProps from "../../models/BaseUserFieldProps.model";
import "./number.scss";

export type NumberType = "currency" | "number" | "percentage";
export interface UserNumberFieldProps extends BaseUserFieldProps {
  numberType: NumberType;
  configuration?: any;
  maxDecimalPlaces: number;
}

const UserNumberField: FC<UserNumberFieldProps> = ({
  label,
  value,
  disabled,
  readOnly,
  numberType,
  configuration,
  maxDecimalPlaces,
  isInTable,
  onChange,
  useInternalState,
  error,
}) => {
  const [internalState, setInternalState] = useState<any>(value);
  return (
    <div className="number">
      <TextField
        label={!readOnly && !isInTable && label}
        value={useInternalState ? internalState : value}
        onChange={(e) => {
          const newState = formatDecimals(e.target.value, maxDecimalPlaces);
          useInternalState ? setInternalState(newState) : onChange(newState);
        }}
        InputProps={{
          startAdornment: renderAdornment(numberType, configuration),
        }}
        type="number"
        disabled={disabled || readOnly}
        color="primary"
        helperText=""
        variant="outlined"
        error={error}
        className={readOnly ? "read-only" : ""}
      />
    </div>
  );
};

const formatDecimals = (originalDecimal: string, maxDecimalPlaces: number) => {
  const decimalSplit = originalDecimal.split(".");
  if (decimalSplit.length === 1 || decimalSplit[1].length < maxDecimalPlaces) {
    return originalDecimal;
  }
  const newDecimalString =
    decimalSplit[0] + "." + decimalSplit[1].substring(0, maxDecimalPlaces);

  return Number.parseFloat(newDecimalString).toFixed(maxDecimalPlaces);
};

const renderAdornment = (numberType: NumberType, configuration: any) => {
  const adornment = getAdornment(numberType, configuration);
  return !!adornment ? (
    <InputAdornment position="start">{adornment}</InputAdornment>
  ) : null;
};

const getAdornment = (numberType: NumberType, configuration: any) => {
  switch (numberType) {
    case "number":
      return null;
    case "currency":
      return configuration.currency;
    case "percentage":
      return "%";
    default:
      return null;
  }
};
export default UserNumberField;
