import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Stack } from "@mui/material";
import { nanoid } from "nanoid";
import React, { FC } from "react";
import { COMPONENT } from "../../../../config/constants";
import { selectSystemFields } from "../../../../features/fields/fieldsSlice";
import { ItemDNDType } from "../../../../../models/Livepreview/ItemDNDType.model";
import { useAppSelector } from "../../../../store/hooks";
import DropZone from "../../../livePreview/DropZone";
import UserComponent from "../../../livePreview/UserComponent";
import {
  getItemFromPath,
  incrementRowsPath,
  removeEmptyRows,
  removeFromRow,
} from "../CardLivePreview";
import "./cardlivepreviewrow.scss";
export interface CardLivePreviewRowProps {
  row: ItemDNDType;
  updateRow: React.Dispatch<React.SetStateAction<ItemDNDType[]>>;
  rows: ItemDNDType[];
}
const CardLivePreviewRow: FC<CardLivePreviewRowProps> = ({
  row,
  updateRow,
  rows,
}) => {
  const systemFields = useAppSelector(selectSystemFields);
  const handleDrop = (data: any, value: any) => {
    if (value.path) {
      //dropping from within card
      const toMove = getItemFromPath(data, value.path);
      const updated = removeFromRow(data, value.path);
      const moved = updated.map((map: ItemDNDType) => {
        if (map.path === row.path && map.children) {
          return {
            ...map,
            children: [
              ...map.children,
              { ...toMove, path: `${row.path}-${row.children?.length}` },
            ],
          };
        }
        return map;
      });
      updateRow(removeEmptyRows(moved));
    } else {
      // dropping from sidebar
      updateRow((prev) =>
        prev.map((map: ItemDNDType) => {
          if (map.path === row.path && map.children) {
            const childId = nanoid();
            const newChild: ItemDNDType = {
              id: childId,
              title: childId,
              type: COMPONENT,
              path: `${map.path}-1`,
              component: value.component,
            };
            return {
              ...map,
              children: [...map.children, newChild],
            };
          }
          return map;
        })
      );
    }
  };
  const canDrop = (item: any, monitor: any) => {
    return true;
  };

  return (
    <Stack direction={"row"} className={"card-live-preview-row-root"}>
      {row.children &&
        (row.children.length === 1 ? (
          <Stack direction="row" className="component-dropzone-container">
            <UserComponent
              components={systemFields}
              path={row.children[0].path!}
              data={row.children[0]}
              readOnly={true}
            />
            <DropZone
              data={rows}
              onDrop={handleDrop}
              checkDrop={canDrop}
              className="dropzone-visible"
            />
          </Stack>
        ) : (
          <Stack direction="row" spacing={1}>
            {row.children.map((child: ItemDNDType, index: number) => (
              <UserComponent
                key={index}
                components={systemFields}
                path={child.path!}
                data={child}
                readOnly={true}
              />
            ))}
          </Stack>
        ))}

      <CancelOutlinedIcon
        className="cancel-icon"
        fontSize="small"
        onClick={() => {
          const index = parseInt(row.path as string);
          const afterIndex = rows.slice(index + 1);
          const updated = [
            ...rows.slice(0, index),
            ...incrementRowsPath(afterIndex, -1),
          ];
          updateRow(updated);
        }}
      />
    </Stack>
  );
};

export default CardLivePreviewRow;
