import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getProjects } from "../../../services/APIService";

import Project from "../../models/backend/project/Project.model";
import { RootState } from "../store/store";

export interface projectsState {
  projects: Project[];
}

const initialState: projectsState = {
  projects: [],
};

export const getProjectsAsync:any = createAsyncThunk(
  "projects/getProjects",
  async () => {
    const response = (await getProjects() as any);

    return  response.data;
  }
);
export const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProjectsAsync.fulfilled, (state, action) => {
      state.projects = action.payload;
    });
  },
});

export const selectProjects = (state: RootState): Project[] =>
  (state.projects as projectsState).projects;
export default projectsSlice.reducer;
