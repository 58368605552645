import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import reducerStatus from "../../models/reducerStatus";
import { getAnnouncements } from "../../services/APIService";
import { RootState } from "../../store/store";

export interface announcement {
  title: string;
  id: string;
  startDate: string;
  endDate: string;
  url: string;
  message: string;
  detail: string;
  scope: number;
  priority: string;
}
export interface announcementsState {
  announcements: announcement[];
  status: reducerStatus;
}
const initialState: announcementsState = {
  announcements: [],
  status: "idle",
};

export const getAnnouncementsAsync = createAsyncThunk(
  "announcements/getAnnouncements",
  async () => {
    const response = await getAnnouncements();
    return response.data;
  }
);

export const announcementsSlice = createSlice({
  name: "announcements",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAnnouncementsAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getAnnouncementsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAnnouncementsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.announcements = action.payload;
      });
  },
});

export const {} = announcementsSlice.actions;

export const selectAllAnnouncements = (state: RootState): announcement[] => {

  return (state.announcements as announcementsState).announcements;
};

export default announcementsSlice.reducer;
