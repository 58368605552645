import { nodeWidth } from "../../../../models/workflow/constants/Node.constant";
import { WorkflowNodeConfiguration } from "../../../../models/workflow/WorkflowNode.model";
import { getId, getNodeIndex } from "../workflowzone/WorkFlowZone";
import { Node } from "react-flow-renderer";
import StageViewState from "../../../../models/workflow/constants/StageViewState.constant";

const getNewNode = (option: WorkflowNodeConfiguration, position: any): Node => {
  const id = getId();
  return {
    id: id,
    type: option.type,
    position,
    data: {
      id: id,
      displayName: `${option.type} node ${getNodeIndex()}`,
      viewState: 0,
      configuration: option,
    },
    style: {
      border: `2px solid ${option.color}`,
      borderRadius: "4px",
      minWidth: nodeWidth,
    },
  };
};
export default getNewNode;
