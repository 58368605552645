import { FormHelperText } from "@mui/material";
import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import BaseUserFormFieldProps from "../../models/BaseUserFormFieldProps.model";
import "./image.scss";
import UserImage, { UserImageProps } from "./UserImage";
export interface UserFormImageProps
  extends BaseUserFormFieldProps,
    UserImageProps {}
const UserFormImage: FC<UserFormImageProps> = ({
  label,
  required,
  disabled,
  requiredMessage,
  invalidMediaTypeMessage,
  readOnly,
  accept,
  defaultValue,
  name
}) => {
  const { control, setValue } = useFormContext();

  const onChange = (changeValue: any, shouldValidate = true) => {
    setValue(name, changeValue, {shouldValidate});
  };

  const validate = (
    required: boolean | undefined,
    value: any
  ): boolean => {
    if (!!required && value === undefined) {
      return false;
    }
    return true;
  };

  return (
  
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { value }, fieldState: { error } }) => (
            <div >
              <UserImage
                label={label}
                disabled={disabled}
                invalidMediaTypeMessage={invalidMediaTypeMessage}
                readOnly={readOnly}
                accept={accept}
                value={value}
                onChange={onChange}
                useInternalState={false}
              />
              <div className="form-helper-styling" hidden={!error}>
                <FormHelperText error={!!error}>
                  {!!required && requiredMessage}
                </FormHelperText>
              </div>
            </div>
        )}
        rules={{
          validate: (value?: any) => {
            return validate(required, value);
          },
        }}
      />
  );
};

export default UserFormImage;
