import { Stack } from "@mui/material";
import React, { FC, useCallback, useMemo, useState } from "react";
import { createEditor, Descendant } from "slate";
import { withHistory } from "slate-history";
import { Editable, Slate, withReact } from "slate-react";
import BaseUserFieldProps from "../../models/BaseUserFieldProps.model";
import Block, { BlockProps } from "./nodes/block/Block";
import Leaf, { LeafProps } from "./nodes/leaf/Leaf";
import RichTextFieldToolbar from "./RichTextFieldToolbar";
import { indent, outdent, toggleMark } from "./utils/richTextUtils";
import withImages from "./utils/withImages";
import withLinks from "./utils/withLink";
export interface UserRichTextFieldProps extends BaseUserFieldProps {
  initialValue: Descendant[];
}

const UserRichTextField: FC<UserRichTextFieldProps> = ({
  initialValue,
  useInternalState,
  value,
  onChange,
  readOnly,
}) => {
  const renderElement = useCallback(
    (props: BlockProps) => <Block {...props} />,
    []
  );
  const renderLeaf = useCallback((props: LeafProps) => <Leaf {...props} />, []);
  const editor = useMemo(
    () => withLinks(withImages(withHistory(withReact(createEditor())))),
    []
  );
  const [internalState, setInternalState] =
    useState<Descendant[]>(initialValue);
  return (
    <Slate
      editor={editor}
      value={useInternalState ? internalState : value}
      onChange={(newValue) =>
        useInternalState ? setInternalState(newValue) : onChange(newValue)
      }>
      {!readOnly ? (
        <>
          <RichTextFieldToolbar />
          <Editable
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            onKeyDown={(e) => {
              if (e.key === "Tab") {
                e.preventDefault();
                e.shiftKey ? outdent(editor) : indent(editor, "indented");
                return;
              }
              if (!e.ctrlKey) {
                return;
              }
              switch (e.key) {
                case "b":
                  e.preventDefault();
                  toggleMark(editor, "bold");
                  break;
                case "i":
                  e.preventDefault();
                  toggleMark(editor, "italic");
                  break;
                case "u":
                  e.preventDefault();
                  toggleMark(editor, "underline");
                  break;
                default:
                  break;
              }
            }}
          />
        </>
      ) : (
        <Stack>
          <Editable
            readOnly
            renderElement={renderElement}
            renderLeaf={renderLeaf}
          />
        </Stack>
      )}
    </Slate>
  );
};

export default UserRichTextField;
