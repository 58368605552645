import { User } from "@microsoft/microsoft-graph-types";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import OutlinedFlagIcon from "@mui/icons-material/OutlinedFlag";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { Stack } from "@mui/material";
import {
  selectGroupMembers,
  selectUserPhotos,
} from "../../../../../features/msGraph/msGraphSlice";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { getAllForms } from "../../../../features/forms/formsSlice";
import UserPicture from "../../../../../models/common/UserPicture.model";

import StageViewState from "../../../../../models/workflow/constants/StageViewState.constant";
import WorkflowStage from "../../../../../models/workflow/WorkflowStage.model";
import WorkflowStageReviewer from "../../../../../models/workflow/WorkflowStageReviewer.model";
import { useAppSelector } from "../../../../store/hooks";
import UserPeoplePicker from "../../../../../app-shared/user/peoplepicker/UserPeoplePicker";
import { getUserByEntityId, mapPeople } from "../../utils/userMapper";
import "./nodeinfo.scss";

export interface NodeInfoProps {
  data: any;
}
const NodeInfo: FC<NodeInfoProps> = ({ data }) => {
  const forms = useAppSelector(getAllForms);
  const { t } = useTranslation(["admin"]);
  const groupMembers: User[] = useAppSelector(selectGroupMembers);
  const userPictures: UserPicture[] = useAppSelector(selectUserPhotos);
  return (
    <div className="node-info-root">
      <Stack direction="column" spacing={2}>
        {["Stage", "StageReview"].includes(
          data.configuration.type as string
        ) && (
          <Stack direction="column" spacing={2}>
            <Stack direction="row" spacing={2}>
              <FeedOutlinedIcon />
              {forms.length > 0 ? (
                <div>
                  {(data as WorkflowStage).formId ? (
                    <div>
                      {
                        forms.find((form) => form.id === data.formId)
                          ?.displayName
                      }
                    </div>
                  ) : (
                    forms[0].displayName
                  )}
                </div>
              ) : (
                ""
              )}
            </Stack>
            {(data.autoProgress ||
              data.allowManagersApproval ||
              data.readOnly) && (
              <Stack direction="row" spacing={2}>
                <OutlinedFlagIcon />
                <div>
                  {generateFlagsString(
                    data.autoProgress,
                    data.allowManagersApproval,
                    data.readOnly,
                    t
                  )}
                </div>
              </Stack>
            )}

            {data.configuration.type === "StageReview" &&
              data.reviewers &&
              (data.reviewers as WorkflowStageReviewer[]).length > 0 && (
                <Stack direction="row" spacing={2}>
                  <div className="reviewers-info">
                    <PeopleAltOutlinedIcon />
                  </div>

                  <UserPeoplePicker
                    multiple={true}
                    isEditing={false}
                    maxAvatars={4}
                    peopleList={mapPeople(userPictures, groupMembers)}
                    value={
                      data?.reviewers
                        ? getUserByEntityId(
                            data.reviewers,
                            userPictures,
                            groupMembers
                          )
                        : []
                    }
                    useInternalState={false}
                    placeholder={""}
                    noOptionsText={""}
                    maxDropdownCount={5}
                    label={""}
                  />
                </Stack>
              )}
          </Stack>
        )}
        <Stack direction="row" spacing={2}>
          <VisibilityOffOutlinedIcon />{" "}
          <div>{StageViewState[data.viewState]}</div>
        </Stack>
      </Stack>
    </div>
  );
};

const generateFlagsString = (
  autoProgress: boolean,
  allowManagersApproval: boolean,
  readOnly: boolean,
  t: any
): string => {
  let baseString = "";

  if (autoProgress) {
    baseString = baseString + ` ${t("autoProgress")},`;
  }
  if (allowManagersApproval) {
    baseString = baseString + ` ${t("allowManagersApproval")},`;
  }
  if (readOnly) {
    baseString = baseString + ` ${t("readOnly")},`;
  }

  baseString = baseString.substring(0, baseString.length - 1);
  return baseString;
};

export default NodeInfo;
