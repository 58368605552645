import { graphConfig } from "../config/authConfig";

/**
 * Attaches a given access token to a Microsoft Graph API call. Returns information about the user
 */
export async function callMsGraphGet(accessToken: any, dataType: string) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(`${graphConfig.graphEndpoint}/${dataType}`, options)
    .then((response) => response)
    .catch((error) => console.log(error));
}

export async function callMsGraphPost(
  accessToken: any,
  queryString: string,
  body: any
) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "POST",
    headers: headers,
    body: body,
  };

  return fetch(`${graphConfig.graphEndpoint}/${queryString}`, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}
