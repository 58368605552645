import { User } from "@microsoft/microsoft-graph-types";
import UserPicture from "../../../../models/common/UserPicture.model";

export const getUserByEntityId = (
  reviewers: any,
  pictureList: UserPicture[],
  userList: User[]
): [] => {
  if (pictureList.length === 0 && userList.length === 0) {
    return [];
  }
  return reviewers.map((reviewer: any) => ({
    entityId: reviewer.entityId,
    userName: userList.find((user) => user.id === reviewer.entityId)
      ?.displayName,
    imageUrl: getUserPicture(pictureList, reviewer.entityId),
    status: "online",
    type: "User",
  }));
};
export const getUserPicture = (
  pictureList: UserPicture[],
  id?: string
): string => {
  const picture = pictureList.find(
    (userPicture: UserPicture) => userPicture.userId === id
  );
  return picture ? picture.photo : "incorrect";
};
export const mapPeople = (pictureList: UserPicture[], users: User[]) => {
  return users && pictureList
    ? users.map((user: User) => {
        return {
          entityId: user.id,
          userName: user.displayName,
          imageUrl: getUserPicture(pictureList, user.id),
          status: "online",
          type: "User",
        };
      })
    : [];
};
