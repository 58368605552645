import { User } from "@microsoft/microsoft-graph-types";
import { nanoid } from "nanoid";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { mapPeople } from "../components/workflowdesigner/utils/userMapper";
import { currencyCodeToSymbol } from "../config/currencies";

import Item from "../../models/Item.model";
import UserCheckBoxGroup from "../../app-shared/user/checkboxgroup/UserCheckBoxGroup";
import UserDatePicker from "../../app-shared/user/datepicker/UserDatePicker";
import UserImage from "../../app-shared/user/image/UserImage";
import UserRichTextField from "../../app-shared/user/richtext/UserRichTextField";
import UserSwitchField from "../../app-shared/user/switch/UserSwitchField";
import UserTextField from "../../app-shared/user/textfield/UserTextField";
import UserURLField from "../../app-shared/user/url/UserURLField";
import StandardTable from "../../app-shared/user/tables/standard/table/StandardTable";
import UserTaskTable from "../../app-shared/user/tables/task/table/TaskTable";
// import {
//   StandardTable,

//   UserTaskTable,
// } from "../utils/componentLoader";
import EdisonButton from "../../app-shared/edison/button/EdisonButton";
import EdisonFormInput from "../../app-shared/edison/forminput/EdisonFormInput";
import UserNumberField from "../../app-shared/user/number/UserNumberField";
import UserPeoplePicker from "../../app-shared/user/peoplepicker/UserPeoplePicker";

import { Choice, SharedChoice } from "../../models/multiChoiceField.model";
import UserPicture from "../../models/common/UserPicture.model";
const useTypeToComponentMapper = (
  readOnly: boolean,
  component: Item,
  groupMembers: User[],
  userPictures: UserPicture[],
  value?: any,
  setValue?: any,
  useInternalState = true ///TODO Add logic depending on if we want to use internal state or not
): ReactNode => {
  const { t } = useTranslation(["admin"]);

  switch (component.dataType) {
    case "TextBox":
      return (
        <UserTextField
          {...component.configuration}
          name={component.name}
          label={component.displayName}
          id={nanoid()}
          useInternalState={useInternalState}
          readOnly={readOnly}
          value={value ? value : "Text field text"}
        />
      );
    case "Number":
      return (
        <UserNumberField
          {...component.configuration}
          numberType={component.configuration.format}
          label={component.displayName}
          id={nanoid()}
          configuration={
            component.configuration.format === "currency"
              ? {
                  ...component.configuration,
                  currency: currencyCodeToSymbol(
                    component.configuration.currency
                  ),
                }
              : component.configuration
          }
          useInternalState={useInternalState}
          readOnly={readOnly}
          value={value ? value : 3}
        />
      );
    case "Button":
      return (
        <EdisonButton
          // configuration={component.configuration}
          name={component.displayName}
        />
      );
    case "Switch":
      return (
        <UserSwitchField
          {...component.configuration}
          name={component.displayName}
          label={component.displayName}
          useInternalState={useInternalState}
          readOnly={readOnly}
          value={value ? value : true}
        />
      );
    case "Url":
      return (
        <UserURLField
          {...component.configuration}
          name={component.displayName}
          label={component.displayName}
          useInternalState={useInternalState}
          readOnly={readOnly}
          value={value ? value : { title: "google", url: "https://google.com" }}
        />
      );
    case "Date":
      return (
        <UserDatePicker
          {...component.configuration}
          name={component.displayName}
          label={component.displayName}
          useInternalState={useInternalState}
          readOnly={readOnly}
          value={value ? value : new Date()}
        />
      );
    case "RichText":
      return (
        <UserRichTextField
          {...component.configuration}
          name={component.displayName}
          label={component.displayName}
          useInternalState={useInternalState}
          initialValue={[
            {
              type: "paragraph",

              children: [{ text: "A line of text in a paragraph." }],
            },
          ]} //TODO: update initial value when getting data from backend
          readOnly={readOnly}
        />
      );
    case "Image":
      return (
        <UserImage
          {...component.configuration}
          name={component.displayName}
          label={component.displayName}
          useInternalState={useInternalState}
          readOnly={readOnly}
          value={
            value ? value : { preview: "https://via.placeholder.com/150/" }
          }
        />
      );
    case "People":
      return (
        <UserPeoplePicker
          multiple={true}
          label={component.displayName}
          placeholder={component.displayName}
          noOptionsText={"a"}
          maxAvatars={4}
          maxDropdownCount={10}
          peopleList={mapPeople(userPictures, groupMembers)}
          useInternalState={useInternalState}
          isEditing={!readOnly}
          value={
            value ? value : mapPeople(userPictures, groupMembers).slice(0, 4)
          }
        />
      );

    case "MultiChoice":
      return (
        <UserCheckBoxGroup
          // id={nanoid()}
          label={component.displayName}
          choices={parseMultiChoiceLayout(component.configuration.choiceList)}
          multiLevel={true}
          disabled={false}
          useInternalState={useInternalState}
          readOnly={readOnly}
          value={
            value
              ? value
              : [
                  parseMultiChoiceLayout(component.configuration.choiceList)[0]
                    .value,
                ]
          }
        />
      );

    // case "Standard":
    //   // TODO: Hardcoded for all hands meeting, waiting for bryntum tables
    //   return (
    //     <StandardTable
    //       id={nanoid()}
    //       isEditing={true}
    //       columns={[
    //         { name: "id", type: "string" },
    //         {
    //           name: "number1",
    //           type: "number",
    //           label: "Number1",
    //           disabled: false,
    //           required: true,
    //           requiredMessage: "Number Field is required.",
    //           numberType: "currency",
    //           configuration: {
    //             currency: "$",
    //           },

    //           maxDecimalPlaces: 3,
    //         },
    //         {
    //           name: "text1",
    //           type: "text",
    //           label: "Text1",
    //           disabled: false,
    //           required: true,
    //           requiredMessage: "Text Field is required.",
    //         },
    //         {
    //           name: "date1",
    //           type: "date",
    //           disabled: false,
    //           required: "true",
    //           requiredMessage: "Please select a date",
    //           invalidDateMessage: "Invalid date inputed",
    //         },
    //         {
    //           name: "switch1",
    //           type: "switch",
    //           disabled: false,
    //           onLabel: "On",
    //           offLabel: "Off",
    //         },
    //         {
    //           name: "checkbox1",
    //           type: "checkbox",
    //           label: "Checkbox Group Field",
    //           choices: [
    //             {
    //               label: "Checkbox 1",
    //               value: "Checkbox1",
    //             },
    //             {
    //               label: "Checkbox 2",
    //               value: "Checkbox2",
    //             },
    //           ],
    //           disabled: false,
    //           required: false,
    //           requiredMessage: "At least one field is required",
    //         },

    //         {
    //           name: "multi1",
    //           type: "checkbox",
    //           label: "Checkbox Group Field",
    //           multiLevel: true,
    //           disabled: false,
    //           required: false,
    //           requiredMessage: "At least one field is required",
    //           choices: [
    //             {
    //               label: "Checkbox 1",
    //               value: "Checkbox1",
    //               children: [
    //                 {
    //                   label: "Checkbox 1 1",
    //                   value: "Checkbox11",
    //                   children: [],
    //                 },
    //                 {
    //                   label: "Checkbox 1 2",
    //                   value: "Checkbox12",
    //                   children: [],
    //                 },
    //               ],
    //             },
    //             {
    //               label: "Checkbox 2",
    //               value: "Checkbox2",
    //               children: [],
    //             },
    //             {
    //               label: "Checkbox 3",
    //               value: "Checkbox3",
    //               children: [],
    //             },
    //           ],
    //         },
    //         {
    //           name: "url1",
    //           type: "url",
    //           label: "URL Field",
    //           titleInputPlaceholder: "",
    //           URLInputPlaceholder: "",
    //           disabled: false,
    //           required: false,
    //           requiredMessage: "Required field",
    //         },
    //       ]}
    //       data={[
    //         {
    //           id: 1,
    //           number1: {
    //             id: "1",
    //             value: 1.001,
    //           },
    //           text1: {
    //             id: "5",
    //             value: "apple",
    //           },
    //           date1: {
    //             id: "7",
    //             value: "12/25/2012",
    //           },
    //           switch1: {
    //             id: "9",
    //             value: true,
    //           },
    //           checkbox1: {
    //             id: "11",
    //             value: ["Checkbox2"],
    //           },
    //           multi1: {
    //             id: "15",
    //             value: ["Checkbox11"],
    //           },
    //           url1: {
    //             id: "13",
    //             value: {
    //               title: "google",
    //               url: "www.google.com",
    //             },
    //           },
    //         },
    //         {
    //           id: 2,
    //           number1: {
    //             id: "3",
    //             value: 1.003,
    //           },
    //           text1: {
    //             id: "6",
    //             value: "mango",
    //           },
    //           date1: {
    //             id: "8",
    //             value: "11/13/2012",
    //           },
    //           switch1: {
    //             id: "10",
    //             value: false,
    //           },
    //           checkbox1: {
    //             id: "12",
    //             value: ["Checkbox1"],
    //           },
    //           multi1: {
    //             id: "16",
    //             value: ["Checkbox2"],
    //           },
    //           url1: {
    //             id: "14",
    //             value: {
    //               title: "amazon",
    //               url: "www.amazon.com",
    //             },
    //           },
    //         },
    //       ]}
    //     />
    //   );

    // case "Task":
    //   return (
    //     <UserTaskTable
    //       isEditing={true}
    //       showCriticalPaths={true}
    //       showTimeline={false}
    //       showBoardView={false}
    //       taskViewText={t("taskView")}
    //       boardViewText={t("boardView")}
    //       undoText={t("undo")}
    //       redoText={t("redo")}
    //       zoomInText={t("zoomIn")}
    //       zoomOutText={t("zoomOut")}
    //       zoomToFitText={t("zoomToFit")}
    //       addCardText={t("addCard")}
    //       viewCardText={t("viewCard")}
    //       editCardText={t("editCard")}
    //       removeCardText={t("removeCard")}
    //       columns={[
    //         {
    //           name: "id",
    //           type: "number",
    //           label: "Id",
    //           disabled: true,
    //           hidden: false,
    //         },
    //         {
    //           name: "name",
    //           type: "text",
    //           label: "Name",
    //           disabled: false,
    //           required: true,
    //           requiredMessage: "Text Field is required.",
    //           hidden: false,
    //         },
    //         {
    //           name: "startDate",
    //           type: "date",
    //           label: "Start Date",
    //           disabled: false,
    //           required: true,
    //           requiredMessage: "Please select a date",
    //           invalidDateMessage: "Invalid date inputed",
    //           hidden: false,
    //         },
    //         {
    //           name: "endDate",
    //           type: "date",
    //           label: "End Date",
    //           disabled: false,
    //           required: true,
    //           requiredMessage: "Please select a date",
    //           invalidDateMessage: "Invalid date inputed",
    //           hidden: false,
    //         },
    //         {
    //           name: "duration",
    //           type: "number",
    //           label: "Duration",
    //           disabled: false,
    //           hidden: false,
    //         },
    //         {
    //           name: "percentDone",
    //           type: "number",
    //           label: "% Complete",
    //           disabled: false,
    //           hidden: false,
    //         },
    //         {
    //           name: "wbs",
    //           type: "number",
    //           label: "Wbs",
    //           disabled: false,
    //           hidden: false,
    //         },
    //         /*{
    //           name: "effort",
    //           type: "number",
    //           label: "Effort",
    //           disabled: false,
    //           hidden: false,
    //         },
    //         {
    //           name: "predecessor",
    //           label: "Predecessor",
    //           disabled: false,
    //           hidden: false,
    //         },
    //         {
    //           name: "successor",
    //           label: "Successor",
    //           disabled: false,
    //           hidden: false,
    //         },
    //         {
    //           name: "schedulingmodecolumn",
    //           label: "Scheduling Mode",
    //           disabled: false,
    //           hidden: false,
    //         },
    //         {
    //           name: "constrainttype",
    //           label: "Constrain Type",
    //           disabled: false,
    //           hidden: false,
    //         },
    //         {
    //           name: "constraintdate",
    //           label: "Constraint Date",
    //           disabled: false,
    //           hidden: false,
    //         },
    //         {
    //           name: "manuallyscheduled",
    //           label: "Manually Scheduled",
    //           type: "boolean",
    //           disabled: false,
    //           hidden: false,
    //         },*/
    //       ]}
    //       data={[
    //         {
    //           id: 1,
    //           name: "Setup web server",
    //           percentDone: 25,
    //           duration: 9,
    //           startDate: "2021-01-14",
    //           rollup: true,
    //           endDate: "2021-01-23",
    //           customField: "T 1",
    //           deadlinedate: "2021-01-25",
    //           showInTimeline: true,
    //           status: "inprogress",
    //         },
    //         {
    //           id: 2,
    //           name: "Configure firewall",
    //           percentDone: 50,
    //           startDate: "2021-01-14",
    //           rollup: true,
    //           duration: 3,
    //           endDate: "2021-01-17",
    //           deadlineDate: "2021-01-19",
    //           customField: "T 2",
    //           showInTimeline: true,
    //           status: "done",
    //         },
    //         {
    //           id: 3,
    //           name: "Setup load balancer",
    //           percentDone: 75,
    //           startDate: "2021-01-14",
    //           rollup: true,
    //           duration: 3,
    //           endDate: "2021-01-17",
    //           customField: "T 3",
    //           showInTimeline: true,
    //           status: "done",
    //         },
    //         {
    //           id: 4,
    //           name: "Run tests",
    //           percentDone: 100,
    //           startDate: "2021-01-23",
    //           rollup: true,
    //           duration: 21,
    //           endDate: "2021-02-13",
    //           customField: "T 4",
    //           showInTimeline: true,
    //           status: "inprogress",
    //           children: [
    //             {
    //               id: 5,
    //               name: "Website Design",
    //               percentDone: 100,
    //               startDate: "2021-01-23",
    //               rollup: true,
    //               duration: 21,
    //               endDate: "2021-02-13",
    //               customField: "T 5",
    //               status: "todo",
    //             },
    //           ],
    //         },
    //         {
    //           id: 6,
    //           name: "Inform management about decision",
    //           percentDone: 0,
    //           startDate: "2021-01-14",
    //           rollup: true,
    //           duration: 2,
    //           endDate: "2021-01-16",
    //           cost: 5000,
    //           status: "inprogress",
    //           baselines: [
    //             { startDate: "2021-01-14", endDate: "2021-01-15" },
    //             { startDate: "2021-01-14", endDate: "2021-01-15" },
    //           ],
    //         },
    //         {
    //           id: 7,
    //           name: "Website Design 11",
    //           percentDone: 100,
    //           startDate: "2021-01-23",
    //           rollup: true,
    //           duration: 21,
    //           endDate: "2021-02-13",
    //           customField: "T 1",
    //           inactive: true,
    //           status: "todo",
    //         },
    //       ]}
    //       dependenciesData={[{ id: 1, fromTask: 2, toTask: 3 }]}
    //     />
    //   );

    default:
      return (
        <EdisonFormInput
          disabled={false}
          name="field"
          label={"field"}
          defaultValue={""}
          requiredMessage={"descriptionRequiredMessage"}
        />
      );
  }
};

const parseMultiChoiceLayout = (choiceList: Choice[]): SharedChoice[] => {
  return choiceList
    .filter((choice) => choice.path.length === 1)
    .map((choice) => ({
      label: choice.label,
      value: choice.path,
      children: recurseGetChildren(choiceList, choice.path),
    }));
};
const recurseGetChildren = (
  choiceList: Choice[],
  parentPath: string
): SharedChoice[] => {
  const directChildren = choiceList.filter(
    (choice) =>
      choice.path.startsWith(parentPath) &&
      choice.path !== parentPath &&
      choice.path.length === parentPath.length + 2
  );
  if (directChildren.length === 0) {
    return [];
  }
  return directChildren.map((choice) => ({
    label: choice.label,
    value: choice.path,
    children: recurseGetChildren(choiceList, choice.path),
  }));
};

export default useTypeToComponentMapper;
