import { List } from "@mui/material";
import React, { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { routes } from "../../config";
import RouteItem from "../../models/RouteItem.model";
import MenuItem from "../MenuItem/MenuItem";

interface Props {
  open: boolean;
}

const NavigationMenu: FC<Props> = ({ open }): ReactElement => {



  const { t } = useTranslation(["common"]);
  return (
    
      <List>
        {routes
          .filter((route: RouteItem) => !route.isApp)
          .map((route: RouteItem) => (
            <MenuItem
              key={`${route.key}`}
              title={t(route.title??"")}
              icon={route.icon}
              tooltip={t(route.tooltip??"")}
              path={route.path}
              enabled={route.enabled}
              component={route.component}
              subRoutes={route.subRoutes}
            />
          ))}
      </List>
  );
};

export default NavigationMenu;
