

import { Avatar, Skeleton} from "@mui/material";
import React, { FC } from "react";
import "./skeletonactivity.scss";

export interface Props {
  className?: string;
  rows: number;
}

const EdisonSkeletonActivity: FC<Props> = ({ className, rows }) => {
  const skeletonRows = Array.from({ length: rows }, (_, i) => i);

  return (
    <div className={` ${className}`}>
      {skeletonRows.map((row) => (
        <div key={row} className="skeleton-activity">
          <Skeleton >
            <Avatar />
          </Skeleton>
          <Skeleton animation="wave" />
        </div>
      ))}
    </div>
  );
};

export default EdisonSkeletonActivity;
