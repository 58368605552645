import React, { FC } from 'react'
import EdisonCard from '../../../app-shared/edison/card/EdisonCard'
import Discover from '../discover/Discover'

const Projects:FC = () => {
  return (
    <div><EdisonCard> <Discover/></EdisonCard></div>
  )
}

export default Projects