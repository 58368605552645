import { InputLabel, MenuItem, Select } from "@mui/material";
import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import "./formselect.scss";
import "./../../../App.scss";

export interface EdisonFormSelectProps {
  name: string;
  defaultValue: string | any;
  label: string;
  isDisabled: boolean;
  requiredMessage: string;
  data: Array<string> | Array<any>;
  fullWidth?: boolean;
  size?: "small" | "medium";
}

const EdisonFormSelect: FC<EdisonFormSelectProps> = ({
  name,
  defaultValue,
  label,
  isDisabled,
  requiredMessage,
  data,
  fullWidth,
  size,
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <div>
          <InputLabel>{label}</InputLabel>
          <Select
            id={name}
            fullWidth={fullWidth}
            size={size}
            variant="outlined"
            color="primary"
            className="select no-label-input"
            value={value}
            disabled={isDisabled}
            onChange={onChange}
            error={!!error}>
            {(data as any[]).map((record, index) => (
              <MenuItem key={record.id} value={index}>
                {!!record.icon && (
                  <span className="edison-select_icon">{record.icon}</span>
                )}
                {record.displayName}
              </MenuItem>
            ))}
          </Select>
        </div>
      )}
      rules={{ required: requiredMessage }}
    />
  );
};

export default EdisonFormSelect;
