import { TextField } from "@mui/material";
import React, { FC, useState } from "react";
import BaseUserFieldProps from "../../models/BaseUserFieldProps.model";
import "./textfield.scss";

export interface UserTextFieldProps extends BaseUserFieldProps {
  requiredMessage?: string;
  multiline?: boolean;
  rows?: number;
  maxLength?: number;
}

const UserTextField: FC<UserTextFieldProps> = ({
  label,
  value,
  multiline,
  rows,
  disabled,
  readOnly,
  maxLength,
  isInTable,
  useInternalState,
  onChange,
  error,
}) => {
  const [internalState, setInternalState] = useState(value);
  const getCurrentValue = () => (useInternalState ? internalState : value);
  return (
    <div className="user-text-field">
      <TextField
        variant="outlined"
        type="text"
        label={!isInTable && !readOnly && label}
        fullWidth
        value={getCurrentValue()}
        onChange={(e) =>
          useInternalState
            ? setInternalState(e.target.value)
            : onChange(e.target.value)
        }
        helperText=""
        multiline={multiline}
        rows={rows}
        disabled={disabled || readOnly}
        inputProps={{
          maxLength: maxLength,
          readOnly: readOnly,
        }}
        error={error}
        className={readOnly ? "read-only" : ""}
      />
    </div>
  );
};

export default UserTextField;
