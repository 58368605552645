import { Stack } from "@mui/material";
import React, { FC } from "react";
import Container from "../../models/backend/form/Container.model";
import Zone from "../../models/backend/form/Zone.model";
import Row from "./Row";
export interface SingleTabProps {
  container: Container;
}
const SingleTab: FC<SingleTabProps> = ({ container }) => {
  return (
    <Stack spacing={3}>

      {container?.zones?.map((zone: Zone, index) => (
        <Row key={index} zone={zone} />
      ))}
    </Stack>
  );
};

export default SingleTab;
