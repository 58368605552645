import { Grid } from "@mui/material";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import DataTypes from "../../models/TableTypes/PeriodicTableFields/DataTypes.enum";
import DefaultViews from "../../models/TableTypes/PeriodicTableFields/DefaultViews.enum";
import PeriodUnits from "../../models/TableTypes/PeriodicTableFields/PeriodUnits.enum";
import EdisonRadioGroupInput from "../../app-shared/edison/radiogroupinput/EdisonRadioGroupInput";

interface Props {
  defaultConfiguration?: any;
}

const PeriodicTableInputs: FC<Props> = ({ defaultConfiguration }) => {
  const { t } = useTranslation(["admin"]);
  return (
    <Grid>
      <EdisonRadioGroupInput
        title={t("periodicUnits")}
        type="periodicUnits"
        options={Object.keys(PeriodUnits).filter((unit) => isNaN(Number(unit)))}
        defaultValue={
          !!defaultConfiguration.periodicUnits
            ? defaultConfiguration.periodicUnits
            : "weeks"
        }
        horizontal={true}
        textData={{
          weeks: t("weeks"),
          months: t("months"),
          quarters: t("quarters"),
          years: t("years"),
        }}
      />
      <EdisonRadioGroupInput
        title={t("defaultView")}
        type="defaultView"
        options={Object.keys(DefaultViews).filter((unit) =>
          isNaN(Number(unit))
        )}
        defaultValue={
          !!defaultConfiguration.defaultView
            ? defaultConfiguration.defaultView
            : "periodic"
        }
        horizontal={true}
        textData={{
          periodic: t("periodic"),
          details: t("details"),
        }}
      />
      <EdisonRadioGroupInput
        title={t("dataType")}
        type="dataTypeUnit"
        options={Object.keys(DataTypes).filter((unit) => isNaN(Number(unit)))}
        defaultValue={
          !!defaultConfiguration.dataTypeUnit
            ? defaultConfiguration.dataTypeUnit
            : "cost"
        }
        horizontal={true}
        textData={{
          cost: t("cost"),
          effort: t("effort"),
          material: t("material"),
        }}
      />
    </Grid>
  );
};
export default PeriodicTableInputs;
