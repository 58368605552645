import FieldItem from "../../../../models/FieldTypes/FieldItem.model";

const getAvailableFields = (
  calculatedFields: FieldItem[],
  numberFields: FieldItem[],
  fieldToEdit?: FieldItem,
  isEdit?: boolean
): FieldItem[] => {
  return numberFields.concat(
    calculatedFields.filter((field) => {
      if (isEdit && fieldToEdit) {
        if (fieldToEdit.id === field.id) {
          return false;
        }
        if (
          field.fieldDependencies &&
          field.fieldDependencies
            .map((deps) => deps.fieldId)
            .includes(fieldToEdit.id)
        ) {
          return false;
        }
      }
      return true;
    })
  );
};
export default getAvailableFields;
