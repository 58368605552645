import { Grid, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React, { FC } from "react";
import {
  addBlockToConditionList,
  selectConditionList,
} from "../../../features/fields/calculatedFieldSlice";
import { ConditionBlock } from "../../../../models/calculatedfield/calculatedfield.model";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { ConditionRow } from "./ConditionRow";

const ConditionExpression: FC = ({}) => {
  const conditionList = useAppSelector(selectConditionList);
  const dispatch = useAppDispatch();
  return (
    <Grid container direction="column">
      {conditionList.map(
        (conditionBlock: ConditionBlock, conditionListIndex: number) => (
          <Grid key={conditionListIndex} item container direction="column">
            <ConditionRow
              expressionList={
                conditionBlock.conditionType === "else"
                  ? conditionBlock.resultBlock
                  : conditionBlock.conditionBlock
              }
              conditionListIndex={conditionListIndex}
              rowType={
                conditionBlock.conditionType === "else" ? "else" : "condition"
              }
              conditionType={conditionBlock.conditionType}
            />

            {["if", "elseIf"].includes(conditionBlock.conditionType) && (
              <ConditionRow
                expressionList={conditionBlock.resultBlock}
                conditionListIndex={conditionListIndex}
                rowType="then"
                conditionType={conditionBlock.conditionType}
              />
            )}
          </Grid>
        )
      )}
      <Grid item>
        <IconButton
          onClick={() => dispatch(addBlockToConditionList())}
          disabled={
            conditionList[conditionList.length - 1].conditionType === "else"
          }>
          <AddIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default ConditionExpression;
