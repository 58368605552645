import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ShareIcon from "@mui/icons-material/Share";
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { FC, useState } from "react";
import "./contentcard.scss";

export interface EdisonContentCardProps {
  id: string;
  title: string;
  description: string;
  createdDate: string;
  isCompact: boolean;
  imageSource: string;
  children?: any;
}
const EdisonContentCard: FC<EdisonContentCardProps> = ({
  id,
  title,
  description,
  createdDate,
  isCompact,
  imageSource,
  children,
}) => {
  const [expanded, setExpanded] = useState(false);
  const zonePlaceHolderList = [1, 2, 3];
  return (
    <Card className="content-card" elevation={0} variant="outlined">
      <CardHeader
        avatar={
          <Avatar aria-label="recipe">
            <img src={imageSource} className="icon-image"></img>
          </Avatar>
        }
        title={<strong className="title">{title}</strong>}
        subheader={createdDate}
        className="title-container"
      />
      <CardMedia
        component="img"
        height="194"
        image={imageSource}
        alt="Card Image"
      />
      <CardContent>
        <Typography variant="body2" className="description-box">
          {description}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <Grid item container direction="row" className="icons-row">
          <div>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => console.log("clicked")}>
              <EditIcon />
            </IconButton>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => console.log("clicked")}>
              <ShareIcon />
            </IconButton>
          </div>
          {!isCompact && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setExpanded((prev) => !prev)}>
              {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </Grid>
      </CardActions>
      {expanded && (
        <Grid className="zone-box">
          {children}
          {/* <Grid
            container
            direction="column"
            className="placeholder-zone-container">
            {zonePlaceHolderList.map((zone: number, index: number) => (
              <Grid item key={index} className="placeholder-zone">
                ZONE {zone}
              </Grid>
            ))}
          </Grid> */}

          {/* <UserZone {...zoneProps} /> */}
        </Grid>
      )}
    </Card>
  );
};

export default EdisonContentCard;
