import { Button, Typography } from "@mui/material";
import React, { FC, ReactNode } from "react";



export interface EdisonButtonProps {
  name: string;
  type?: any;
  onClick?: any;
  disabled?: boolean;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  backgroundColor?: string;
  fill?: boolean;
}
const EdisonButton: FC<EdisonButtonProps> = ({
  name,
  type,
  onClick,
  disabled,
  startIcon,
  endIcon,
  backgroundColor,
  fill,
}) => {

  return (
    <Button
      variant={fill ? "contained" : "outlined"}
      color="primary"
      type={type}
      onClick={onClick}
      disabled={disabled}
      startIcon={startIcon}
      endIcon={endIcon}
      style={backgroundColor? {backgroundColor: backgroundColor }: {}}>
      <Typography>{name}</Typography>
    </Button>
  );
};

export default EdisonButton;
