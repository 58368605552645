import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postProject } from "../../services/APIService";
import { RootState } from "../../store/store";

export interface projectState {
  currentProject: any;
}

const initialState: projectState = {
  currentProject: {},
};

export const createProjectAsync: any = createAsyncThunk(
  "projects/createProject",
  async (project: any) => {
      const response = await postProject(project)
      return (response as any).data;
  }
);
export const projectSlice = createSlice({
  name: "containerProject",
  initialState,
  reducers: {
    setCurrentProject: (state, action) => {
      state.currentProject = action.payload;
    },
  },
});
export const { setCurrentProject } = projectSlice.actions;

export const selectCurrentProject = (state: RootState): any => {
  return (state.containerProject as projectState).currentProject;
};
export default projectSlice.reducer;
