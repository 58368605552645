import ColumnModel from "../../models/Column.model";

const getItemListColumns = (t: any): ColumnModel[] => [
  {
    id: "1",
    name: t("label"),
    reference: "displayName",
  },
  {
    id: 2,
    name: t("type"),
    reference: "dataType",
  },
  {
    id: 3,
    name: t("internalName"),
    reference: "name",
  },
  {
    id: 4,
    name: t("lastUpdated"),
    reference: "modified",
  },
];
export default getItemListColumns;
