import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import EdisonFormInput from "../../app-shared/edison/forminput/EdisonFormInput";
import removeWhitespaces from "../utils/removeWhitespaces";

interface Props {
  name: string;
  label: string;
  internalName: string | undefined;
  defaultValue: string;
  hasIDField: boolean;
  requiredMessage: string;
  multiline?: boolean;
  rows?: number;
  maxLength?: number;
}

const AdminFormInput: FC<Props> = ({
  name,
  label,
  internalName,
  defaultValue,
  hasIDField,
  requiredMessage,
  multiline,
  rows,
  maxLength,
}) => {
  const { t } = useTranslation(["admin"]);
  const { watch } = useFormContext();
  return (
    <>
      <EdisonFormInput
        name={name}
        label={label}
        defaultValue={defaultValue}
        rows={rows}
        requiredMessage={requiredMessage}
        multiline={multiline}
        disabled={false}
        maxLength={maxLength}
      />
      {!!hasIDField && (
        <EdisonFormInput
          name={`${name}InternalName`}
          label={t("internalName")}
          defaultValue={defaultValue}
          multiline={false}
          disabled={true}
          inheritedValue={
            !!internalName ? internalName : removeWhitespaces(watch(name))
          }
        />
      )}
    </>
  );
};

export default AdminFormInput;
