import { User } from "@microsoft/microsoft-graph-types";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import {
  selectGroupMembers,
  selectUserPhotos,
} from "../../../features/msGraph/msGraphSlice";
import React, { FC, useRef } from "react";
import { useDrag } from "react-dnd";
import { COMPONENT } from "../../config/constants";
import UserPicture from "../../../models/common/UserPicture.model";

import { useAppSelector } from "../../store/hooks";
import useTypeToComponentMapper from "../../utils/useTypeToComponentMapper";
import "./usercomponent.scss";

export interface UserComponentProps {
  data: any;
  components: any;
  path: string;
  removeComponent?: any;
  readOnly: boolean;
}

const UserComponent: FC<UserComponentProps> = ({
  data,
  components,
  path,
  removeComponent,
  readOnly,
}) => {
  const ref = useRef(null);
  const groupMembers: User[] = useAppSelector(selectGroupMembers);
  const userPictures: UserPicture[] = useAppSelector(selectUserPhotos);
  const [value, setValue] = React.useState();

  // const currentComponent = useAppSelector((state: RootState) =>
  //   selectFieldById(state, data.component.id || 0)
  // );
  const [{ isDragging }, drag] = useDrag({
    type: COMPONENT,
    item: { type: COMPONENT, id: data.id, path },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(ref);

  return (
    <div className="usercomponent-parent">
      <div ref={ref} style={{ opacity }} className="usercomponent draggable">
        {useTypeToComponentMapper(
          readOnly,
          !!data.component ? data.component : components[data.id],
          groupMembers,
          userPictures,
          value,
          setValue
        )}
      </div>
      {!!removeComponent && (
        <CancelOutlinedIcon
          className={`pointer`}
          fontSize="small"
          onClick={() => removeComponent(path, data.id)}
        />
      )}
    </div>
  );
};
export default UserComponent;
