import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getProjectTypes } from "../../services/APIService";
import { RootState } from "../../store/store";

export interface projectTypesState {
  projectTypes: any[];
}

const initialState: projectTypesState = {
  projectTypes: [],
};


export const getProjectsTypesAsync: any= createAsyncThunk("projectTypes/getProjectTypes", async()=>{
    const response = await getProjectTypes();
    return (response as any).data;
})
export const projectTypesSlice = createSlice({
  name: "projectTypes",
  initialState,
  reducers: {},
  extraReducers:(builder)=> {
      builder.addCase(getProjectsTypesAsync.fulfilled, (state, action)=>{
          state.projectTypes= action.payload;
      })
  },
});
export const selectProjectTypes = (state: RootState): any[] => {
  return (state.projectTypes as projectTypesState).projectTypes;
};

export default projectTypesSlice.reducer;
