const fieldTypes = [
  { id: 0, displayName: "TextBox" },
  { id: 1, displayName: "RichText" },
  { id: 2, displayName: "Number" },
  { id: 3, displayName: "Image" },
  { id: 4, displayName: "Choice" },
  { id: 5, displayName: "MultiChoice" },
  { id: 6, displayName: "RAG" },
  { id: 7, displayName: "Date" },
  { id: 8, displayName: "Switch" },
  { id: 9, displayName: "People" },
  { id: 10, displayName: "Url" },
  { id: 11, displayName: "Calculated" },
];
export const fieldTypeDisplayNameFromId = (id: number): string | undefined => {
  return fieldTypes.find((fieldToFind) => id === fieldToFind.id)?.displayName;
};
export const fieldTypeIdFromDisplayName = (
  displayName: string
): number | undefined => {
  return fieldTypes.find(
    (fieldToFind) => displayName === fieldToFind.displayName
  )?.id;
};
export default fieldTypes;
