import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import FullscreenOutlinedIcon from "@mui/icons-material/FullscreenOutlined";
import { Button, Paper } from "@mui/material";
import { nanoid } from "nanoid";
import React, { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  baseComponents,
  COLUMN,
  COMPONENT,
  ROW,
  SIDEBAR_ITEM,
} from "../../config/constants";
import {
  addNewRow,
  removeColumn,
  removeRow,
  removeComponent,
  selectCurrentTab,
  selectFormData,
  selectLayout,
  updateLayout,
} from "../../features/livepreview/livepreviewSlice";
import { ItemDNDType } from "../../../models/Livepreview/ItemDNDType.model";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import EdisonTypography from "../../../app-shared/edison/typography/EdisonTypography";
import {
  handleMoveSidebarComponentIntoParent,
  handleMoveToDifferentParent,
  handleMoveWithinParent,
} from "../../utils/dndHelpers";
import DropZone from "./DropZone";
import "./livepreview.scss";
import Row from "./Row";
import TabContainer from "./TabContainer";

const ACCEPTS = [SIDEBAR_ITEM, COMPONENT, ROW, COLUMN];

export interface LivePreviewProps {
  form: any;
}

const LivePreview: FC<LivePreviewProps> = ({ form }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["admin"]);

  const initialComponents = baseComponents;
  const [components, setComponents] = useState(initialComponents);

  const formData = useAppSelector(selectFormData);
  const currentTab = useAppSelector(selectCurrentTab);

  const layout = useAppSelector(selectLayout);

  const handleDrop = useCallback(
    (dropZone: any, item: any) => {
      const splitDropZonePath = dropZone.path.split("-");
      const pathToDropZone = splitDropZonePath.slice(0, -1).join("-");
      const newItem: ItemDNDType = {
        id: item.id,
        title: "",
        type: item.type,
      };
      if (item.type === COLUMN) {
        newItem.children = item.children;
      }
      // features into dropzone
      if (item.type === SIDEBAR_ITEM) {
        const newComponent = {
          fieldId: item.id,
          id: nanoid(),
          ...item.component,
        };
        const newItem: ItemDNDType = {
          id: newComponent.id,
          title: "",
          type: COMPONENT,
          component: { ...item.component },
        };
        setComponents({
          ...components,
          [newComponent.id]: newComponent,
        });
        dispatch(
          updateLayout(
            handleMoveSidebarComponentIntoParent(
              layout,
              splitDropZonePath,
              newItem
            )
          )
        );
        return;
      }

      const splitItemPath = item.path.split("-");
      const pathToItem = splitItemPath.slice(0, -1).join("-");

      // move without create
      if (splitItemPath.length === splitDropZonePath.length) {
        // move within parent
        if (pathToItem === pathToDropZone) {
          dispatch(
            updateLayout(
              handleMoveWithinParent(layout, splitDropZonePath, splitItemPath)
            )
          );
          return;
        }

        // move to another parent
        dispatch(
          updateLayout(
            handleMoveToDifferentParent(
              layout,
              splitDropZonePath,
              splitItemPath,
              newItem
            )
          )
        );
        return;
      }

      // move and create
      dispatch(
        updateLayout(
          handleMoveToDifferentParent(
            layout,
            splitDropZonePath,
            splitItemPath,
            newItem
          )
        )
      );
    },
    [layout, components]
  );
  const renderRow = (row: any, currentPath: string) => {
    return (
      <Row
        key={row.id}
        data={row}
        handleDrop={handleDrop}
        components={components}
        path={currentPath}
        removeRow={(path: string) => dispatch(removeRow(path))}
        removeColumn={(path: string) => dispatch(removeColumn(path))}
        removeComponent={(path: string, id: string) =>
          dispatch(removeComponent({ path, id }))
        }
      />
    );
  };
  return (
    <div className="livepreview">
      <div className="livepreview_title">
        <EdisonTypography title={t("livePreview")} variant="t2" />
        <div className="lpviewport">
          <FullscreenOutlinedIcon />
        </div>
      </div>

      <Paper className="paper">
        <div className="livepreview_body">
          <div className="pageContainer">
            <div className="page">
              <div className="lp_page-title">
                <EdisonTypography title={t("tabs")} variant="t4" />
                <EdisonTypography
                  title={`(${t("dndToReorder")})`}
                  variant="t10"
                />
              </div>
              <TabContainer data={formData} />
            </div>
          </div>
          <div className="pageContainer">
            <div className="page">
              <div className="lp_page-title">
                <EdisonTypography title={currentTab.title} variant="t4" />
                <EdisonTypography
                  title={`(${t("dndToReorder")})`}
                  variant="t10"
                />
              </div>

              {layout.map((row, index) => {
                const currentPath = `${index}`;
                return (
                  <React.Fragment key={row.id}>
                    <DropZone
                      data={{
                        path: currentPath,
                        childrenCount: layout.length,
                      }}
                      onDrop={handleDrop}
                    />
                    {renderRow(row, currentPath)}
                  </React.Fragment>
                );
              })}
              <DropZone
                data={{
                  path: `${layout.length}`,
                  childrenCount: layout.length,
                }}
                onDrop={handleDrop}
                isLast
              />
            </div>
            <div className="livepreview_add-row">
              <Button
                onClick={() => dispatch(addNewRow())}
                color="inherit"
                startIcon={<AddCircleOutlineIcon />}>
                <EdisonTypography title={t("addNewRow")} variant="t6" />
              </Button>
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default LivePreview;
