import React from "react";
import EdisonTypography from "../../app-shared/edison/typography/EdisonTypography";
import "./ideas.scss";

const Ideas = () => (
  <div className="ideas-root">
    <div className="body">
      <EdisonTypography variant="t1" title={"Ideas"} disableUppercase={true} />
      <EdisonTypography
        variant="t8"
        title={"Coming Soon"}
        disableUppercase={true}
      />
    </div>
  </div>
);

export default Ideas;
