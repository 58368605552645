import { withMsal } from "@azure/msal-react";
import React from "react";
import Administration from "../../app-admin/pages/administration/Administration";

// const EDAdminRemote = React.lazy(() => import("EDAdmin/App"));

// const EDAdmin = withMsal(EDAdminRemote);

const Admin = (): JSX.Element => {
  return (
    <div>
      <Administration />
    </div>
  );
};

export default Admin;
