import Item from "../../models/Item.model";
import SortOrder from "../../models/SortOrder.model";

function descendingComparator<T>(a: T, b: T) {
  if (b < a) {
    return -1;
  }
  if (b > a) {
    return 1;
  }
  return 0;
}
const sortData = <DataType extends Item, KeyType extends keyof DataType>(
  data: DataType[],
  order: SortOrder,
  orderBy: KeyType
): DataType[] => {
  return data.sort((a: DataType, b: DataType) => {
    const newOrder =
      order === "desc"
        ? descendingComparator(a[orderBy], b[orderBy])
        : -descendingComparator(a[orderBy], b[orderBy]);
    if (newOrder !== 0) return newOrder;
    return order === "desc" ? b.id - a.id : a.id - b.id;
  });
};
export default sortData;
