import { apiConfig } from "../config/authConfig";
import { userState } from "../features/user/userSlice";
import { RootState, store } from "../store/store";

const getHeaders = (accessToken: string): Headers => {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;
  const appState = store.getState();
  const userState = appState.user as userState;

  headers.append("Access-Control-Max-Age", "600");

  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");
  headers.append("edison365-tenant", userState.tenant);
  headers.append("edison365-instance", userState.instance);

  return headers;
};

export function fetchFields(
  accessToken: string,
  endpoint: string
): Promise<unknown> {
  const options = {
    method: "GET",
    headers: getHeaders(accessToken),
  };

  return fetch(`${endpoint}`, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

export function saveFields(
  accessToken: string,
  endpoint: string,
  data: any
): Promise<unknown> {
  const options = {
    method: "POST",
    headers: getHeaders(accessToken),
    body: JSON.stringify(data),
  };

  return fetch(`${endpoint}`, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

export function recycleFields(
  accessToken: string,
  endpoint: string,
  id: any
): Promise<unknown> {
  const options = {
    method: "DELETE",
    headers: getHeaders(accessToken),
  };

  return fetch(`${endpoint}/${id}/recycle`, options)
    .then((response) => {
      if (response.ok)
        return response[response.status == 204 ? "text" : "json"]();
    })
    .catch((error) => console.log(error));
}

export function updateFields(
  accessToken: string,
  endpoint: string,
  data: any
): Promise<unknown> {
  const options = {
    method: "PUT",
    headers: getHeaders(accessToken),
    body: JSON.stringify(data),
  };

  return fetch(`${endpoint}`, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

export function fetchSingleElement(
  accessToken: string,
  endpoint: string,
  id: any
): Promise<unknown> {
  const options = {
    method: "GET",
    headers: getHeaders(accessToken),
  };
  return fetch(`${endpoint}/${id}`, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}
