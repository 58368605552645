import AddIcon from "@mui/icons-material/Add";
import { Button, Grid, Popover, TextField } from "@mui/material";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { addNewChoice } from "../../features/fields/multiChoiceSlice";
import EdisonButton from "../../../app-shared/edison/button/EdisonButton";
import "./multichoicepopover.scss";

export interface MultiChoicePopoverProps {
  path: string;
  isTopLevel: boolean;
}
const MultiChoicePopover: FC<MultiChoicePopoverProps> = ({
  path,
  isTopLevel,
}) => {
  const [label, setLabel] = useState("");
  const dispatch = useDispatch();
  const { t } = useTranslation(["admin"]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <Grid className="multi_choice_popover">
      {isTopLevel ? (
        <div className="add_choice_button">
          <EdisonButton
            name={t("addChoice")}
            onClick={handleClick}
            endIcon={<AddIcon />}
          />
        </div>
      ) : (
        <AddIcon
          className="add_icon"
          onClick={(e) => {
            e.stopPropagation();
            handleClick(e);
          }}
        />
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}>
        <Grid
          container
          direction="row"
          className="popover"
          style={{ alignItems: "flex-end" }}>
          <TextField
            variant="standard"
            value={label}
            onChange={(e) => setLabel(e.target.value)}
            label={t("label")}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
          <Grid item className="button_container">
            <Button
              className="button"
              onClick={(e) => {
                e.stopPropagation();
                if (label.length > 0) {
                  dispatch(addNewChoice({ label: label, path: path }));
                  setLabel("");
                  handleClose();
                }
              }}>
              {t("save")}
            </Button>
          </Grid>
        </Grid>
      </Popover>
    </Grid>
  );
};

export default MultiChoicePopover;
