import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import EditIcon from "@mui/icons-material/Edit";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import HistoryIcon from "@mui/icons-material/History";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import ColumnModel from "../../../models/Column.model";
import Item from "../../../models/Item.model";
import RowIcon from "../../../models/RowIcon.model";
import EdisonTable from "../../../app-shared/edison/table/EdisonTable";
import sortData from "../../utils/sortData";
import "./admintable.scss";

interface Props {
  data: Array<any>;
  columns: ColumnModel[];
  icons?: RowIcon[];
  tableTitle?: string;
  editAction: any;
  deleteAction: any;
}

const AdminTable: FC<Props> = ({
  data,
  columns,
  icons,
  tableTitle,
  editAction,
  deleteAction,
}) => {
  const { t } = useTranslation(["admin"]);

  return (
    <EdisonTable
      animateSearchBar={false}
      data={data}
      columns={columns}
      icons={[]}
      sortData={sortData}
      showCheckBox={true}
      buttons={[
        {
          title: t("restore"),
          type: "restore",
          onClick: (rowId: number) => {
            console.log("Export clicked");
          },
          fill: true,
          visible: true,
          icon: HistoryIcon,
        },
        {
          title: t("edit"),
          type: "edit",
          onClick: (ids: number[]) => {
            editAction(ids);
          },
          fill: true,
          visible: true,
          icon: EditIcon,
        },
        {
          title: t("delete"),
          type: "delete",
          onClick: (ids: number[]) => {
            deleteAction(ids);
          },
          fill: true,
          visible: true,
          icon: CancelOutlinedIcon,
        },
        {
          title: t("export"),
          type: "export",
          onClick: {},
          fill: true,
          visible: true,
          icon: FileDownloadOutlinedIcon,
        },
      ]}
    />
  );
};

export default AdminTable;
