import { AppBar, Button, Dialog, DialogContent, Drawer } from "@mui/material";
import React, { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import HomeLayout from "../../components/layout/HomeLayout";
import { CDNValues } from "../../config/cdnConfig";
import "./unauthenticatedView.scss";
import EdisonTypography from "../../app-shared/edison/typography/EdisonTypography";

interface Props {
  handleLogin?: any;
  instance: any;
  isSigningIn?: boolean;
}

const UnauthenticatedView: FC<Props> = ({
  handleLogin,
  instance,
  isSigningIn,
}): ReactElement => {
  const { t } = useTranslation(["common"]);

  const [isLoading] = useState(true);
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={"unauthenticated-view"}>
      <Dialog
        open={open}
        className={"login-dialog"}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent className={"dialog-logo"}>
          {<img src={CDNValues.edisonLogo} className={"login-logo"} />}
        </DialogContent>
        <DialogContent className="dialog-content">
          <EdisonTypography
            variant="t6"
            title={t("signInPrompt")}
            disableUppercase={true}
          />
        </DialogContent>
        <DialogContent className={"dialog-content"}>
          {!!isSigningIn ? (
            <Button
              className={"login-button"}
              variant="outlined"
              color="primary">
              <EdisonTypography
                variant="t6"
                title={t("signingIn") + "..."}
                disableUppercase={true}
              />
            </Button>
          ) : (
            <Button
              className={"login-button"}
              variant="outlined"
              onClick={() => handleLogin(instance)}
              color="primary">
              <EdisonTypography
                variant="t6"
                title={t("signIn")}
                disableUppercase={true}
              />
            </Button>
          )}
        </DialogContent>
      </Dialog>
      <AppBar
        position="fixed"
        color="secondary"
        elevation={0}
        className={"app-bar"}></AppBar>
      <Drawer variant="permanent" className={`drawer-close`}>
        <div className={"toolbar"}></div>
      </Drawer>
      <main className={"content"}>
        <HomeLayout isLoading={isLoading} />
        {/* <Button
          // variant="primary"
          className="ml-auto"
          onClick={() => handleLogin(instance)}>
          Sign in using Microsoft
        </Button> */}
      </main>
    </div>
  );
};

export default UnauthenticatedView;
