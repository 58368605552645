import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import ExploreOutlinedIcon from "@mui/icons-material/ExploreOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import ContainerIcon from "../components/icon/ContainerIcon";
import RouteItem from "../models/RouteItem.model";
import Admin from "../pages/Admin/Admin";
import BusinessCase from "../pages/BusinessCase/BusinessCase";
import Home from "../pages/Home/Home";
import Ideas from "../pages/Ideas/Ideas";
import Projects from "../pages/Projects/Projects";
import React, { FC } from "react";
import { CDNValues } from "./cdnConfig";
import PersonIcon from "@mui/icons-material/Person";
import Personal from "../pages/Personal/Personal";
import edisonIcon from "./../assets/edison_icon.png";
import businesscaseIcon from "./../assets/businesscase_icon.png";
import ideasIcon from "./../assets/ideas_icon.png";
import projectsIcon from "./../assets/projects_icon.png";

const Temp = () => <div>Coming Soon</div>;

export const routes: Array<RouteItem> = [
  {
    key: "router-home",
    title: "Home",
    tooltip: "Home",
    path: "/home",
    enabled: true,
    component: Home,
    icon: <HomeOutlinedIcon />,
    appendDivider: true,
  },
  {
    key: "router-discover",
    title: "Discover",
    tooltip: "Discover",
    path: "/discover",
    enabled: true,
    component: Temp,
    icon: <ExploreOutlinedIcon />,
    appendDivider: true,
  },
  {
    key: "router-personal",
    title: "Personal",
    tooltip: "Personal",
    path: "/personal",
    enabled: true,
    component: Personal,
    icon: <PersonIcon />,
    appendDivider: true,
  },
  {
    key: "router-reports",
    title: "Reports",
    tooltip: "Reports",
    path: "/reports",
    enabled: true,
    component: Temp,
    icon: <AssessmentOutlinedIcon />,
    appendDivider: true,
  },
  // {
  //   key: "router-administration",
  //   title: "Admin",
  //   tooltip: "Admin",
  //   path: "/administration",
  //   enabled: true,
  //   component: Temp,
  //   icon: <VpnKeyOutlinedIcon />,
  //   appendDivider: true,
  // },
  {
    key: "router-settings",
    title: "Settings",
    tooltip: "Settings",
    path: "/settings",
    enabled: true,
    component: Admin,
    icon: <SettingsOutlinedIcon />,
    appendDivider: true,
  },
  {
    key: "router-admin",
    title: "Administration",
    tooltip: "Home",
    path: "/admin",
    enabled: true,
    component: Home,
    icon: <ContainerIcon imgUrl={edisonIcon} />,
    appendDivider: true,
    isApp: true,
    themeName: "adminTheme",
  },
  {
    key: "router-ideas",
    title: "Ideas",
    tooltip: "Ideas",
    path: "/ideas",
    enabled: true,
    component: Ideas,
    icon: <ContainerIcon imgUrl={ideasIcon} />,
    appendDivider: true,
    isApp: true,
    themeName: "ideasTheme",
  },
  {
    key: "router-projects",
    title: "Projects",
    tooltip: "Projects",
    path: "/projects",
    enabled: true,
    component: Projects,
    icon: <ContainerIcon imgUrl={projectsIcon} />,
    appendDivider: true,
    isApp: true,
    themeName: "projectsTheme",
  },

  {
    key: "router-businessCase",
    title: "Business Case",
    tooltip: "Business Case",
    path: "/businessCase",
    enabled: true,
    component: BusinessCase,
    icon: <ContainerIcon imgUrl={businesscaseIcon} />,
    appendDivider: true,
    isApp: true,
    themeName: "casesTheme",
  },
];
