export const translations: Record<string, string> = {
  administration: "Administração",
  label: "ETIQUETA",
  fieldType: "TIPO DE CAMPO",
  lastUpdated: "ULTIMA ATUALIZAÇÃO",
  internalName: "NOME INTERNO",
  type: "TIPO",
  linkUrl: "LINK URL",
  icon: "ÍCONE",
  createField: "Criar Campo",
  buttonsPageDescriptionP1:
    "Create custom buttons for use on forms to enable clickable links to other sites.",
  buttonsPageDescriptionP2:
    "Use of the tokens will make a button URL dynamic by passing in the properties from the Project.",
  Tokens: "Tokens",
  createButton: "Criar Botão",
  cancel: "Cancelar",
  save: "Salvar",
  labelLower: "Etiqueta",
  internalNameLower: "Nome Interno",
  linkUrlLower: "Link Url ",
  description: "Decrição",
  descriptionRequiredMessage: "Description required",
  internalNameRequiredMessage: "Internal name is required",
  labelRequiredMessage: "Label is required",
  linkUrlRequiredMessage: "Link Url is required",
  loadingErrorMessage:
    "Couldn&apos;t load the data at this time. Please refresh the page or contact your administrator.",
  defaultTitle: "Default Title",
  systemFields: "Campost de Sistema",
  createTable: "Criar Tabela",
  Administration: "Administração",
  database: "BASE DE DADOS",
  globalusers: "UTILIZADORES GLOBAIS",
  fields: "CAMPOST",
  tables: "TABELAS",
  buttons: "BOTOES",
  forms: "FORMULÁRIOS",
  types: "TIPOS",
  news: "NOTÍCIAS",
  theme: "TEMA",
  notification: "NOTIFICACAO",
  pwa: "PWA",
  productlinks: "PRODUCT LINKS",
  teams: "EQUIPAS",
  yammer: "YAMMER",
  reports: "RELATÓRIOS",
  customerinfo: "CUSTOMER INFO",
  carddesigner: "CARD DESIGNER",
  newField: "NOVO CAMPO",
  newButton: "NOVO BOTA",
  newTable: "NOVA TABELA",
  notifications: "Notifications",
};
