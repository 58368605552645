import React, { FC } from "react";
import {
  EdgeText,
  getEdgeCenter,
  getSmoothStepPath,
} from "react-flow-renderer";
import { selectCurrentElement } from "../../../features/workflow/workflowSlice";
import { useAppSelector } from "../../../store/hooks";
import "./customedge.scss";
export interface CustomEdgeProps {
  id: any;
  sourceX: any;
  sourceY: any;
  targetX: any;
  targetY: any;
  sourcePosition: any;
  targetPosition: any;
  label?: string;
}
const CustomEdge: FC<CustomEdgeProps> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  label,
}) => {
  const currentNode = useAppSelector(selectCurrentElement);
  const edgePath = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const center = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
  });
  const text = label ? (
    <EdgeText x={center[0]} y={center[1]} label={label} />
  ) : null;
  return (
    <>
      <path
        id={id}
        className={`react-flow__edge-path workflow-react-flow-path ${
          currentNode && currentNode.id === id && "selected"
        }`}
        d={edgePath}
        markerEnd={
          currentNode && currentNode.id === id
            ? "url(#edge-marker-selected)"
            : "url(#edge-marker-base)"
        }
      />
      {text}
    </>
  );
};

export default CustomEdge;
