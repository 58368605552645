import { Icon, IconButton, Theme, Tooltip } from "@mui/material";
import DefaultIcon from "@mui/icons-material/FileCopy";
import clsx from "clsx";
import React, {
  ComponentType,
  FC,
  ReactElement,
  ReactNode,
  useContext,
} from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Location } from "history";
import RouteItem from "../../models/RouteItem.model";
import { ThemeContext } from "../../theme/ThemeContainer";
import "./appItem.scss";

const AppItem: FC<RouteItem> = (route: RouteItem): ReactElement => {
  const location = useLocation();
  const setThemeName = useContext(ThemeContext);

  const handleNavigate = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ): void => {
    if (!!route.themeName) setThemeName(route.themeName);
    if (!route.enabled) e.preventDefault();
  };
  return (
    <div
      className={clsx("app-item", {
        ["selected"]: location.pathname === route.path,
      })}>
      <NavLink
        to={`${route.path}`}
        style={{ textDecoration: "none", color: "inherit" }}
        onClick={handleNavigate}
        className={clsx({
          ["listItemDisabled"]: !route.enabled,
        })}>
        <Tooltip title={route.tooltip || ""} placement="right">
          <IconButton size="small">
            {typeof route.icon === "object" ? (
              (route.icon as ReactNode)
            ) : (
              <Icon>{route.icon}</Icon>
            )}
          </IconButton>
        </Tooltip>
      </NavLink>
    </div>
  );
};

export default AppItem;
