import DownloadIcon from "@mui/icons-material/Download";
import FilterListIcon from "@mui/icons-material/FilterList";
import SortIcon from "@mui/icons-material/Sort";
import { Button, Grid, Stack } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import EdisonContentCard from "../../../app-shared/edison/contentcard/EdisonContentCard";
import EdisonSearchBar from "../../../app-shared/edison/searchbar/EdisonSearchBar";
import EdisonTypography from "../../../app-shared/edison/typography/EdisonTypography";
import {
  getProjectsAsync,
  selectProjects,
} from "../../redux/reducers/projectsSlice";
import { useAppSelector } from "../../redux/store/hooks";

import "./discover.scss";

const list = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 11, 12, 13, 14];
const Discover: FC = () => {
  const history = useHistory();
  const [searchFilter, setSearchFilter] = useState("");
  const { t } = useTranslation(["projects"]);
  const dispatch = useDispatch();

  const projects = useAppSelector(selectProjects);
  useEffect(() => {
    dispatch(getProjectsAsync());
  }, []);

  return (
    <Stack spacing={2} className="discover-page-root">
      <EdisonTypography title={t("discover")} variant="t1" />
      <Stack direction="row" className="discover-toolbar">
        <EdisonSearchBar
          searchFilter={searchFilter}
          setSearchFilter={setSearchFilter}
          animated={false}
          width="30em"
        />
        <Stack direction="row" spacing={1}>
          <Button>{t("clearAll")}</Button>
          <Button variant="outlined" startIcon={<SortIcon />}>
            {t("sort")}
          </Button>
          <Button
            variant="contained"
            startIcon={<FilterListIcon />}
            disableElevation
            className="white">
            {t("filter")}
          </Button>
          <Button
            variant="contained"
            startIcon={<DownloadIcon />}
            disableElevation
            className="white">
            {t("export")}
          </Button>
        </Stack>
      </Stack>
      <Grid container spacing={4}>
        {projects.map((item, index) => (
          <Grid
            item
            key={index}
            onClick={() => {
              history.push({
                pathname: "/projects/viewproject",
                search: `?id=${item.id}`,
                state: { item: item },
              });
            }}>
            <div className="item">
              <EdisonContentCard
                id={item.toString()}
                title={item.displayName??""}
                description={item.description??""}
                createdDate={ new Date(item.created? item.created: "").toString()}
                isCompact={false}
                imageSource={
                  item.imageUrl
                    ? item.imageUrl
                    : "https://cdn-dev.edison365.com/edison365-public/Images/cardPlaceholder.png?tk=RVSYUwvyUGjiAPaQCRPIGbfEs_0NwihK0pxBH5A9XWvoB854DTGvv2OL-Q8S3AoLR2h055u8srwi39o"
                }
              />
            </div>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

export default Discover;
