import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import React, { FC } from "react";
import { Node } from "react-flow-renderer";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  selectCurrentElement,
  updateElement,
} from "../../../../features/workflow/workflowSlice";
import { useAppSelector } from "../../../../store/hooks";
import ExpandableMenu from "../../../expandableMenu/ExpandableMenu";
import updateNodeCustomData from "../../utils/updateNodeCustomData";

const NodeFlags: FC = () => {
  const { t } = useTranslation(["admin"]);

  const currentNode = useAppSelector(selectCurrentElement);
  const dispatch = useDispatch();
  return (
    <ExpandableMenu title={t("flags")}>
      <Grid container direction="row" width="100%">
        {currentNode &&
          ["Stage", "StageReview"].includes(
            currentNode.data.configuration.type
          ) && (
            <>
              <FormControlLabel
                label={t("autoProgress") as string}
                control={
                  <Checkbox
                    checked={
                      currentNode?.data?.autoProgress
                        ? currentNode.data.autoProgress
                        : false
                    }
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (currentNode) {
                        dispatch(
                          updateElement(
                            updateNodeCustomData(
                              "autoProgress",
                              e.target.checked,
                              currentNode as Node
                            )
                          )
                        );
                      }
                    }}
                  />
                }
              />

              <FormControlLabel
                label={t("readOnly") as string}
                control={
                  <Checkbox
                    checked={
                      currentNode?.data?.readOnly
                        ? currentNode.data.readOnly
                        : false
                    }
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (currentNode) {
                        dispatch(
                          updateElement(
                            updateNodeCustomData(
                              "readOnly",
                              e.target.checked,
                              currentNode as Node
                            )
                          )
                        );
                      }
                    }}
                  />
                }
              />
              {currentNode.data.configuration.type === "StageReview" && (
                <FormControlLabel
                  label={t("allowManagersApproval") as string}
                  control={
                    <Checkbox
                      checked={
                        currentNode?.data?.allowManagersApproval
                          ? currentNode.data.allowManagersApproval
                          : false
                      }
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (currentNode) {
                          dispatch(
                            updateElement(
                              updateNodeCustomData(
                                "allowManagersApproval",
                                e.target.checked,
                                currentNode as Node
                              )
                            )
                          );
                        }
                      }}
                    />
                  }
                />
              )}
            </>
          )}
      </Grid>
    </ExpandableMenu>
  );
};

export default NodeFlags;
