import { Grid } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Item from "../../../models/Item.model";
import EdisonCard from "../../../app-shared/edison/card/EdisonCard";
import EdisonTable from "../../../app-shared/edison/table/EdisonTable";
import AdminTable from "../admintable/AdminTable";
import "./linkedtables.scss";
import sortData from "../../utils/sortData";
interface Props {
  data: Item[];
  firstTableTitle: string;
  secondTableTitle: string;
  ids: number[];
  setParentData: any;
}
const LinkedTables: FC<Props> = ({
  data,
  firstTableTitle,
  secondTableTitle,
  ids,
  setParentData,
}) => {
  const { t } = useTranslation(["admin"]);
  const columns = [
    {
      id: "1",
      name: t("name"),
      reference: "displayName" as keyof Item,
    },
    {
      id: "2",
      name: t("type"),
      reference: "type" as keyof Item,
    },
  ];

  const [firstTableItems, setFirstTableItems] = useState<Item[]>([]);
  const [secondTableItems, setSecondTableItems] = useState<Item[]>([]);

  useEffect(() => {
    setFirstTableItems(
      data.filter(
        (dataToFilter) =>
          !ids.includes(dataToFilter.id) && !dataToFilter.isDeleted
      )
    );
    setSecondTableItems(
      data.filter((dataToFilter) => ids.includes(dataToFilter.id))
    );
  }, [ids]);
  useEffect(() => {
    setParentData(secondTableItems);
  }, [secondTableItems]);
  const addItemToSecondTable = (id: number) => {
    const item = firstTableItems
      .concat(secondTableItems)
      .find((itemToFind) => itemToFind.id === id);
    setSecondTableItems((prev) => [...prev, item!]);
    setFirstTableItems((prev) =>
      prev.filter((itemToFilter) => itemToFilter.id !== id)
    );
  };
  const removeItemFromSecondTable = (id: number) => {
    const item = secondTableItems.find((itemToFind) => itemToFind.id === id);
    setFirstTableItems((prev) => [...prev, item!]);
    setSecondTableItems((prev) =>
      prev.filter((itemToFilter) => itemToFilter.id !== id)
    );
  };

  return (
    <Grid container className="linked-tables-container">
      <Grid item className="sub-table">
        <EdisonCard>
          <EdisonTable
            showCheckBox={false}
            animateSearchBar={false}
            data={firstTableItems}
            columns={columns}
            icons={[
              { icon: AddCircleOutlineIcon, action: addItemToSecondTable },
            ]}
            tableTitle={firstTableTitle}
            buttons={[]}
            sortData={sortData}
          />
        </EdisonCard>
      </Grid>
      <Grid item className="sub-table left-margin">
        <EdisonCard>
          <EdisonTable
            showCheckBox={false}
            animateSearchBar={false}
            data={secondTableItems}
            columns={columns}
            icons={[
              { icon: HighlightOffIcon, action: removeItemFromSecondTable },
            ]}
            tableTitle={secondTableTitle}
            buttons={[]}
            sortData={sortData}
          />
        </EdisonCard>
      </Grid>
    </Grid>
  );
};
export default LinkedTables;
