import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  CardContent,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import AdminTable from "../../components/admintable/AdminTable";
import Layout from "../../components/layout/Layout";
import Loading from "../../components/loading/Loading";
import { routes } from "../../config/routes";
import ColumnModel from "../../../models/Column.model";
import Item from "../../../models/Item.model";
import RouteItem from "../../../models/RouteItem.model";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import EdisonCard from "../../../app-shared/edison/card/EdisonCard";
import EdisonFlyout from "../../../app-shared/edison/flyout/EdisonFlyout";
import EdisonFormSelect from "../../../app-shared/edison/formselect/EdisonFormSelect";
import EdisonParentTabs from "../../../app-shared/edison/tabs/EdisonParentTabs";
import EdisonFormInput from "../../../app-shared/edison/forminput/EdisonFormInput";
import UserPeoplePicker from "../../../app-shared/user/peoplepicker/UserPeoplePicker";
import UserFormImage from "../../../app-shared/user/image/UserFormImage";

import navigate from "../../utils/navigate";
import "./typespage.scss";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  createTypesAsync,
  getTypesAsync,
  selectTypes,
} from "../../features/types/typesSlice";
import { FormProvider, useForm } from "react-hook-form";
import { PeoplePickerInputs } from "../../components/PeoplePickerInputs";
import { RootState } from "../../store/store";
import {
  getUserByEntityId,
  mapPeople,
} from "../../components/workflowdesigner/utils/userMapper";
import UserPicture from "../../../models/common/UserPicture.model";
import { User } from "@microsoft/microsoft-graph-types";
import {
  selectUserPhotos,
  selectGroupMembers,
} from "../../../features/msGraph/msGraphSlice";
import {
  getWorkflowsAsync,
  selectWorkflowList,
} from "../../features/workflow/workflowSlice";

const TypesPage: FC = () => {
  const { t } = useTranslation(["admin"]);

  const { url } = useRouteMatch();
  const history = useHistory();
  const dispatch = useAppDispatch();

  // const setOpen = (isOpen) => (Flyout.args.isOpen = isOpen);

  const [isOpen, setIsOpen] = useState(false);

  const firstButtonAction = () => {
    console.log("opop", result);
    dispatch(createTypesAsync(result));
  };
  // setIsOpen(false);
  const secondButtonAction = () => setIsOpen(false);
  const iconClickAction = () => setIsOpen(false);
  const methods = useForm();
  const { handleSubmit, watch } = methods;
  const onSubmit = async (data: any) => console.log("submited");

  const [value, setValue] = React.useState([]);

  useEffect(() => {
    if (typesStatus === "idle") {
      dispatch(getTypesAsync());
    }
  }, []);

  const typesList = useAppSelector(selectTypes);
  const typesStatus = useAppSelector((state: RootState) => state.types.status);
  const userPictures: UserPicture[] = useAppSelector(selectUserPhotos);
  const groupMembers: User[] = useAppSelector(selectGroupMembers);
  const workflowList = useAppSelector(selectWorkflowList);
  useEffect(() => {
    dispatch(getWorkflowsAsync());
  }, []);

  useEffect(() => {
    if (typesStatus === "idle") {
      dispatch(getTypesAsync());
    }
  }, []);

  const columns: ColumnModel[] = [
    {
      id: 1,
      name: t("label"),
      reference: "displayName",
    },
    {
      id: 2,
      name: t("internalName"),
      reference: "name",
    },
    {
      id: 3,
      name: t("linkUrl"),
      reference: "linkUrl" as keyof Item,
    },
    {
      id: 4,
      name: t("icon"),
      reference: "icon " as keyof Item,
    },
  ];

  //dummy data for the record type start
  const recordType = [
    {
      id: 1,
      icon: (
        <img src="https://cdn-dev.edison365.com/edison365-public/Images/businessCase.svg?tk=RVSYUwvyUGjiAPaQCRPIGbfEs_0NwihK0pxBH5A9XWvoB854DTGvv2OL-Q8S3AoLR2h055u8srwi39o" />
      ),
      displayName: "businessCase",
    },
    {
      id: 2,
      icon: (
        <img src="https://cdn-dev.edison365.com/edison365-public/Images/ideas.svg?tk=RVSYUwvyUGjiAPaQCRPIGbfEs_0NwihK0pxBH5A9XWvoB854DTGvv2OL-Q8S3AoLR2h055u8srwi39o" />
      ),
      displayName: "ideas",
    },
    {
      id: 3,
      icon: (
        <img src="https://cdn-dev.edison365.com/edison365-public/Images/projects.svg?tk=RVSYUwvyUGjiAPaQCRPIGbfEs_0NwihK0pxBH5A9XWvoB854DTGvv2OL-Q8S3AoLR2h055u8srwi39o" />
      ),
      displayName: "projects",
    },
  ];

  //testing api
  const result = {
    id: 0,
    name: "stuff",
    displayName: "testing",
    description: "kel testing",
    imageUrl:
      "https://cdn-dev.edison365.com/edison365-public/Images/edison365_logo_grey.png?tk=RVSYUwvyUGjiAPaQCRPIGbfEs_0NwihK0pxBH5A9XWvoB854DTGvv2OL-Q8S3AoLR2h055u8srwi39o",
    isDeleted: false,
    workflow: 17,
    typePermissions: [
      {
        id: 0,
        role: 1,
        entityID: "string",
        permissionType: 0,
      },
    ],
  };

  //Tabs and their content
  const tabData = [
    {
      id: 1,
      tabTitle: t("activeTypes"),
      tabContent: (
        <div>
          <Grid item xs={12}>
            <EdisonCard>
              <CardContent>
                <AdminTable
                  data={typesList}
                  columns={columns}
                  icons={[
                    { icon: EditIcon, action: null },
                    { icon: CancelOutlinedIcon, action: null },
                  ]}
                  editAction={() => console.log("edit clicked")}
                  deleteAction={() => console.log("delete clicked")}
                />
              </CardContent>
            </EdisonCard>
          </Grid>
        </div>
      ),
    },
    {
      id: 2,
      tabTitle: t("deletedTypes"),
      tabContent: <span>Coming Soon</span>,
    },
  ];
  //tabs content end

  const route = routes.find(
    (routeToFind: RouteItem) => routeToFind.key == "types"
  );

  return (
    <Layout>
      <div className="types-page-root">
        <EdisonFlyout
          isOpen={isOpen}
          title={t("createType")}
          handleOpen={() => console.log("open")}
          handleClosed={() => console.log("closed")}
          firstButtonText={t("save")}
          secondButtonText={t("cancel")}
          firstButtonAction={firstButtonAction}
          secondButtonAction={secondButtonAction}
          iconClickAction={iconClickAction}>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <EdisonFormSelect
                isDisabled={false}
                fullWidth
                size="small"
                // className="form-items"
                name="recordType"
                defaultValue={0}
                label={t("recordType")}
                requiredMessage=""
                data={recordType.map((recordType) => ({
                  icon: recordType.icon,
                  id: recordType.id,
                  displayName: t(recordType.displayName),
                }))}
              />
              <br />
              <EdisonFormInput
                name="Type Name"
                label={t("typeName")}
                defaultValue=""
                disabled={false}
                fullWidth
                size="small"
              />
              <EdisonFormInput
                name="Internal Name"
                label={t("internalName")}
                defaultValue=""
                disabled={false}
                fullWidth
                size="small"
              />
              <EdisonFormInput
                name="Description"
                label={t("description")}
                defaultValue=""
                disabled={false}
                fullWidth
                size="small"
              />
              <UserFormImage
                defaultValue=""
                useInternalState={true}
                name="Default Image"
                label="Default Image"
                disabled={false}
                readOnly={false}
                required={false}
                requiredMessage="Please select an image"
                invalidMediaTypeMessage="Invalid media type"
                accept="image/png, image/jpg, image/jpeg"
              />
              <br />
              <UserPeoplePicker
                isEditing={true}
                peopleList={mapPeople(userPictures, groupMembers)}
                label={t("processManagers")}
                multiple={false}
                maxAvatars={1}
                noOptionsText={t("noMatches")}
                value={
                  value
                    ? value
                    : mapPeople(userPictures, groupMembers).slice(0, 4)
                }
                onChange={undefined}
                maxDropdownCount={3}
                useInternalState={false}
                placeholder={t("processManagers")}
              />

              <br />
              <UserPeoplePicker
                isEditing={true}
                peopleList={mapPeople(userPictures, groupMembers)}
                label={t("owners")}
                multiple={false}
                maxAvatars={1}
                noOptionsText={t("noMatches")}
                value={
                  value
                    ? value
                    : mapPeople(userPictures, groupMembers).slice(0, 4)
                }
                onChange={undefined}
                maxDropdownCount={3}
                useInternalState={false}
                placeholder={t("owners")}
              />
              <br />
              <UserPeoplePicker
                isEditing={true}
                peopleList={mapPeople(userPictures, groupMembers)}
                label={t("readers")}
                multiple={false}
                maxAvatars={1}
                noOptionsText={t("noMatches")}
                value={
                  value
                    ? value
                    : mapPeople(userPictures, groupMembers).slice(0, 4)
                }
                onChange={undefined}
                maxDropdownCount={3}
                useInternalState={false}
                placeholder={t("readers")}
              />
              <br />
              <EdisonFormSelect
                isDisabled={false}
                fullWidth
                size="small"
                // className="form-items"
                name="workflow"
                defaultValue={0}
                label={t("workflow")}
                requiredMessage=""
                data={workflowList.map((workflow) => ({
                  id: workflow.id,
                  displayName: t(workflow.name),
                }))}
              />
            </form>
          </FormProvider>
        </EdisonFlyout>
        <Grid container spacing={8}>
          <Grid item>
            <Button
              className="types-page-button"
              variant="contained"
              color="primary"
              startIcon={<AddCircleOutlineIcon />}
              onClick={() => setIsOpen(true)}>
              {t("createType")}
            </Button>
          </Grid>
        </Grid>
        <EdisonParentTabs data={tabData} />
      </div>
    </Layout>
  );
};
export default TypesPage;
